<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          class="common-botton"
          @click="$emit('changeComponent')"
          v-if="canEdit"
        >
          <span style="padding-right: 11%;">
            Editar
          </span>
          <v-icon rigth>
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="datosSolicitud.tipo == 'Renovación'">
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          No. de Póliza a renovar
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.polizaRenovar ? formData.polizaRenovar : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Fecha Inicio de vigencia a renovar
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.fechaInicioVigenciaRenovar
              ? getFormattDate(formData.fechaInicioVigenciaRenovar)
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Fecha Fin de vigencia a renovar
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.fechaFinVigenciaRenovar
              ? getFormattDate(formData.fechaFinVigenciaRenovar)
              : "Sin llenar"
          }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="3"
        xl="3"
        v-if="datosSolicitud.tipo == 'Emisión' || datosSolicitud.tipo == 'Renovación'"
      >
        <span class="text-header-data">
          Número de póliza
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.numeroPoliza ? formData.numeroPoliza : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Años de Vigencia
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.anios ? formData.anios : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Inicio de Vigencia
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.inicio ? getFormattDate(formData.inicio) : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Fin de Vigencia
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.fin ? getFormattDate(formData.fin) : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Periodicidad de Pago
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.periodicidadSelected
              ? getFormatPeriodicidad(formData.periodicidadSelected)
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Forma de Pago
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.formaPagoSelected
              ? formData.formaPagoSelected
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Tipo de Vehículo
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.tipo_vehiculo ? formData.tipo_vehiculo : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Origen del Auto
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.origenAuto ? formData.origenAuto : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Modelo
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.modelo ? formData.modelo : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Marca
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.marca ? formData.marca : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3" v-if="!formData.interPlan">
        <span class="text-header-data ">
          Submarca
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.submarca ? formData.submarca : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Version
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.version ? formData.version : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <span class="text-header-data ">
          Plan
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.coberturasSelected
              ? formData.coberturasSelected
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3"
        xl="3"
      >
        <span class="text-header-data ">
          Uso del Auto
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.uso_auto ? formData.uso_auto : "Sin llenar" }}
        </span>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3"
        xl="3"
      >
        <span class="text-header-data ">
          No de Motor
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.numMotor ? formData.numMotor : "Sin llenar" }}
        </span>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3"
        xl="3"
      >
        <span class="text-header-data ">
          No. De Serie
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.numSerie ? formData.numSerie : "Sin llenar" }}
        </span>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3"
        xl="3"
      >
        <span class="text-header-data ">
          Placas
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.placas ? formData.placas : "Sin llenar" }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      planList: [
        { id: 1, name: "Amplia" },
        { id: 14, name: "Amplia-Plus" },
        { id: 2, name: "Limitada" },
        { id: 3, name: "RC" },
      ],
      formData: {
        modelo: null,
        marca: null,
        submarca: null,
        version: null,
        coberturasSelected: null,
        tipo_vehiculo: null,
        origenAuto: null,
        uso_auto: null,
        numMotor: null,
        numSerie: null,
        placas: null,
      },
      periodicidadItems: [
        { text: "Anual", value: 1 },
        { text: "Semestral", value: 2 },
        { text: "Trimestral", value: 3 },
        { text: "Mensual", value: 4 },
      ],
    };
  },
  props: {
    datosSolicitud: Object,
    canEdit: Boolean
  },

  methods: {
    fillData() {
      var jsonData = JSON.parse(this.datosSolicitud.body);
      this.formData.inicio = this.datosSolicitud.fechaInicioVigencia ? this.datosSolicitud.fechaInicioVigencia : null;
      this.formData.fin = this.datosSolicitud.fechaFinVigencia  ? this.datosSolicitud.fechaFinVigencia  : null;

      this.formData.modelo = jsonData.modelo ? jsonData.modelo : null;
      this.formData.marca = jsonData.marca ? jsonData.marca : null;
      this.formData.submarca = jsonData.submarca ? jsonData.submarca : null;
      this.formData.version = jsonData.version ? jsonData.version : null;
      this.formData.coberturasSelected = jsonData.coberturasSelected
        ? jsonData.coberturasSelected
        : null;
      this.formData.tipo_vehiculo = jsonData.tipo_vehiculo
        ? jsonData.tipo_vehiculo
        : null;
      this.formData.origenAuto = jsonData.origenAuto
        ? jsonData.origenAuto
        : null;
      this.formData.numMotor = jsonData.numMotor ? jsonData.numMotor : null;
      this.formData.numSerie = jsonData.numSerie ? jsonData.numSerie : null;
      this.formData.placas = jsonData.placas ? jsonData.placas : null;
      this.formData.uso_auto = jsonData.uso_auto ? jsonData.uso_auto : null;
      this.formData.numMotor = jsonData.numMotor ? jsonData.numMotor : null;
      this.formData.numSerie = jsonData.numSerie ? jsonData.numSerie : null;
      this.formData.placas = jsonData.placas ? jsonData.placas : null;
      this.formData.polizaRenovar = jsonData.polizaRenovar
        ? jsonData.polizaRenovar
        : null;
      this.formData.numeroPoliza = jsonData.numeroPoliza
        ? jsonData.numeroPoliza
        : null;
      this.formData.fechaInicioVigenciaRenovar = jsonData.fechaInicioVigenciaRenovar
        ? jsonData.fechaInicioVigenciaRenovar
        : null;
      this.formData.fechaFinVigenciaRenovar = jsonData.fechaFinVigenciaRenovar
        ? jsonData.fechaFinVigenciaRenovar
        : null;
      this.formData.anios = jsonData.anios ? jsonData.anios : null;
      this.formData.periodicidadSelected = this.datosSolicitud.periodicidadPago
        ? this.datosSolicitud.periodicidadPago
        : null;
      this.formData.formaPagoSelected = this.datosSolicitud.formaDePago
        ? this.datosSolicitud.formaDePago
        : null;
      this.formData.interPlan = jsonData.interPlan
        ? jsonData.interPlan
        : false;
    },
    getFormattDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    getFormatPeriodicidad(id) {
      var p = this.periodicidadItems.find((e) => e.value == id);
      return p ? p.text : "No disponible";
    },
  },
  mounted() {
    this.fillData();
  },
};
</script>
