<template>
  <CommonExpansionPanel :currentPanel="0">
    <template v-slot:title-panel>
      Buscar
    </template>
    <v-expansion-panel-content>
      <div v-if="area_negocio == 6">
        <FiltersAreaEmailsSolicitudes
          @getAreaNegocioFilters="actualizarFiltros"
          :loading="loading"
        />
      </div>
      <div v-else>
        <filters-solicitudes
          @searchUpdated="getSolicitudes"
          :currentFilters="filtros"
          :loading="loading"
        ></filters-solicitudes>
      </div>

      <hr />
      <div v-if="area_negocio == 6">
        <TableAreaNegocioSolicitudes
          :total="total"
          :loading="loading"
          :solicitudes="solicitudes"
          :restartPaginacion="restartPaginacion"
          @restartFlag="restartPaginacion = false"
          @seleccionSolicitud="seleccionSolicitud"
          @paginacionModificada="paginacionModificada"
        />
      </div>
      <div v-else>
        <table-solicitudes
          :loading="loading"
          :solicitudes="solicitudes"
          @seleccionSolicitud="seleccionSolicitud"
        ></table-solicitudes>
      </div>
    </v-expansion-panel-content>
  </CommonExpansionPanel>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import FiltersSolicitudes from "./FiltersSolicitudes.vue";
import TableSolicitudes from "./TableSolicitudes.vue";
import { mainAxios } from "@/mainAxios.js";
import FiltersAreaEmailsSolicitudes from "./FiltrosAreaNegocio/FiltersAreaEmailsSolicitudes.vue";
import { getSolicitudesFiltrosAreaNegocio } from "../../../../../services/solicitudes/Solicitudes.js";
import TableAreaNegocioSolicitudes from "./FiltrosAreaNegocio/TableAreaNegocioSolicitudes.vue";

export default {
  components: {
    CommonExpansionPanel,
    FiltersSolicitudes,
    TableSolicitudes,
    FiltersAreaEmailsSolicitudes,
    TableAreaNegocioSolicitudes,
  },

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
      area_negocio: localStorage.getItem("area_negocio_id"),
      total:0,
      elementosPagina: 10,
      pagina: 1,
      restartPaginacion:false
    };
  },
  props: {
    contactData: Object,
    correosDataFormat: Object,
    routesContactData: Object,
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getSolicitudes(body) {
        const alianzaIdFromUrl = this.$route.params.alianza_id;
        const alianzaIdFromStorage = localStorage.alianzaId !== "null" ? localStorage.alianzaId : null;
        const alianzaId = alianzaIdFromUrl || alianzaIdFromStorage || null;
        
        if (alianzaId) {
          body.alianza_id = alianzaId;
        } else {
          delete body.alianza_id;
        }


      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.loading = true;
      mainAxios
        .post(
          `/v1/solicitud/list/${localStorage.agenteUuid}`,
          body,
          requestConfig
        )
        .then(({ data }) => {
          this.loading = false;
          if (data.length) this.solicitudes = data;
          else this.solicitudes = [];
          this.filtros = body;
        });
    },
    getTodasLasSolicitudes() {
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(`/v1/solicitud/info/status/1`, requestConfig)
        .then(({ data }) => {
          this.todasLasSolicitudes = data;
        });
    },
    seleccionSolicitud(val) {
      this.$emit("seleccionSolicitud", val);
    },
    actualizarFiltros(filters){
      this.filtros = filters
      this.elementosPagina = 10
      this.pagina = 1
      this.restartPaginacion = true
      this.getSolicitudesAreaNegocio()
    },
    async getSolicitudesAreaNegocio() {
      this.loading = true;
      let response = await getSolicitudesFiltrosAreaNegocio(
        this.filtros,
        this.elementosPagina,
        Number(this.pagina)-1
      );
      this.loading = false;
      if (response.data.length){
        this.total = response.total
        this.solicitudes = response.data;
      } 
      else{
        this.solicitudes = [];
        this.total = 0
      } 
    },
    paginacionModificada(paginacion){
      this.elementosPagina = paginacion.elementosPagina
      this.pagina = paginacion.pagina
      this.getSolicitudesAreaNegocio()

    }
  },
  mounted() {
    if (String(window.location.pathname).includes("/alianza/perfil/")) {
      this.getSolicitudes({});
    }
  },
};
</script>
