<template>
  <v-card>
    <v-btn icon class="close-button" @click="$emit('input', false)">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-card-title>
      <v-row>
        <v-col cols="12">
          <h4>Perfil Alianza</h4>
          <span>Actualice aquí la foto y los datos de su empresa.</span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider class="my-2 mx-3"></v-divider>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-img
            :src="
              coverImage
                ? coverImage
                : 'https://via.placeholder.com/820x312.png?text='
            "
            class="cover-image"
          ></v-img>
        </v-col>
        <v-row class="align-items-end">
          <v-col cols="auto" style="margin-top: -32px; margin-left: 30px">
            <v-avatar size="100" class="ml-6" :color="alianzaColor">
              <v-img
                :src="logo ? logo : require('@/assets/Loaderverify.gif')"
                alt="Logo"
              ></v-img>
            </v-avatar>
          </v-col>
          <v-col class="align-self-end">
            <div>
              <h3>{{ nameAlliance }}</h3>
              <p>ID {{ idAliance }}</p>
            </div>
          </v-col>
        </v-row>
      </v-row>
    </v-container>

    <v-card-text style="margin-top: 30px;">
      <v-container>
        <InputFile
          text="Logo compañia"
          typeFile="logoCompania"
          @file-loaded-base64="fileLoaded"
          @file-loaded="fileLoadedFile"
          inputId="logo-file-input"
          :size="'PNG, JPG (max. 170x170px)'"
        />
        <InputFile
          text="Imagen de fondo"
          typeFile="imagenCover"
          inputId="cover-file-input"
          @file-loaded-base64="fileLoaded"
          @file-loaded="fileLoadedFile"
          :size="'PNG, JPG (max. 820x312px)'"
        />
        <v-row>
          <v-col cols="12" md="3">
            <div>
              <span
                class="text-subtitle-2 font-weight-bold"
                style="color: #4d5358"
                >Nombre de alianza
              </span>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="Alianza"
              v-model="alianzaName"
              outlined
              dense
              primary
              color="#00a7e4"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col cols="12" md="3">
            <span
              class="text-subtitle-2 font-weight-bold"
              style="color: #4d5358"
              >Elegir color
            </span>
          </v-col>

          <v-col cols="auto">
            <div
              :style="{ backgroundColor: alianzaColor }"
              class="color-box"
            ></div>
          </v-col>

          <v-col cols="auto">
            <div @click="colorDialog = true" outlined>
              <h3 style="color: #00a5df; padding-bottom: 3px; cursor: pointer">
                Elegir color
                <v-icon right>mdi-pencil-outline</v-icon>
              </h3>
            </div>
          </v-col>
        </v-row>
        <v-dialog v-model="colorDialog" persistent max-width="290px">
          <v-card>
            <v-card-title class="headline">Elija el color</v-card-title>
            <v-card-text>
              <v-color-picker v-model="alianzaColor" flat></v-color-picker>
            </v-card-text>

            <v-card-actions>
              <v-btn text color="primary" @click="colorDialog = false">
                Listo
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="messageDialog" max-width="290px">
          <v-card>
            <v-col align="center">
              <v-icon style="font-size: 70px; margin-bottom: 0" color="blue">
                mdi-alert-circle
              </v-icon>
            </v-col>
            <v-card-title class="headline message">{{ message }}</v-card-title>
            <v-card-actions>
              <v-col align="center">
                <v-btn
                  @click="messageDialog = false"
                  class="common-botton mr-4"
                >
                  Entendido
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="messageDialogSuccess" max-width="fit-content">
          <v-card>
            <v-col align="center">
              <v-img
                :src="require('@/assets/Loaderverify.gif')"
                alt="Success"
                width="70"
                height="70"
                style="margin-bottom: 0"
              ></v-img>
            </v-col>
            <v-card-title class="headline message pt-0">{{
              message
            }}</v-card-title>
            <v-card-actions>
              <v-col align="center">
                <v-btn
                  class="common-botton mr-4"
                  @click="goMainView"
                  :style="{ color: 'white' }"
                >
                  Entendido
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-card-text>
    <v-divider class="my-2 mx-3"></v-divider>
    <v-col cols="12" style="text-align: end">
      <v-btn text color="secondary" @click="$emit('input', false)"
        >Cancelar</v-btn
      >
      <!-- Botón con estilo personalizado, ancho fijo, y comportamiento dinámico que se desactiva y muestra un indicador de carga mientras se ejecuta el método "saveAlianza". -->
      <v-btn
        @click="saveAlianza"
        class="common-botton mr-4"
        dark
        width="200"
        :disabled="isSaving"
        :loading="isSaving"
      >
        {{ isSaving ? "Guardando..." : "Guardar" }}
      </v-btn>
    </v-col>
  </v-card>
</template>

<script>
import { mainAxios } from "../../../../mainAxios";
import InputFile from "../../../components/InputFile.vue";
export default {
  props: {
    idAliance: Number,
    nameAlliance: String,
    value: Boolean,
  },
  name: "ModalEditarMarcaAlianza",
  components: {
    InputFile,
  },
  data() {
    return {
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      step: 1,
      logo: localStorage.getItem("allianceLogo") || null,
      logoFile: null,
      coverImageFile: null,
      coverImage: localStorage.getItem("allianceCover") || null,
      alianzaName: this.nameAlliance,
      alianzaColor: localStorage.getItem("mainColorAlianza") || null,
      colorDialog: false,
      messageDialog: false,
      message: "",
      messageDialogSuccess: false,
      isSaving: false, // Indicador booleano que muestra si se está guardando información; comienza como falso por defecto.
    };
  },
  watch: {
    alianzaColor() {
      console.log(
        "color: ",
        localStorage.getItem("mainColorAlianza"),
        this.alianzaColor
      );
    },
  },
  methods: {
    /**
     * Guarda la configuración de la alianza.
     *
     * Esta función se encarga de enviar una solicitud para actualizar la información de la alianza.
     * Primero, previene múltiples clics estableciendo `isSaving` a `true`.
     * Luego, crea un objeto `FormData` con los datos necesarios, incluyendo el ID de la alianza,
     * el color principal, el logo y la imagen de portada. Si no se proporcionan archivos de logo
     * o portada, intenta obtenerlos desde URLs.
     *
     * Configura los encabezados de la solicitud, incluyendo el token de autorización.
     *
     * Envía una solicitud POST a la API para actualizar la configuración de la marca de la alianza.
     * Si la respuesta es exitosa, muestra un mensaje de éxito. Si hay un error, muestra un mensaje de error.
     * Finalmente, restablece el estado de `isSaving` a `false` independientemente del resultado.
     */
    async saveAlianza() {
      if (this.isSaving) return; // Prevent multiple clicks
      this.isSaving = true; // Establece "isSaving" en verdadero para indicar que el proceso de guardado está en curso.

      const formData = new FormData();
      formData.append(
        "allianceId",
        this.rol == "AGENTCORP"
          ? JSON.parse(localStorage.getItem("alianzaId"))
          : this.idAliance
      );
      formData.append(
        "mainColor",
        this.alianzaColor || localStorage.getItem("mainColorAlianza")
      );
      formData.append(
        "imageLogo",
        this.logoFile || localStorage.getItem("allianceLogo")
      );
      formData.append(
        "imageCover",
        this.coverImageFile || localStorage.getItem("allianceCover")
      );

      if (this.logoFile) {
        formData.append("imageLogo", this.logoFile);
      } else if (this.logo) {
        const logoFile = await this.fetchImageAsFile(this.logo, "logo.png");
        formData.append("imageLogo", logoFile);
      }

      if (this.coverImageFile) {
        formData.append("imageCover", this.coverImageFile);
      } else if (this.coverImage) {
        const coverFile = await this.fetchImageAsFile(
          this.coverImage,
          "cover.png"
        );
        formData.append("imageCover", coverFile);
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      try {
        const response = await mainAxios.post(
          "v1/alliance/brand-config",
          formData,
          config
        );

        if (response.status === 200) {
          this.messageDialogSuccess = true;
          this.message =
            "¡La información de la alianza fue actualizada con éxito!";
          // this.sendMailWelcome();
        } else {
          this.messageDialog = true;
          this.message =
            "Hubo un error al actualizar la información de la alianza.";
        }
        localStorage.removeItem("idAliance");
        localStorage.removeItem("currentStep");
      } catch (error) {
        console.error("Error al actualizar la alianza:", error);
        this.messageDialog = true;
        this.message =
          "Hubo un error al intentar actualizar la alianza. Por favor, inténtalo de nuevo.";
      } finally {
        this.isSaving = false; // Reset the saving state regardless of success/failure
      }
    },
    /**
     * Esta función toma una URL de una imagen y la descarga como un archivo.
     * @param {string} url - La URL de la imagen a descargar.
     * @param {string} fileName - El nombre que se le dará al archivo descargado.
     * @returns {Promise<File>} - Una promesa que se resuelve con el archivo descargado.
     * @throws {Error} - Lanza un error si la descarga falla.
     */
    async fetchImageAsFile(url, fileName) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        return new File([blob], fileName, { type: blob.type });
      } catch (e) {
        console.log("errorrrr: ".e);
      }
    },
    fileLoaded(base64, type) {
      switch (type) {
        case "logoCompania":
          this.logo = base64;
          break;
        case "imagenCover":
          this.coverImage = base64;
          break;
      }
    },
    /**
     * Maneja la carga de archivos y los asigna a las propiedades correspondientes
     * según el tipo de archivo proporcionado.
     *
     * @param {File} file - El archivo cargado.
     * @param {string} type - El tipo de archivo, puede ser "logoCompania" o "imagenCover".
     */
    fileLoadedFile(file, type) {
      switch (type) {
        case "logoCompania":
          this.logoFile = file;
          break;
        case "imagenCover":
          this.coverImageFile = file;
          break;
      }
    },
    // Esta función redirige al usuario a la vista principal dependiendo de su rol
    // y recarga la página para asegurar que los cambios se reflejen.
    goMainView() {
      this.messageDialogSuccess = false;
      this.$router.push({
        name: this.rol == "ADMINAGENT" ? "tablaAlianzasCorporativas" : null,
      });

      window.location.reload();
    },
    /**
     * Envía una solicitud GET para obtener información de una alianza específica
     * utilizando el token de acceso almacenado en localStorage para la autorización.
     */
    async sendMailWelcome() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      await mainAxios.get(`/v1/alliance/${this.idAliance}`, config);
    },
  },
  mounted() {
    const storedColor = localStorage.getItem("mainColorAlianza");

    if (storedColor) {
      this.alianzaColor = storedColor;
    }
  },
};
</script>

<style scoped>
.tall-file-input {
  height: 150px !important;
}

.color-box {
  width: 24px;
  height: 24px;
  border: 1px solid #ccc;
}

.cover-image {
  height: 200px;
  background-color: #e0e0e0;
}

.v-avatar {
  border: 4px solid white;
}

span {
  color: #78909c;
  font-size: 14px;
}

.message {
  text-align: center;
}

.v-card {
  background-color: white !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
  max-width: 100%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}
</style>
