<template>
  <div class="pt-5">
    <commond-form
      :request="solicitudData"
      :route="`/v1/solicitud/update/${solicitud.uuid}`"
      :run="run"
      action="PUT"
      @cancel="run = false"
      @success="chekIfPrePoliza"
      @afterError="run = false"
    >
      <template #data>
        <v-row>
          <v-col cols="12" md="6" v-if="!isAgente">
            <v-autocomplete
              :items="ejecutivos"
              label="Ejecutivo"
              outlined
              v-model="solicitudData.operadorUuid"
              item-text="nombre"
              item-value="id"
            ></v-autocomplete>
          </v-col>
          <v-col v-if="!isAgente" cols="12" md="6">
            <v-select
              v-model="solicitudData.solicitudCompletaSelected"
              label="¿Solicitud Completa?"
              :items="solicitudCompleta"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" v-if="(!isAgente && showEnTramiteCheck) && (isSomeCheckTrue.valor == false || isSomeCheckTrue.campo == 'checkTramite')">
            <v-checkbox
              label="En Trámite"
              v-model="solicitudData.checkTramite"
            ></v-checkbox>
          </v-col>
          <v-col
            cols="12"
            md="6"
            v-if="
              (!isAgente &&
                solicitud.status == 'En tramite' &&
                !solicitudData.checkCotizacionRenovacionAprobada &&
                mostrarInputs) && (isSomeCheckTrue.valor == false || isSomeCheckTrue.campo == 'checkCotizacionRenovacionTerminada')"
          >
            <v-checkbox
              label="Cotización / Renovación Terminada"
              v-model="solicitudData.checkCotizacionRenovacionTerminada"
            ></v-checkbox>
          </v-col>
          <v-col
            cols="12"
            md="6"
            v-if="
              !isAgente && solicitud.status == 'En tramite' && !mostrarInputs
            "
          >
            <v-checkbox
              label="Terminada"
              v-model="solicitudData.checkFinalizada"
            ></v-checkbox>
          </v-col>
          <v-col
            v-if="solicitudData.checkCotizacionRenovacionTerminada"
            cols="12"
            md="6"
          >
            <v-text-field
              label="Valor de la Oportunidad"
              outlined
              prefix="$"
              v-model="solicitudData.valorOportunidad"
              type="number"
              :rules="[$rules.required, $rules.numeric]"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            v-if="(canEmit && solicitud.status == 'Pendiente de Aprobacion') && (isSomeCheckTrue.valor == false || isSomeCheckTrue.campo == 'checkCotizacionRenovacionAprobada')"
          >
            <v-checkbox
              label="Cotización / Renovación Aprobada"
              v-model="solicitudData.checkCotizacionRenovacionAprobada"
            ></v-checkbox>
          </v-col>
          <v-col
            cols="12"
            md="6"
            v-if="
              (!solicitudData.checkCotizacionRenovacionAprobada &&
                solicitud.status == 'Pendiente de Aprobacion') && (isSomeCheckTrue.valor == false || isSomeCheckTrue.campo == 'checkCotizacionRenovacionRechazada')
            "
          >
            <v-checkbox
              label="Cotización / Renovación Rechazada"
              v-model="solicitudData.checkCotizacionRenovacionRechazada"
            ></v-checkbox>
          </v-col>

          <v-col
            cols="12"
            md="6"
            v-if="
              (!solicitudData.checkCotizacionRenovacionAprobada &&
                solicitud.status == 'Pendiente de Aprobacion') && (isSomeCheckTrue.valor == false || isSomeCheckTrue.campo == 'solicitudDatacheckRecotizar')"
          >
            <v-checkbox
              label="Recotizar"
              v-model="solicitudData.solicitudDatacheckRecotizar"
            ></v-checkbox>
          </v-col>
          <v-col
            cols="12"
            v-if="
              solicitudData.checkCotizacionRenovacionRechazada ||
                solicitudData.checkRecotizar
            "
          >
            <v-text-field
              label="Motivo de Recotización o Rechazo"
              v-model="solicitudData.motivoRecotizacionRechazo"
              outlined
              :rules="[$rules.required]"
            ></v-text-field>
          </v-col>

          <v-col v-if="(!isAgente) && (isSomeCheckTrue.valor == false || isSomeCheckTrue.campo == 'checkPendienteInfo')" cols="12" md="6">
            <v-checkbox
              label="Pendiente de Información"
              v-model="solicitudData.checkPendienteInfo"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="6" v-if="(!isAgente) && (isSomeCheckTrue.valor == false || isSomeCheckTrue.campo == 'checkCancelacion')">
            <v-checkbox
              label="Cancelación"
              v-model="solicitudData.checkCancelacion"
            ></v-checkbox>
          </v-col>
          <v-col
            v-if="(!isAgente && solicitudData.checkPendienteInfo)"
            cols="12"
            md="6"
          >
            <v-textarea
              label="Causa de Pendiente de Información"
              v-model="solicitudData.causaPendienteInformacion"
              rows="1"
              auto-grow
              outlined
            ></v-textarea>
          </v-col>
          <v-col
            v-if="!isAgente && solicitudData.checkCancelacion"
            cols="12"
            md="6"
          >
            <v-textarea
              label="Causa de Cancelación"
              v-model="solicitudData.motivoCancelacion"
              rows="1"
              auto-grow
              outlined
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: end;"
          >
            <v-btn
              rounded
              dark
              color="#00a7e4"
              outlined
              @click="$emit('cancel')"
              style="margin-right: 20px;"
            >
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
            <v-btn rounded dark class="common-botton" @click="run = true">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </commond-form>
  </div>
</template>

<script>
import { mainAxios } from "@/mainAxios.js";
import moment from "moment";
import CommondForm from "@/components/commonComponents/CommondForm.vue";

export default {
  components: {
    CommondForm,
  },

  props: {
    solicitud: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      run: false,
      solicitudData: {
        operadorUuid: null,
        solicitudCompletaSelected: null,
        checkPendienteInfo: false,
        checkCancelacion: false,
        causaPendienteInformacion: null,
        motivoCancelacion: null,
        checkTramite: false,
        cotizacionRenovacionAprobada: false,
        checkCotizacionRenovacionAprobada: false,
        checkFinalizada: false,
        checkCotizacionRenovacionRechazada: false,
        checkRecotizar: false,
        estadoRepublicaSelected: null,
      },
      requiredRule: [(v) => !!v || "El campo es requerido"],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      currentPanel: null,
      readonly: true,
      ejecutivos: [],
      procesoCotizacion: ["Cotización", "Emisión", "Finalizado"],
      solicitudCompleta: ["Si", "No"],
      estados: [
        { id: 1, estadoRepublica: "Aguascalientes" },
        { id: 2, estadoRepublica: "Baja California" },
        { id: 3, estadoRepublica: "Baja California Sur" },
        { id: 4, estadoRepublica: "Campeche" },
        { id: 5, estadoRepublica: "Coahuila de Zaragoza" },
        { id: 6, estadoRepublica: "Colima" },
        { id: 7, estadoRepublica: "Chiapas" },
        { id: 8, estadoRepublica: "Chihuahua" },
        { id: 9, estadoRepublica: "Ciudad de México" },
        { id: 10, estadoRepublica: "Durango" },
        { id: 11, estadoRepublica: "Guanajuato" },
        { id: 12, estadoRepublica: "Guerrero" },
        { id: 13, estadoRepublica: "Hidalgo" },
        { id: 14, estadoRepublica: "Jalisco" },
        { id: 15, estadoRepublica: "Estado de México" },
        { id: 16, estadoRepublica: "Michoacán de Ocampo" },
        { id: 17, estadoRepublica: "Morelos" },
        { id: 18, estadoRepublica: "Nayarit" },
        { id: 19, estadoRepublica: "Nuevo León" },
        { id: 20, estadoRepublica: "Oaxaca" },
        { id: 21, estadoRepublica: "Puebla" },
        { id: 22, estadoRepublica: "Querétaro" },
        { id: 23, estadoRepublica: "Quintana Roo" },
        { id: 24, estadoRepublica: "San Luis Potosí" },
        { id: 25, estadoRepublica: "Sinaloa" },
        { id: 26, estadoRepublica: "Sonora" },
        { id: 27, estadoRepublica: "Tabasco" },
        { id: 28, estadoRepublica: "Tamaulipas" },
        { id: 29, estadoRepublica: "Tlaxcala" },
        { id: 30, estadoRepublica: "Veracruz de Ignacio de la Llave" },
        { id: 31, estadoRepublica: "Yucatán" },
        { id: 32, estadoRepublica: "Zacatecas" },
      ],
      toPolizaAhorro: {
        tipoPortafolios: null,
        fumador: null,
        plazoComprometido: null,
        deducibilidad: null,
        revaluableInflacion: null,
        valorPlan: null,
        montoInversion: null,
      },
      toPolizaDaños: {
        tipoInmueble: null,
        otroTipoInmueble: null,
        valorInmueble: null,
        nivelDepartamento: null,
        pisosTotales: null,
        numeroPisosAltos: null,
        sotanos: null,
        valorContenidos: null,
        valorEquipoElectronico: null,
        sumaAseguradaRC: null,
        sumaAseguradaCristales: null,
        sumaAseguradaRoboViolencia: null,
        sumaAseguradaDineroValores: null,
        sumaAseguradaObrasDeArte: null,
      },
      toPolizaFianza: {
        contratoAdjunto: null,
      },
      toPolizaSalud: {
        nivelHospitalario: null,
        sumaAsegurada: null,
        deducible: null,
        coaseguro: null,
        ceroDeducible: null,
        medicinaFueraHospital: null,
        emergenciaExtranjero: null,
        enfermedadesCatastroficas: null,
        coberturaExtranjero: null,
        preexistencias: null,
        proteccionDental: null,
        maternidadExtendida: null,
        coberturaNacional: null,
        reconocimientoAntiguedad: null,
        dependientes: null,
        fechasParaCotizar: null,
        dateFormattedInicioVigencia: null,
        menufechasParaCotizar: false,
      },
      toPolizaVida: {
        plan: null,
        fumador: null,
        sumaAsegurada: null,
        muerteAccidental: null,
        perdidasOrganicas: null,
        invalidezTotal: null,
        gastosFunerarios: null,
      },
    };
  },
  watch: {},
  computed: {
    isAgente() {
      return this.rol == "AGENT";
    },
    canEmit() {
      if (
        this.rol == "AGENT" ||
        this.rol == "OPERADOR" ||
        this.rol == "ADMIN" ||
        this.rol == "OPERACIONES"
      )
        return true;
      else return false;
    },

    isAgenteDirecto() {
      const agente = this.agentes.find(
        (a) => a.id == this.solicitudData.agente
      );
      if (!agente) return false;

      return !!agente.canalList.find((canal) => canal.id == 3);
    },

    esInbound() {
      return this.rol.includes("INBOUND");
    },

    showEnTramiteCheck() {
      return !!["Asignada", "Pendiente de Informacion", "Recotizar"].find(
        (status) => status == this.solicitud.status
      );
    },

    mostrarInputs() {
      if (
        this.solicitud.tipo == "Cotización" ||
        this.solicitud.tipo == "Emisión" ||
        this.solicitud.tipo == "Renovación"
      ) {
        return true;
      } else return false;
    },
    estadoId() {
      if (!this.solicitudData.estadoRepublicaSelected) return "";
      return this.estados.find(
        (e) => e.estadoRepublica == this.solicitudData.estadoRepublicaSelected
      ).id;
    },
    isSomeCheckTrue() {
      let campos = [
        "checkPendienteInfo",
        "checkCancelacion",
        "checkTramite",
        "checkCotizacionRenovacionAprobada",
        "checkFinalizada",
        "checkCotizacionRenovacionRechazada",
        "checkRecotizar",
        "checkCotizacionRenovacionTerminada",
        "solicitudDatacheckRecotizar",
      ];

      const campoEncontrado = campos.find((campo) => this.solicitudData[campo]);

      if (campoEncontrado) {
        return { valor: true, campo: campoEncontrado };
      } else {
        return { valor: false };
      }
    },
  },
  methods: {
    validateStep() {
      if (this.$refs.firstStepForm.validate()) {
        this.$emit("goToSave", this.solicitudData);
      }
    },

    beforeStep() {
      this.$emit("ChangeOnlyStep", 2, null);
    },

    obtenerEjecutivos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/agentes/operador", config).then((response) => {
        response.data.map((element) => {
          this.ejecutivos.push({
            nombre:
              `${element.nombre}` +
              " " +
              `${element.apellidoPaterno}` +
              " " +
              `${element.apellidoMaterno ? element.apellidoMaterno : ""}`,
            id: element.id,
            uuid: element.uuid,
          });
          if (this.rol == "OPERADOR") {
            localStorage.agenteUuid == element.uuid
              ? (this.form.ejecutivo = element.uuid)
              : "";
          }
        });

        const currentOperador = this.ejecutivos.find(
          (e) => e.id == this.solicitud.ejecutivo
        );
        if (!currentOperador)
          this.ejecutivos = [
            {
              nombre: this.solicitud.nombreEjecutivo,
              id: this.solicitud.ejecutivo,
            },
            ...this.ejecutivos,
          ];
      });
    },

    setCurrentData() {
      this.solicitudData = {
        operadorUuid: this.solicitud.ejecutivo,
        procesoCotizacionSelected: "Cotización",
        solicitudCompletaSelected: this.solicitud.solicitudCompleta
          ? "Si"
          : "No",
        checkPendienteInfo: false,
        checkCancelacion: false,
        causaPendienteInformacion: null,
        motivoCancelacion: null,
        checkTramite: false,
        cotizacionRenovacionAprobada: !!this.solicitud
          .cotizacionRenovacionAprobada,
        checkCotizacionRenovacionAprobada: false,
        checkFinalizada: false,
        estadoRepublicaSelected: this.solicitud.estadoRepublicaSelected ?? null,
      };
    },

    chekIfPrePoliza(response) {
      if (this.solicitudData.checkCotizacionRenovacionAprobada) {
        let config = {
          headers: {
            Authorization: "Bearer " + localStorage.agenteAccessToken,
          },
        };
        var jsonInfo = JSON.parse(response.data.body);
        let request = {
          aseguradora_id: this.solicitud.solicitudBody.aseguradora_id ?? 1,
          agente: response.data.agenteId ?? null,
          anios: jsonInfo.anios ? parseInt(jsonInfo.anios) : 1,
          apellido_materno: response.data.apellidoMaterno ?? "",
          apellido_paterno: response.data.apellidoPaterno ?? "",
          calle: jsonInfo.calle ?? null,
          campania: response.data.campania ?? null,
          campania_id: jsonInfo.campania_id ?? null,
          categoriaSelected: response.data.categoria ?? null,
          ciudad: jsonInfo.municipio ?? null,
          codigo_postal: response.data.codigoPostal ?? "",
          colonia: jsonInfo.colonia ?? null,
          correo_electronico: response.data.correoElectronico ?? "",
          cotizacion: 1,
          edad: jsonInfo.edad ?? null,
          estado_id: this.estadoId ?? null,
          fecha_nacimiento: response.data.fechaNacimiento,
          fechaPrimerPago: moment(new Date()).format("YYYY-MM-DD"),
          fin: jsonInfo.fin ?? null,
          forma_pago:
            response.data.formaDePago == "Tarjeta de Crédito"
              ? 1
              : response.data.formaDePago == "Tarjeta de Débito"
              ? 2
              : response.data.formaDePago == "Deposito Bancario"
              ? 3
              : response.data.formaDePago == "Transferencia"
              ? 4
              : response.data.formaDePago == "Cheque"
              ? 5
              : response.data.formaDePago == "Efectivo"
              ? 6
              : response.data.formaDePago == "Domiciliación"
              ? 7
              : "",
          genero: response.data.sexo ?? "",
          inicio: jsonInfo.inicio ?? null,
          leadId: 1,
          compania: "AXA",
          detalle: jsonInfo.detalle ?? "",
          ejecutivo_de_operaciones: this.solicitudData.operadorUuid ?? "",
          fecha_cancelacion: null,
          fecha_emision: null,
          fecha_finalizacion: null,
          nombre: response.data.nombre ?? "",
          notas: "",
          num_exterior: jsonInfo.numExterior ?? null,
          num_interior: jsonInfo.numInterior ?? null,
          numeroCuotas: 0,
          operador_id: this.solicitudData.operadorUuid
            ? this.solicitudData.operadorUuid
            : "",
          origen: response.data.origen ? response.data.origen : "",
          pago_id: 1,
          periodicidad: null,
          /* */ poliza: "",
          /* */ poliza_anterior: null,
          /* */ poliza_cancelada: null,
          /* */ poliza_finalizada: null,
          /* */ prima_neta: 0,
          /* */ prima_total: 0,
          /* */ primer_pago: 0,
          /* */ primer_pago_poliza: 0,
          modificado_por: null,
          motivo_cancelacion: null,
          motivo_finalizacion: null,
          producto_id: jsonInfo.producto_id ?? "",
          razon_social: "",
          rfc: response.data.rfc ? response.data.rfc : "",
          status: 0,
          statusCivil: "Soltero(a)",
          telefono: response.data.celular ? response.data.celular : "",
          tipo_persona: jsonInfo.regimen_persona
            ? jsonInfo.regimen_persona
            : null,
          /* */ tipo_poliza:
            response.data.tipo == "Cotización" ||
            response.data.tipo == "Emisión"
              ? "Nueva"
              : response.data.tipo == "Renovación"
              ? "Renovación"
              : "",
          type: null,
          subsecuente_pago: 0,
          solicitud_id: response.data.id ?? null,
          alianzaId: this.solicitud.alianzaId ? this.solicitud.alianzaId : null
        };
        var ramoData = {};
        switch (response.data.categoria) {
          case "AHORRO":
            ramoData = this.toPolizaAhorro;
            request.prima_neta = jsonInfo.valorPlan ?? null;
            request.prima_total = jsonInfo.valorPlan ?? null;
            request.primer_pago = jsonInfo.valorPlan ?? null;
            request.subsecuente_pago = jsonInfo.montoInversion ?? null;
            request.plazoComprometido = jsonInfo.anios
              ? parseInt(jsonInfo.anios)
              : 1;
            break;
          case "AUTOS":
            request.modelo = String (jsonInfo.modelo) ?? "";
            request.marca = jsonInfo.marca ?? "";
            request.submarca = jsonInfo.submarca ?? "";
            request.detalle_auto = "Sin detalles";
            request.version = jsonInfo.version ?? null;
            request.plan_id = 1;
            request.origen_auto = jsonInfo.origenAuto ?? null;
            (request.uso_auto = jsonInfo.uso_auto
              ? jsonInfo.usoAutoSelected
              : null),
              (request.num_motor = jsonInfo.numMotor ?? "");
            request.num_serie = jsonInfo.numSerie ?? "";
            request.placas = jsonInfo.placas ? jsonInfo.placas : "";
            request.interPlan = jsonInfo.interPlan ? jsonInfo.interPlan : false;
            break;
          case "DAÑOS":
            ramoData = this.toPolizaDaños;
            break;
          case "FIANZA":
            ramoData = this.toPolizaFianza;
            break;
          case "SALUD":
            ramoData = this.toPolizaSalud;
            break;
          case "VIDA":
            ramoData = this.toPolizaVida;
            break;
        }

        if (response.data.categoria != "AUTOS") {
          var keys = Object.keys(ramoData);
          keys.map((e) => {
            if (typeof jsonInfo[e] != "undefined") {
              ramoData[e] = jsonInfo[e];
            }
          });
          request = {
            ...request,
            ...ramoData,
          };
        }

        console.log("request completo:", request);
        mainAxios
          .post("/v1/emision/manual", request, config)
          .then((response) => {
            console.log("Entro a generar pre-poliza", response);
            this.$emit("getData");
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$emit("getData");
      }
      console.log({ response });
    },
  },

  mounted() {
    this.obtenerEjecutivos();
    this.setCurrentData();
  },
};
</script>
