<template>
  <!-- Contenedor principal con padding y borde redondeado -->
  <div class="document-table-container">
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="nombre"
      class="elevation-0"
      :loading="loading"
      loading-text="Cargando..."
      :footer-props="{
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Elementos por página',
      }"
      no-data-text="No hay documentos para mostrar"
    >
      <!-- Columna Lista
           Se utiliza la clase "wrap-text" para controlar el quiebre de línea
           dependiendo del tamaño de la pantalla. -->
      <template v-slot:item.nombre="{ item }">
        <div class="wrap-text">
          {{ item.nombre }}
        </div>
      </template>

      <!-- Columna Descargar
           Botón con ícono alineado a la derecha (definido en headers con align:"end") -->
      <template v-slot:item.id="{ item }">
        <v-btn icon x-small @click="downloadDoc(item.urlBucket)">
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </template>

      <!-- Columna Visualizar
           Botón con ícono de "ojo" para ver el documento.
           Se utiliza :disabled="disabled" para evitar múltiples clics simultáneos. -->
      <template v-slot:item.uuid="{ item }">
        <v-btn icon x-small @click="view(item.urlBucket, item.nombre)" :disabled="disabled">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>

      <!-- Footer con texto de paginación personalizado -->
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        <div v-if="itemsLength">
          Mostrando {{ pageStart }} - {{ pageStop }} de {{ itemsLength }} solicitudes
        </div>
      </template>
    </v-data-table>

    <!-- Diálogo para visualizar el documento en PDF o imagen -->
    <v-dialog v-model="abrirDialog" scrollable transition="scale-transition" max-width="90vw">
      <v-card>
        <v-card-title class="headline">{{ documentName }}</v-card-title>

        <!-- Si es PDF, usamos un iframe con Google Docs viewer -->
        <v-card-text v-if="isPdf" class="d-flex justify-center align-center">
          <iframe
            :src="`https://docs.google.com/gview?url=${encodeURIComponent(documentURL)}&embedded=true`"
            width="100%"
            style="height: 70vh;"
          ></iframe>
        </v-card-text>

        <!-- Si no es PDF (por ejemplo imagen), la mostramos dentro de un img -->
        <v-card-text v-else class="d-flex justify-center align-center">
          <img
            :src="documentURL"
            style="max-width:100%; height:auto;"
            :alt="documentName"
          >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Botón para cerrar el diálogo -->
          <v-btn color="blue darken-1" text @click="cerrarDialog()">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'TablaDocumentosAlianza',
  props: {
    // Datos de la alianza, que incluye la lista de documentos
    alianzaData: Object,
    // Indica si se están cargando los datos
    loading: {
      type: Boolean,
      default: false,
    },
    // Título opcional de la tabla (si se requiriera)
    title: {
      type: String,
    },
    // Permite controlar si se muestra la opción de descarga
    download: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      abrirDialog: false,   // Controla la apertura del diálogo de visualización
      isPdf: false,         // Indica si el documento a visualizar es un PDF
      documentName: "",     // Nombre del documento a mostrar en el diálogo
      documentURL: "",      // URL del documento a mostrar
      disabled: false,      // Deshabilita los botones mientras se carga el documento
      headers: [
        {
          text: "Lista",     // Encabezado de la columna de nombre del documento
          value: "nombre",
          align: "start",
        },
        {
          text: "Descargar", // Encabezado de la columna de descarga
          value: "id",
          sortable: false,
          align: "end",
        },
        {
          text: "Visualizar", // Encabezado de la columna de visualización
          value: "uuid",
          sortable: false,
          align: "end",
        },
      ],
      // Se obtienen los documentos desde alianzaData
      items: this.alianzaData.documentos[0].documentos,
    };
  },
  methods: {
    // Abre el diálogo para visualizar un documento
    view(url, docname) {
      this.disabled = true;
      this.abrirDialog = true;
      this.documentURL = url;
      this.isPdf = url.includes('.pdf?');
      this.documentName = docname;
    },
    // Cierra el diálogo y restaura variables
    cerrarDialog() {
      this.disabled = false;
      this.abrirDialog = false;
      this.isPdf = false;
    },
    // Descarga el documento abriendo el link en una nueva pestaña
    downloadDoc(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.document-table-container {
  background-color: rgba(247, 248, 250, 1);
  padding: 24px;
  border-radius: 20px;
  overflow-x: auto; /* En pantallas grandes, se permite scroll horizontal */
}

/* En pantallas grandes (por defecto), se mantiene el texto en una sola línea (nowrap).
   Si el texto es muy largo, la tabla mostrará scroll horizontal. */
.wrap-text {
  white-space: nowrap;
}

/* En pantallas pequeñas (max-width: 600px),
   permitimos el quiebre de línea (normal) y el word-break,
   además limitamos el ancho para que la información no provoque scroll horizontal.
   Esto asegura que se vean los íconos a la derecha sin necesitar desplazarse. */
@media (max-width: 600px) {
  .wrap-text {
    white-space: normal;
    word-break: break-word;
    max-width: 150px;
  }
}
</style>
