<template>
  <!-- Define a row container for the layout -->
  <v-row>
    <!-- Define a column that takes up all 12 columns of the grid -->
    <v-col cols="12">
      <!-- Use a custom expansion panel component -->
      <CommonExpansionPanel>
        <!-- Define the title slot for the expansion panel -->
        <template v-slot:title-panel> Búsqueda detallada</template>
        <!-- Define the content of the expansion panel -->
        <v-expansion-panel-content>
          <!-- Define a row container with no gutters and a gap of 16px between columns -->
          <v-row no-gutters style="gap: 16px">
            <!-- Define a column that takes up all 12 columns on small screens and 6 columns on medium and larger screens -->
            <v-col cols="12" md="6">
              <!-- Define a text field for searching by name -->
              <v-text-field
                label="Busqueda por nombre(s)"
                v-model="alianzaName"
                @blur="onBlur"
                hint="Buscar un Agente por nombre o correo"
                outlined
                dense
                primary
                color="#00a7e4"
                @keyup.enter="getAsesores"
              ></v-text-field>
            </v-col>
            <!-- Define a column that takes up all 12 columns on small screens and auto width on medium and larger screens -->
            <v-col cols="12" md="auto">
              <!-- Define a button for triggering the search -->
              <v-btn class="common-botton-alianza" dark @click="getAlianzas">
                <span>Buscar</span> <!-- Set the button text to "Buscar" -->
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </CommonExpansionPanel>
    </v-col>
  </v-row>
</template>

<script>
// Import the custom expansion panel component
import CommonExpansionPanel from "../../../../../../components/commonComponents/CommonExpansionPanel.vue";

export default {
  // Register the imported component
  components: { CommonExpansionPanel },
  // Set the name of the component
  name: "CardDashboard",
  // Define the props that the component accepts
  props: {
    title: String, // The title prop should be a string
  },
  // Define the data properties for the component
  data() {
    return {
      alianzaName: "", // Initialize alianzaName as an empty string
    };
  },
  // Define the methods for the component
  methods: {
    // Emit an event to update the filter with the current alianzaName value
    getAsesores() {
      this.$emit("update:filter", this.alianzaName);
    },
    // Emit an event to update the filter when the input loses focus
    onBlur() {
      this.$emit("update:filter", this.alianzaName);
    },
  },
};
</script>

<style scoped></style>
