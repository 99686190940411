<template>
  <v-dialog
    v-model="OpenDialogShowEmails"
    persistent
    max-width="700"
    :scrollable="true"
  >
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="10">
            <v-row>
              <v-col cols="2">
                <v-img :src="emailImg" max-width="40" he alt=""> </v-img>
              </v-col>
              <v-col cols="10">                
                <span>
                  Emails
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="2"
            style="text-align: end; cursor: pointer;"
            @click="closeModal"
          >
            <v-icon left color="#858C94">
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="height: 400px;">
        <v-container>
          <v-row>
            <v-col cols="10">
              <v-text-field
                class="share-bar-clase"
                label="Buscar"
                outlined
                dense
                prepend-inner-icon="mdi-magnify"
                :light="true"
                v-model="filtro"
              ></v-text-field>
            </v-col>
            <v-col
              cols="2"
              style="text-align: end; cursor: pointer;"
              @click="openCreateModal"
            >
              <v-icon left dense color="#0070C0">
                mdi-plus-circle-outline
              </v-icon>
            </v-col>
          </v-row>
          <v-expansion-panels
            class="pb-5"
            v-for="(email, idx) in emails"
            :key="idx"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-row>
                  <v-col cols="5" style="align-self: center;">
                    <h4>
                      {{ email.subject }}
                    </h4>
                  </v-col>
                  <v-col cols="3" style="align-self: center;">
                    {{ email.Onlyfecha }}
                  </v-col>
                  <v-col cols="2" style="align-self: center;">
                      <v-btn 
                      icon
                      :href="email.url_documento"
                    >
                      <v-icon
                          color='#029ECF'
                      >
                        mdi-arrow-down-bold
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="2" style="align-self: center;">
                      {{ email.hora }}
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-html="email.cuerpo"></div>
                <v-row class="pt-4">
                  <v-col cols="12">
                    <h4>
                      Creado por: {{ email.remitente }} el:
                      {{ email.dateFormat || $timezone.formatServerTimezone(email.fecha) }}
                    </h4>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </v-card-text>
      <v-card-actions style="justify-content: center;">
        <div
          style="display: flex; justify-content: center; align-items: center;"
        >
          <v-pagination
            v-model="page"
            :length="pages"
            circle
            color="#0070C0"
          ></v-pagination>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import emailImg from "@/assets/email.svg";

export default {
  components: {},
  props: {
    OpenDialogShowEmails: {
      type: Boolean,
      default: false,
    },
    emails: Array,
  },

  data() {
    return {
      requiredRule: [(v) => !!v || "El campo es requerido"],
      data: {
        nombre: null,
        enviarPorCorreo: false,
        nota: null,
        tipo: "Nota",
        tipo_documento: null,
      },
      run: false,
      formData: null,
      page: 1,
      limit: 5,
      filtro: null,
      emailImg: emailImg
    };
  },

  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    openCreateModal() {
      this.$emit("openCreateModal");
    },
  },
  computed: {
    pages() {
      if (!this.emailsFiltrados) return 1;
      const totalPages = Math.ceil(this.emailsFiltrados.length / this.limit);
      return totalPages < 1 ? 1 : totalPages;
    },
    emailsFiltrados() {
      if (!this.filtro) {
        return this.emails;
      }
      const filtroMin = this.filtro.toLowerCase().trim();
      return this.emails.filter((nota) => {
        return nota.nota.toLowerCase().includes(filtroMin);
      });
    },
    indiceInicio() {
      return (this.page - 1) * this.limit;
    },
    indiceFinal() {
      return this.page * this.limit - 1;
    },
    showNotes() {
      return this.emailsFiltrados.slice(
        this.indiceInicio,
        this.indiceFinal + 1
      );
    },
  },
};
</script>

<style scoped>
.custom-list {
  background-color: #0058fc;
  border-radius: 10px;
  text-align: center;
  margin: 5px 0px 5px 0px;
}

.v-expansion-panel {
  background-color: rgb(165, 55, 238);
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 5px !important;
  background-color: aliceblue;
}
.v-expansion-panel-header {
  background-color: aliceblue;
  border-radius: 5px;
}
</style>
