<template>
  <v-container fluid class="content-container">
    <div>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-row>
            <v-col cols="12">
              <photo-card :personalData="personalData" :stateList="stateList">
              </photo-card>
            </v-col>
            <v-col cols="12">
              <cards-info-panel :infoCards="infoCards" :routesConfigNoteCard="routesConfigNoteCard"
                :personalData="personalData" :emailRoutesConfig="emailRoutesConfig" @getData="getData">
              </cards-info-panel>
            </v-col>
            <v-col cols="12">
              <personal-data-panel :personalData="personalData" :stateList="stateList" :routesConfig="routesConfig"
                :canEdit="canEdit" @getData="getData">
              </personal-data-panel>
            </v-col>
            <v-col cols="12">
              <contact-data-panel :correosDataFormat="correosDataFormat" :contactData="contactData"
                :routesContactData="routesContactData" :canEdit="canEdit" @getData="getData">
              </contact-data-panel>
            </v-col>
            <v-col cols="12">
              <security-data-panel :personalData="personalData" :routesConfigPassword="routesConfigPassword"
                :canEdit="canEdit" @getData="getData">
              </security-data-panel>
            </v-col>
          </v-row>
        </v-col>
      </template>
      <template v-else>
        <v-row class="mobile-layout">
          <v-col cols="12">
            <photo-card :personalData="personalData" :stateList="stateList"></photo-card>
          </v-col>
          <v-col cols="12">
            <personal-data-panel :personalData="personalData" :stateList="stateList" :routesConfig="routesConfig"
              :canEdit="canEdit" @getData="getData"></personal-data-panel>
          </v-col>
          <v-col cols="12">
            <contact-data-panel :correosDataFormat="correosDataFormat" :contactData="contactData"
              :routesContactData="routesContactData" :canEdit="canEdit" @getData="getData"></contact-data-panel>
          </v-col>
          <v-col cols="12">
            <cards-info-panel :infoCards="infoCards" :routesConfigNoteCard="routesConfigNoteCard"
              :personalData="personalData" :emailRoutesConfig="emailRoutesConfig" @getData="getData"></cards-info-panel>
          </v-col>
          <v-col cols="12">
            <security-data-panel :personalData="personalData" :routesConfigPassword="routesConfigPassword"
              :canEdit="canEdit" @getData="getData"></security-data-panel>
          </v-col>
        </v-row>
      </template>

      <!-- <v-col cols="12" sm="12" md="2" lg="2" xl="2" v-if="screenWidth1 > 999">
      <v-row>
        <v-col cols="12">
          <profile-card
            :correo="this.personalData.correo"
            :esAgente="true"
          ></profile-card>
        </v-col>
      </v-row>
    </v-col> -->
    </div>
  </v-container>
</template>

<script>
import PhotoCard from "./PhotoCard.vue";
import PersonalDataPanel from "../components/personalData/PersonalDataPanel.vue";
import ContactDataPanel from "../components/contactData/ContactDataPanel.vue";
import SecurityDataPanel from "../components/securityData/SecurityDataPanel.vue";
// import ProfileCard from "../components/profileData/ProfileCard.vue";
import CardsInfoPanel from "../components/cardsInfo/CardsInfoPanel.vue";
import { mainAxios } from "@/mainAxios";
import moment from "moment";

export default {
  components: {
    PhotoCard,
    PersonalDataPanel,
    ContactDataPanel,
    SecurityDataPanel,
    // ProfileCard,
    CardsInfoPanel,
  },

  data() {
    return {
      config: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
      personalData: {
        rol: "",
      },
      stateList: [],
      correosList: [],
      routes: {},
      routesConfig: {
        completeData: true,
        route: "",
        action: "PUT",
      },
      contactData: {
        celular: "",
        telefono: "",
        idAgente: null,
      },
      routesConfigPassword: {
        route: "",
        action: "POST",
        uuidCambio: "",
      },
      infoCards: {
        notas: [],
        docs: [],
        emails: [],
      },
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      routesConfigNoteCard: {
        route: "",
        action: "POST",
      },
      correosDataFormat: {
        correos: [],
        action: "POST",
      },
      routesContactData: {
        routeCommond: "",
        routeCommondAction: "PUT",
        deleteEmails: "/v1/correo-contacto/delete",
        changeEmails: "/v1/correo-contacto/update/", //query param idCorreo para modificar correo
        insertEmails: "/v1/correo-contacto/insert",
      },
      emailRoutesConfig: {
        sendEmailRoute: "",
        action: "",
        optionCorreosList: [],
        destinatario: "",
      },
      screenWidth1: window.innerWidth,
      canEdit: false,
    };
  },

  methods: {
    getDataContact() {
      if (!this.$route.params.uuid) {
        mainAxios
          .get("/v1/agente/uuid/" + localStorage.agenteUuid, this.config)
          .then((data) => {
            this.contactData.celular = data.data.celular;
            this.contactData.telefono = data.data.telefono;
            this.contactData.idAgente = data.data.id;
          });
      } else {
        mainAxios
          .get("/v1/agente/uuid/" + this.$route.params.uuid, this.config)
          .then((value) => {
            this.contactData.celular = value.data.celular;
            this.contactData.telefono = value.data.telefono;
            this.contactData.idAgente = value.data.id;
          });
      }
    },
    getUserData() {
      this.infoCards.notas = [];
      this.infoCards.docs = [];
      var route = "";
      if (typeof this.$route.params.uuid != "undefined") {
        route = "/v1/agente/uuid/" + this.$route.params.uuid;
        this.routesConfig.route = `/v1/agente/update/${this.$route.params.uuid}`;
        this.routesContactData.routeCommond = `/v1/agente/update/${this.$route.params.uuid}`;
      } else {
        route = "/v1/agente/uuid/" + localStorage.agenteUuid;
        this.routesConfig.route = `/v1/agente/update/${localStorage.agenteUuid}`;
        this.routesContactData.routeCommond = `/v1/agente/update/${localStorage.agenteUuid}`;
      }

      mainAxios.get(route, this.config).then((value) => {
        this.formatPersonalData(value.data);
        this.getCorreos(value.data);
        this.formatNotasDocumentos(value.data.documentos);
        this.formatCorreosInfoCard(value.data.correos);
      });
    },

    routesConfigPasswordConfig() {
      if (
        this.rol === "ADMIN" ||
        this.rol === "ADMINAGENT" ||
        this.rol === "ADMINCC" ||
        this.rol === "MANAGER" ||
        this.rol === "DIRECTOR" ||
        this.rol === "OPERACIONES"
      ) {
        this.routesConfigPassword.route = "/v1/agente/admin/password/update";
        this.routesConfigPassword.uuidCambio = this.$route.params.uuid || localStorage.agenteUuid;
      } else {
        this.routesConfigPassword.route = "/v1/agente/admin/password/update";
        this.routesConfigPassword.uuidCambio = localStorage.agenteUuid;
      }
    },
    formatPersonalData(data) {
      this.personalData.id = data.id ?? null;
      this.personalData.uuid = data.uuid ?? null;
      this.personalData.correo = data.username ?? null;
      this.personalData.nombre = data.nombre ?? null;
      this.personalData.apellidoPaterno = data.apellidoPaterno ?? null;
      this.personalData.apellidoMaterno = data.apellidoMaterno ?? null;
      this.personalData.celular = data.celular ?? null;
      this.personalData.telefono = data.telefono;
      this.personalData.fechaNacimiento = moment(
        data.fechaNacimiento,
        "YYYY-MM-DD"
      ).format("YYYY-MM-DD");
      this.personalData.sexo = data.sexo ?? null;
      this.personalData.nacimientoEstadoId = String(data.nacimientoEstadoId);
      this.personalData.rfc = data.rfc ?? null;
      this.personalData.curp = data.curp ?? null;
      this.personalData.status = data.status ? data.status : 0;
      this.personalData.rol = data.roles[0] ? data.roles[0].rol : data.rol

      localStorage.setItem("rolNotAdmin", this.personalData.rol);
      this.personalData.ramos = data.ramos ?? null;
      this.personalData.canales = data.canales ?? null;
      this.personalData.landings = data.landings ?? null;
      this.personalData.fuenteIngreso = data.fuenteIngreso ?? null;
      this.personalData.regimen = data.regimen ?? null;
      this.personalData.agenteAsociadoPromotoria =
        data.agenteAsociadoPromotoria ?? null;
      this.personalData.tipoAgente = data.tipoAgente ?? null;
      this.personalData.razonSocial = data.razonSocial ?? null;
      this.personalData.clabeBancaria = data.clabeBancaria ?? null;
      this.personalData.claveAgente = data.claveAgente ?? null;
      this.personalData.tipoCedula = data.tipoCedula ?? null;
      this.personalData.nivelAgente = data.nivelAgente ?? null;
      this.personalData.numeroCartera = data.numeroCartera ?? null;
      this.personalData.cedula = data.cedula ?? null;
      this.personalData.rc = data.rc ?? null;
      this.personalData.vigenciaCedula = data.vigenciaCedula
        ? moment(data.vigenciaCedula, "YYYY-MM-DD").format("DD/MM/YYYY")
        : null;
      this.personalData.vigenciaPolizaRc = data.vigenciaPolizaRc
        ? moment(data.vigenciaPolizaRc, "YYYY-MM-DD").format("DD/MM/YYYY")
        : null;
      this.personalData.fecha_ingreso = data.fecha_ingreso
        ? moment(data.fecha_ingreso, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")
        : null;
      this.personalData.turno = data.turno ? data.turno : null;
      this.personalData.motivoBaja = data.motivoBaja ? data.motivoBaja : null;
      this.personalData.vip = data.vip ? data.vip : false;
      this.routesConfigNoteCard.route = `/v1/agente/documento/upload/${data.id}`;
      this.personalData.status = data.status ? data.status : 0;
      this.personalData.numero_colaborador = data.numero_colaborador
        ? data.numero_colaborador
        : null;
      this.canEditMethod();
    },

    getCorreos(data) {
      mainAxios
        .get(`v1/agente/list/correos-contacto/${data.id}`, this.config)
        .then((value) => {
          this.formatCorreos(value.data);
        });
    },
    formatCorreos(correos) {
      // Filtrar correos válidos
      const correosFiltrados = correos.filter(
        (correo) => correo.correo !== null && !correo.error
      );
      // Limpiar el array de destino
      this.correosDataFormat.correos = [];

      // Mapear los correos filtrados y asignarlos
      correosFiltrados.map((c) => {
        this.correosDataFormat.correos.push({
          id: c.id,
          uuid: c.uuid,
          correo: c.correo,
          prospecto_id: c.prospecto_id,
          favorito: c.favorito,
        });
      });

      // Calcular las rutas de correos
      this.calculateRoutesEmails();
    },
    formatCorreosInfoCard(correos) {
      this.infoCards.emails = [];
      correos.map((e) => {
        this.infoCards.emails.push({
          showInfo:
            e.subject +
            +" " +
            moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY") +
            " " +
            e.subject.slice(0, 10) +
            " ... " +
            moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("HH:mm"),
          dateFormat: moment
            .utc(e.fecha)
            .subtract(6, "hours")
            .format("DD/MM/YYYY HH:mm"),
          Onlyfecha: moment
            .utc(e.fecha)
            .subtract(6, "hours")
            .format("DD/MM/YYYY"),
          hora: moment
            .utc(e.fecha)
            .subtract(6, "hours")
            .format("HH:mm"),
          url_documento: e.documentos ? e.documentos[0].url_bucket : false,
          ...e,
        });
      });
      this.calculateRoutesEmails();
    },

    formatNotasDocumentos(docs) {
      this.infoCards.notas = [];
      this.infoCards.docs = [];
      docs.map((e) => {
        if (e.tipoRegistro == "Nota" || e.tipoRegistro == "nota")
          this.infoCards.notas.push({
            formatData:
              moment
                .utc(e.fecha)
                .subtract(6, "hours")
                .format("DD/MM/YYYY") +
              " " +
              e.detalle.slice(0, 10) +
              " ... " +
              moment
                .utc(e.fecha)
                .subtract(6, "hours")
                .format("HH:mm"),
            dateFormat: moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY HH:mm"),
            url: e.url_bucket,
            ...e,
          });
        else {
          this.infoCards.docs.push({
            dateFormat: moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY HH:mm"),
            onlyDate: moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY"),
            url: e.url_bucket,
            ...e,
          });
        }
      });
    },

    getStates() {
      mainAxios.get("/v1/catalogo/estado", this.config).then((value) => {
        this.stateList = value.data;
      });
    },
    calculateRoutesEmails() {
      this.emailRoutesConfig.sendEmailRoute = "/v1/portal/agente/correo/enviar";
      this.emailRoutesConfig.action = "POST";
      this.emailRoutesConfig.optionCorreosList = this.correosDataFormat.correos;
      this.emailRoutesConfig.remitente = localStorage.agenteMail;
      this.emailRoutesConfig.usuario = this.personalData.id;
    },
    getData() {
      this.getUserData();
      this.getStates();
      this.routesConfigPasswordConfig();
    },
    updateScreenSize() {
      this.screenWidth1 = window.innerWidth;
    },
    canEditMethod() {
      var permitedRoles = [];
      if (window.location.pathname.includes("/usuario/ver-micuenta")) {
        switch (this.rol) {
          case "ADMIN":
            this.canEdit = true;
            break;
          case "ADMINAGENT":
            permitedRoles = ["MANAGER", "AGENT", "AGENTCORP", this.rol];
            break;
          case "MANAGER":
            permitedRoles = ["AGENT", this.rol];
            break;
          case "ADMINCC":
            permitedRoles = [
              "MANAGERCC",
              "AGENTCC",
              "AGENTCCSPONSORS",
              "RENOVACIONESCC",
              "COBRANZACC",
              this.rol,
            ];
            break;
          case "OPERACIONES":
            permitedRoles = ["OPERADOR", "AGENT", this.rol];
            break;
          case "MANAGERCC":
            permitedRoles = ["AGENTCC", "AGENTCCSPONSORS", this.rol];
            break;
          case "MANAGEREMAILS":
            permitedRoles = ["BACKOFFICEEMAILS", "OPERACIONESEMAILS", this.rol];
            break;
          case "AGENTCC":
          case "OPERADORCC":
          case "COBRANZACC":
          case "RENOVACIONESCC":
          case "OPERADORINBOUND":
          case "BACKOFFICEEMAILS":
            permitedRoles = [];
            this.canEdit = true;
            break;
          default:
            this.canEdit = true;
        }
        if (permitedRoles.includes(this.personalData.rol)) this.canEdit = true;
        else if (this.rol != "ADMIN") this.canEdit = true;
      } else this.canEdit = true;
    },
  },
  mounted() {
    this.getDataContact();
    this.getData();
    this.personalData = Object.assign({
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      nacimientoEstadoId: 1,
    });
    window.addEventListener("resize", this.updateScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateScreenSize);
  },
};
</script>

<style scoped>
.content-container {
  min-height: calc(100vh - 80px);
  /* Ajusta el tamaño del footer si es diferente a 80px */
  padding-bottom: 0px;
  /* Espacio reservado para el footer */
  box-sizing: border-box;
}

@media (max-width: 959px) {
  .mobile-layout {
    display: flex;
    flex-direction: column;
    padding: 8px;
  }

  /* Ajustar la tarjeta de la foto en móvil */
  .photo-card-mobile {
    position: relative;
  }

  /* Si el photo-card tiene imágenes o texto con posicionamiento absoluto 
     puedes forzarlo a posicionamiento estático en móvil */
  .photo-card-mobile * {
    position: static !important;
  }

  /* Opcional: Ajustar imagen dentro de photo-card si se encimaba */
  .photo-card-mobile img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  .mobile-layout {
    padding-bottom: 220px;
    /* Espacio reservado para el footer */
  }

  /* Añadir márgenes entre columnas para evitar encimados */
  .mobile-layout .v-col {
    margin-bottom: 12px;
  }
}
</style>
