<template>
  <v-footer style="z-index: 0!important; color: white !important;" :class="globalConfig.colorFooter">
    <v-container>
      <v-row class="d-flex align-center">
        <v-col
          xs="12"
          md="10"
          class="d-flex justify-end"
          style="border-right-style: solid;"
        >
          <v-img
            class="mx-5 responsive-img"
            contain
            max-height="100"
            max-width="210"
            :src="globalConfig.logoFooter"
          ></v-img>
        </v-col>
        <v-col xs="12" md="2">
          <span class="footer-text">
            Experience center inter
          </span>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
import loginData from "../helpers/data/data-login-template.json";
import interifyBlack from "@/assets/interify_black.svg";

export default {
  name: "Footer",
  data() {
    return {
      loginTemplate: loginData,
      globalConfig: JSON.parse(localStorage.getItem("globalConfig")) ?? {},
      logo: interifyBlack,
    };
  },
};
</script>
<style scoped>
.footer a:hover {
  color: #ddd;
}
.no-bullets {
  list-style-type: none;
  padding-left: 0;
  font-size: 12px;
}

.row-icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
}

.column-direction {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media (max-width: 600px) {
  .responsive-img {
    max-width: 70%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .row-icons {
    width: 100%;
    justify-content: flex-start;
  }
  .column-direction {
    align-items: flex-start;
  }
}
</style>
