<template>
  <div>
    <!-- Versión escritorio -->
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-row>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4">
          <span class="text-header-data">
            Contraseña
          </span>
          <br />
          <span>
            **************
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
          <v-btn v-if="canEdit" rounded dark class="common-botton" @click="changeComponent">
            <span>
              Cambiar contraseña
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <!-- Versión móvil -->
    <template v-else>
      <v-row class="mobile-container" align="center" justify="center">
        <v-col cols="12" class="mobile-data text-body-1">
          <span class="mobile-header text-body-1">
            Contraseña
          </span>
          <br />
          <span class="mobile-password">
            **************
          </span>
        </v-col>
        <v-col cols="12" class="mobile-button" align="center">
          <v-btn v-if="canEdit" rounded dark class="mobile-change-button" @click="changeComponent">
            <span>
              Cambiar contraseña
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    personalData: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      canIEditThis: false,
      rol: "",
    };
  },

  methods: {
    changeComponent() {
      this.$emit("changeComponent", 2);
    },
  },
  mounted() {
    this.validateRouteAndRol();
  },
};
</script>

<style scoped>
.whitout-padding-bottom {
  padding-bottom: 0px !important;
}

.whitout-padding-top {
  padding-top: 0px !important;
}

@media (max-width: 959px) {
  .mobile-container {
    padding: 16px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }

  .mobile-data {
    text-align: center;
    margin-bottom: 16px;
  }

  .mobile-header {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
  }

  .mobile-password {
    font-size: 1rem;
    color: #666;
  }

  .mobile-button {
    text-align: center;
  }

  .mobile-change-button {
    background-color: #00a7e4 !important;
    color: white;
    font-weight: bold;
    width: 100%;
    max-width: 300px;
  }
}
</style>
