<template>
  <div>
    <v-expansion-panels elevation="0">
      <v-expansion-panel elevation="0">
        <v-expansion-panel-header style="padding: 35px">
          <h3 style="color: #42352e">Búsqueda detallada</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-row dense>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.nombre"
                  label="Nombre"
                  outlined
                  dense
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.apellidoPaterno"
                  label="Apellido Paterno"
                  outlined
                  dense
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.apellidoMaterno"
                  label="Apellido Materno"
                  outlined
                  dense
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.rfc"
                  label="RFC"
                  outlined
                  dense
                  :rules="[$rules.rfcSimple]"
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
               <v-text-field
                v-model="form.telefono"
                label="Teléfono"
                outlined
                dense
                prepend-inner-icon="mdi-phone"
                maxLength="10"
                :rules="[$rules.numeric, localRules.longDigit(10)]"
                background-color="white"
                class="share-bar-clase"
              ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.correo"
                  label="Correo electrónico"
                  outlined
                  dense
                  prepend-inner-icon="mdi-email"
                  :rules="[$rules.email]"
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="pt-5">
      <v-row>
        <v-col cols="6" md="2" v-if="rol != 'AGENT'">
          <v-select
            v-model="form.agente_id"
            :label="this.rol === 'AGENTCORP' || this.rol === 'ADMINAGENT' && this.alianzaIdFromRoute ? 'Asesor' : 'Agente'"
            :items="this.rol === 'AGENTCORP' || this.rol === 'ADMINAGENT' && this.alianzaIdFromRoute ? asesores : agentes"
            item-text="nombreCompleto"
            item-value="id"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="6" md="2">
          <v-select
            v-model="form.status"
            label="Estatus"
            :items="clienteStatus"
            item-text="title"
            item-value="id"
            outlined
            dense
          ></v-select>
        </v-col>

       <v-spacer></v-spacer>
       <v-col cols="12" md="3" class="d-flex justify-end">
        <div class="d-flex button-container"> <!-- Clase para ajustar el margen en pantallas responsive -->
          <v-btn
            dark
            outlined
            color="#00a7e4"
            rounded
            class="mr-2"
            @click="clearForm()"
            min-width="120"
          >
            Limpiar
          </v-btn>
          <v-btn
            class="common-botton"
            dark
            :disabled="!isFormValid || !minimoUnCampoLleno"
            :loading="loading"
            @click="updateSearch"
            min-width="120"
          >
            Filtrar
          </v-btn>
        </div>
      </v-col>

      </v-row>
    </div>
  </div>
</template>

<script>
import { mainAxios } from "@/mainAxios.js";
import moment from "moment";

export default {
  props: {
    currentFilters: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,

      default: false,
    },
  },

  data() {
    return {
      requestConfig: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
      form: {
        emision_id: "",
        status: "",
        agente_id: "",
        ejecutivo_operaciones_id: "",
        ramo: "",
        rango_fechas: "",
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        rfc: "",
        telefono: "",
        correo: "",
      },
      menuFecha: false,
      clienteStatus: [
        {
          id: 0,
          title: "Nuevo",
        },
        {
          id: 1,
          title: "Activo",
        },
        {
          id: 2,
          title: "Inactivo",
        },
        {
          id: -1,
          title: "Baja",
        },
      ],
      agentes: [],
      operadores: [],
      asesores: [],
      ramos: [],
      tipos: [
        "Cotización",
        "Emisión",
        "Renovación",
        "Endoso A y D",
        "Endoso B",
        "Facturacion",
        "Cancelación",
        "Otro",
      ],

      isFormValid: true,
      localRules: {
        longDigit(number) {
          return (v) =>
            !v ||
            v.length == number ||
            "Debe proporcionar " + number + " digitos";
        },
      },
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },

  computed: {
    datesSelectedString() {
      if (this.form.rango_fechas.length > 1)
        return `${this.formatDate(
          this.form.rango_fechas[0]
        )} a ${this.formatDate(this.form.rango_fechas[1])}`;
      return "";
    },

    minimoUnCampoLleno() {
      return (
        Object.keys(this.form).filter(
          (e) => !!this.form[e] || this.form[e] === 0
        ).length >= 1
      );
    },
  },

  watch: {
    currentFilters(v) {
      !!v && this.setCurrent();
    },
  },

  mounted() {
    this.alianzaIdFromRoute = this.$route.params.alianza_id;
    this.getOperadores();
    this.getAgentes();
    this.getRamos();
    this.setCurrent();
    this.checkIfIsAget();
    this.getListAsesores();
  },

  methods: {
    getOperadores() {
      mainAxios
        .get("/v1/agentes/operador", this.requestConfig)
        .then(({ data }) => {
          this.operadores = data.map((e) => ({
            correo: e.correo,
            nombreCompleto: e.nombreCompleto,
            id: e.id,
            uuid: e.uuid,
          }));
        });
    },

    getAgentes() {
      mainAxios.get("/v1/agente/list", this.requestConfig).then(({ data }) => {
        this.agentes = data.map((e) => ({
          correo: e.correo,
          nombreCompleto: e.nombreCompleto,
          id: e.id,
          uuid: e.uuid,
        }));
      });
    },

    getRamos() {
      mainAxios.get("/v1/ramo/list", this.requestConfig).then(({ data }) => {
        this.ramos = data;
      });
    },

    async getListAsesores() {
      mainAxios
        .post(
          `/v1/agente/list-filtro/${this.idParamOrStorage()}`,
          {
            filtroBusqueda: "",
          },
          this.requestConfig
        )
        .then(({ data }) => {
          this.asesores = data.map((e) => ({
            correo: e.correo,
            nombreCompleto: e.nombreCompleto,
            id: e.id,
            uuid: e.uuid,
          }));
          console.log(this.asesores);
        });
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    updateSearch() {
      if (!this.isFormValid || !this.minimoUnCampoLleno) return;
      let payload = {};
      Object.keys(this.form).forEach((e) => {
        if (!!this.form[e] || this.form[e] === 0) payload[e] = this.form[e];
      });
      console.log(payload);

      this.$emit("searchUpdated", payload);
    },

    setCurrent() {
      this.clearForm();
      this.form = { ...this.form, ...this.currentFilters };
    },

    clearForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = "";
      });
    },

    checkIfIsAget() {
      if (this.rol == "AGENT") {
        this.form.agente_id = localStorage.id;
      }
    },
    idParamOrStorage() {
      return this.alianzaIdFromRoute || localStorage.alianzaId;
    },
  },
};
</script>
<style scoped>
.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(
    .v-expansion-panels--tile
  )
  > .v-expansion-panel--active {
  border-radius: 20px !important;
  background-color: #f7f8fa;
}
.v-expansion-panel-header {
  background-color: #f7f8fa;
  border-radius: 20px !important;
}
@media (max-width: 600px) {
  .button-container {
    margin-bottom: 15px; /* Ajusta el espacio */
  }
}
</style>
