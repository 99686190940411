<template>
  <div>
    <v-card class="mx-auto" color="white" width="100%"
      style="border-radius: 20px; box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1)!important;" elevation="0">
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-card-title @click="showAll" v-if="!openAll" style="justify-content: center; cursor: pointer;">
          <v-img :src="notesImg" max-width="29" he alt=""> </v-img>
          <br />
        </v-card-title>
        <v-card-title v-if="openAll" style="cursor: pointer;">
          <v-row>
            <v-col cols="10" @click="showAll">
              <v-row>
                <v-col cols="2">
                  <v-img :src="notesImg" max-width="25" he alt=""> </v-img>
                </v-col>
                <v-col cols="10">
                  <span>
                    Notas
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2" @click="OpenDialogCreateDocument = true" style="text-align: end;" v-if="canEdit">
              <v-img :src="AddIcon" alt="" style="width: 50px;"> </v-img>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text style="text-align: center;" v-if="!openAll">
          <span style="
                font-size: 1.25rem;
                font-weight: 500;
                letter-spacing: 0.0125em;
                line-height: 2rem;
                word-break: break-all;
                color: rgba(0, 0, 0, 0.87);
            ">
            Notas
          </span>
        </v-card-text>
        <v-card-text v-if="openAll">
          <div v-if="notas.length > 0" style="height: 180px !important;">
            <v-row v-for="(nota, idx) in notas.slice(0, 3)" :key="idx">
              <v-col cols="12" class="custom-list">
                {{ nota.formatData }}
                <span style="color:#0070C0; cursor: pointer; " @click="OpenDialogShowNotes = true">
                  Ver
                </span>
              </v-col>
            </v-row>
          </div>
          <div v-if="notas.length > 0">
            <v-row style="text-align: center;">
              <v-col cols="12">
                <v-btn elevation="0" color="white" @click="OpenDialogShowNotes = true">
                  <span style="color:#0070C0">
                    ver mas
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div v-else style="height:  216px !important; text-align: center;">
            <v-row style="text-align: center;">
              <v-col cols="12">
                <h3>
                  No hay nada por el momento
                </h3>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </template>

      <!-- Versión Mobile -->
      <template v-else>
        <v-card-title @click="showAll" v-if="!openAll" style="justify-content: center; cursor: pointer;">
          <v-img :src="notesImg" max-width="29" he alt=""> </v-img>
          <br />
        </v-card-title>

        <v-card-title v-if="!openAll" style="justify-content: center; cursor: pointer;">
          <span style="display: block; font-size: 1.2rem;">Notas</span>
        </v-card-title>


        <v-card-title v-if="openAll" style="cursor: pointer;">
          <v-row>
            <v-col cols="10" @click="showAll">
              <v-row>
                <v-col cols="2">
                  <v-img :src="notesImg" max-width="25" alt=""></v-img>
                </v-col>
                <v-col cols="10">
                  <span>Notas</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text v-if="openAll" style="text-align: center;">
          <div v-if="notas.length > 0" style="height: auto;">
            <v-row v-for="(nota, idx) in notas.slice(0, 3)" :key="idx">
              <v-col cols="12" class="custom-list">
                {{ nota.formatData }}
                <span style="color:#0070C0; cursor: pointer;" @click="OpenDialogShowNotes = true">
                  Ver
                </span>
              </v-col>
            </v-row>
          </div>
          <div v-if="notas.length > 0">
            <v-row style="text-align: center;">
              <v-col cols="12">
                <v-btn elevation="0" color="white" @click="OpenDialogShowNotes = true">
                  <span style="color:#0070C0">
                    ver más
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div v-else style="height: auto; text-align: center;">
            <v-row style="text-align: center;">
              <v-col cols="12">
                <h3>
                  No hay nada por el momento
                </h3>
              </v-col>
            </v-row>
            <v-row style="margin-top: 16px;" v-if="canEdit">
              <v-col cols="12" style="text-align: center;">
                <v-btn outlined color="#00a7e4" @click="OpenDialogCreateDocument = true" rounded>
                  Agregar Nota
                </v-btn>
              </v-col>
              <v-btn elevation="0" color="white" @click="OpenDialogCreateDocument = true"
                style="border: 1px solid #0070C0; border-radius: 8px;">
              </v-btn>
            </v-row>
          </div>
        </v-card-text>
      </template>
    </v-card>
    <create-notes-dialog :OpenDialogCreateDocument="OpenDialogCreateDocument"
      :routesConfigNoteCard="routesConfigNoteCard" @closeModal="OpenDialogCreateDocument = false" @success="getData">
    </create-notes-dialog>
    <show-notes-dialog :OpenDialogShowNotes="OpenDialogShowNotes" :notas="notas"
      @closeModal="OpenDialogShowNotes = false" @openCreateModal="OpenDialogCreateDocument = true">
    </show-notes-dialog>
  </div>
</template>

<script>
import CreateNotesDialog from "../dialogs/CreateNotesDialog.vue";
import ShowNotesDialog from "../dialogs/ShowNotesDialog.vue";
import notes from "@/assets/notes.svg";
import addIcon from "@/assets/addIcon.svg";

export default {
  components: {
    CreateNotesDialog,
    ShowNotesDialog,
  },
  props: {
    notas: Array,
    routesConfigNoteCard: Object,
    openAll: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      showNotes: false,
      OpenDialogCreateDocument: false,
      OpenDialogShowNotes: false,
      notesImg: notes,
      AddIcon: addIcon,
    };
  },

  watch: {
    openAll(val) {
      this.showNotes = val;
    },
  },

  methods: {
    getData() {
      this.OpenDialogCreateDocument = false;
      this.$emit("getData");
    },
    showAll() {
      this.$emit("showAll", !this.showNotes);
    },
  },
};
</script>

<style scoped>
.rounded-card {
  border-radius: 5px;
  max-width: "100%";
  background-color: white;
}

.custom-list {
  background-color: aliceblue;
  border-radius: 5px;
  text-align: center;
  margin: 5px 0px 5px 0px;
}
</style>
