<template>
  <v-dialog
    v-model="dialog"
    :max-width="modalWidth"
    :persistent="true"
  >
    <v-card v-if="step === 1" class="card-dialog">
      <div class="title-container">
        <v-card-title class="headline-first">Confirmar registro</v-card-title>
        <v-btn icon @click="cancelarRegistro" aria-label="Cerrar" class="close-btn">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <p class="subtitle">
          ¿Estás seguro de que los datos del asesor son correctos?
        </p>
        <v-row>
          <v-col cols="6" class="pb-0">
            <p class="titleKey">Nombre:</p>
            <p class="value">{{ asesor.nombre || "[Name]" }}</p>

            <p class="titleKey">Apellido Paterno:</p>
            <p class="value">
              {{ asesor.apellidoPaterno || "[ApellidoPaterno]" }}
            </p>

            <p class="titleKey">Apellido Materno:</p>
            <p class="value">
              {{ asesor.apellidoMaterno || "[ApellidoMaterno]" }}
            </p>

            <p class="titleKey">Género:</p>
            <p class="value">
              {{ asesor.sexo === 1 ? "Masculino" : "Femenino" || "[Género]" }}
            </p>

            <p class="titleKey">Fecha de Nacimiento:</p>
            <p class="value">{{ asesor.fechaNacimiento || "[00/00/00]" }}</p>
          </v-col>
          <v-col cols="6" class="pb-0">
            <p class="titleKey">Estado de Nacimiento:</p>
            <p class="value">{{ obtenerLabelEstadoPorId(asesor.nacimientoEstadoId) }}</p>

            <p class="titleKey">Celular de Contacto:</p>
            <p class="value">{{ asesor.celular || "[0000000000]" }}</p>

            <p class="titleKey">RFC:</p>
            <p class="value">{{ asesor.rfc || "[000000000000]" }}</p>

            <p class="titleKey">Correo Electrónico:</p>
            <p class="value">
              {{ asesor.emails[0].correo || "[mail.com.mx]" }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="actions">
        <v-btn rounded color="primary" @click="confirmarRegistro">
          Confirmar registro
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="step === 2" class="card-dialog">
      <div class="title-container">
        <v-card-title class="headline">¡Registro exitoso!</v-card-title>
        <v-btn icon @click="setearValores" aria-label="Cerrar" class="close-btn">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-col class="pa-0" cols="12" align="center">
          <v-img class="loader small-icon" :src="loader"></v-img>
        </v-col>
        <p class="text-center">
          El registro de {{ asesor.nombre }} {{ asesor.apellidoPaterno }} {{ asesor.apellidoMaterno }} ha sido guardado correctamente.
        </p>
        <p class="text-center">¿Deseas registrar a otro asesor?</p>
      </v-card-text>
      <v-card-actions class="actions">
        <v-btn rounded color="primary" @click="setearValores">Registrar otro asesor</v-btn>
        <!-- Botón con bordes redondeados y color primario que llama al método "setearValores" para registrar otro asesor -->
      </v-card-actions>
    </v-card>
    <v-card v-if="step === 3" class="card-dialog">
      <div class="title-container">
        <v-card-title class="headline">Error en el registro</v-card-title>
        <v-btn icon @click="cancelarRegistro" aria-label="Cerrar" class="close-btn">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-col cols="12" align="center">
          <v-icon size="64" color="#3BBBEA">mdi-alert-circle-outline</v-icon>
        </v-col>
        <p class="text-center">
          <strong>{{ errorMessage }}.</strong> <br />
          El registro de {{ asesor.nombre }} {{ asesor.apellidoPaterno }}
          {{ asesor.apellidoMaterno }} no se pudo completar. Por favor, intente
          nuevamente o consulte con el servicio técnico para asistencia.
        </p>
      </v-card-text>
      <v-card-actions class="actions actions-vertical">
        <v-btn rounded color="primary" @click="confirmarRegistro">
          Intentar nuevamente
        </v-btn>
        <v-btn text color="secondary" @click="cancelarRegistro">
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import loader from "../../../../assets/Loaderverify.gif";
export default {
  props: {
    dialog: Boolean,
    asesor: Object,
    step: Number,
    errorMessage: String,
  },
  data() {
    return {
      loader,
      internalDialog: this.dialog,
      baseWidth: 300,
      maxWidth: 2000,
      pixelsPerChar: 10,
      catalogRamos: [
        { id: 1, label: "Autos" },
        { id: 2, label: "Salud" },
        { id: 3, label: "Daños" },
        { id: 4, label: "Fianza" },
        { id: 5, label: "Vida" },
        { id: 6, label: "Ahorro" },
      ],
      catalogoEstados: [
        { id: 1, estadoRepublica: "Aguascalientes" },
        { id: 2, estadoRepublica: "Baja California Norte" },
        { id: 3, estadoRepublica: "Baja California Sur" },
        { id: 4, estadoRepublica: "Campeche" },
        { id: 5, estadoRepublica: "Coahuila" },
        { id: 6, estadoRepublica: "Colima" },
        { id: 7, estadoRepublica: "Chiapas" },
        { id: 8, estadoRepublica: "Chihuahua" },
        { id: 9, estadoRepublica: "Ciudad de México" },
        { id: 10, estadoRepublica: "Durango" },
        { id: 11, estadoRepublica: "Guanajuato" },
        { id: 12, estadoRepublica: "Guerrero" },
        { id: 13, estadoRepublica: "Hidalgo" },
        { id: 14, estadoRepublica: "Jalisco" },
        { id: 15, estadoRepublica: "Mexico" },
        { id: 16, estadoRepublica: "Michoacan" },
        { id: 17, estadoRepublica: "Morelos" },
        { id: 18, estadoRepublica: "Nayarit" },
        { id: 19, estadoRepublica: "Nuevo Leon" },
        { id: 20, estadoRepublica: "Oxaca" },
        { id: 21, estadoRepublica: "Puebla" },
        { id: 22, estadoRepublica: "Queretaro" },
        { id: 23, estadoRepublica: "Quintana Roo" },
        { id: 24, estadoRepublica: "San Luis Potosi" },
        { id: 25, estadoRepublica: "Sinaloa" },
        { id: 26, estadoRepublica: "Sonora" },
        { id: 27, estadoRepublica: "Tabasco" },
        { id: 28, estadoRepublica: "Tamaulipas" },
        { id: 29, estadoRepublica: "Tlaxcala" },
        { id: 30, estadoRepublica: "Veracruz" },
        { id: 31, estadoRepublica: "Yucatan" },
        { id: 32, estadoRepublica: "Zacatecas" },
        { id: 33, estadoRepublica: "Desconocido" },
      ],
    };
  },
  computed: {
    emailLength() {
      return this.asesor?.emails?.[0]?.correo?.length || 0;
    },
    modalWidth() {
      const calculatedWidth = this.baseWidth + (this.emailLength * this.pixelsPerChar);
      return Math.min(calculatedWidth, this.maxWidth);
    }
  },
  watch: {
    dialog(val) {
      this.internalDialog = val;
    },
    internalDialog(val) {
      this.$emit("update:dialog", val);
    },
    errorMessage(val) {
      console.log("Error message updated:", val);
    },
    'asesor.emails': {
      // Habilita la observación profunda para detectar cambios en propiedades anidadas
      deep: true,
      handler() {
      }
    }
  },
  methods: {
    confirmarRegistro() {
      this.$emit("confirmarRegistro");
    },
    cancelarRegistro() {
      this.$emit("update:dialog", false);
      this.$emit("cambiarStep");
    },
    setearValores() {
      this.$emit("setearValores");
    },
    obtenerLabelsPorIds(ids, catalogo) {
      if (!ids || !catalogo) return [];
      return ids.map((id) => {
        const ramoEncontrado = catalogo.find((ramo) => ramo.id === id);
        return ramoEncontrado ? ramoEncontrado.label : "Desconocido";
      });
    },
    obtenerLabelEstadoPorId(id) {
      if (!id) return "Desconocido";
      const estadoEncontrado = this.catalogoEstados.find(
        (estado) => estado.id === id
      );
      return estadoEncontrado
        ? estadoEncontrado.estadoRepublica
        : "Desconocido";
    },
  },
};
</script>
<style scoped>
.card-dialog {
  position: relative; /* Posicionamiento relativo para ajustes internos */
  border-radius: 16px; /* Esquinas redondeadas de 16px */
  padding-bottom: 16px; /* Espaciado inferior para separación */
  transition: all 0.3s ease; /* Transición suave para cambios */
}
.title-container {
  display: flex; /* Uso de flexbox para alinear elementos */
  align-items: center; /* Centra elementos verticalmente */
  justify-content: center; /* Centra elementos horizontalmente */
  position: relative; /* Posicionamiento relativo para ajustes internos */
}
.headline {
  font-weight: bold; /* Establece el texto en negrita */
  font-size: 18px; /* Tamaño de fuente de 18px */
  margin: 0; /* Elimina los márgenes alrededor del elemento */
  padding-top: 19px; /* Agrega espacio en la parte superior */
  padding-bottom: 0px; /* Elimina el espacio en la parte inferior */
}

.headline-first {
  font-weight: bold; /* Aplica negrita al texto */
  font-size: 18px; /* Tamaño de fuente de 18px */
  margin: 0; /* Sin márgenes alrededor del elemento */
}

.close-btn {
  position: absolute; /* Posiciona el botón de forma absoluta dentro de su contenedor */
  top: 8px; /* Desplaza el botón 8px desde la parte superior */
  right: 8px; /* Desplaza el botón 8px desde la derecha */
}
.subtitle {
  font-family: "Montserrat"; /* Aplica la fuente Montserrat al texto */
  font-size: 14px; /* Tamaño de fuente de 14px */
  font-weight: 600; /* Grosor de fuente seminegrita */
  margin: 0px 17px 16px 0px; /* Márgenes personalizados: sin margen superior, 17px derecha, 16px inferior, sin margen izquierdo */
  text-align: left; /* Alinea el texto a la izquierda */
}
.actions {
  display: flex; /* Usa flexbox para la disposición de los elementos */
  justify-content: center; /* Centra los elementos horizontalmente */
  flex-direction: column; /* Ordena los elementos en una columna */
}
.actions-vertical v-btn + v-btn {
  margin-top: 10px; /* Agrega 10px de espacio superior entre botones consecutivos */
}
.cancel-btn {
  cursor: pointer;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 300;
}
.loader {
  max-width: 91px;
}
.titleKey {
  font-weight: bold;
  font-size: 14px;
}
.value {
  font-size: 13px;
}
.loader.small-icon {
  max-width: 100px; /* Define un ancho máximo de 100px para el ícono pequeño */
}
p {
  font-family: "Montserrat";
  margin: 0; /* Elimina márgenes alrededor del párrafo */
}
</style>
