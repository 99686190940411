<template>
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Modelo
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.modelo ? ramoData.modelo : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Marca
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.marca ? ramoData.marca : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4" v-if="!isInterPlan">
        <span class="text-header-data ">
          Submarca
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.submarca ? ramoData.submarca : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Version
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.version ? ramoData.version : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Plan
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.plan_id ? getPlanList(ramoData.plan_id) : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Origen del Auto
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.origen_auto ? ramoData.origen_auto : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Uso del Auto
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.uso_auto ? ramoData.uso_auto : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          No de Motor
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.num_motor ? ramoData.num_motor : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          No. De Serie
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.num_serie ? ramoData.num_serie : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Placas
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.placas ? ramoData.placas : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <span class="text-header-data ">
          Detalle de la Póliza
        </span>
        <br />
        <span class="text-data-details">
          {{ ramoData.detalle_auto ? ramoData.detalle_auto : "Sin llenar" }}
        </span>
      </v-col>
    </v-row>
</template>

<script>
export default {
  data() {
    return {
      planList: [
        { id: 1, name: "Amplia" },
        { id: 14, name: "Amplia-Plus" },
        { id: 2, name: "Limitada" },
        { id: 3, name: "RC" },
      ],
    };
  },
  props: {
    polizaInfo: {
      type: Object,
      default: () => {},
    },
    ramoData: {
      type: Object,
      default: () => {},
    },
  },
  mounted(){
    console.log(this.ramoData)
  },
  computed:{
    isInterPlan(){
      if(this.ramoData.interPlan)
        return this.ramoData.interPlan
      else return false
    }
  },
  methods: {
    getPlanList(id){
      var plan =  this.planList.find((e) => { return  e.id == id})
      return plan ? plan.name : 'Sin llenar'
    }
  },
};
</script>
