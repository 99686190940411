<template>
  <v-row style="padding: 0px 20px">
    <v-col cols="4">
      <v-autocomplete
        label="Modelo"
        placeholder="Requerido"
        v-model="modelo"
        :items="modelos"
        item-value="id"
        item-text="id"
        auto-select-first
        @change="
          getMarcas();
          avisarCambio();
        "
      ></v-autocomplete>
    </v-col>
    <v-col cols="4">
      <v-autocomplete
        label="Marca"
        placeholder="Requerido"
        v-model="marca"
        :items="marcas"
        item-value="marca"
        item-text="marca"
        no-data-text="Primero selecciona un modelo"
        auto-select-first
        @change="
          getSubmarcas();
          avisarCambio();
        "
      ></v-autocomplete>
    </v-col>
    <v-col cols="4">
      <v-autocomplete
        label="Submarca"
        placeholder="Requerido"
        v-model="submarca"
        :items="submarcas"
        item-value="nombre"
        item-text="nombre"
        no-data-text="Primero selecciona una marca"
        auto-select-first
        @change="
          getVersiones();
          avisarCambio();
        "
      ></v-autocomplete>
    </v-col>
    <v-col cols="4">
      <v-autocomplete
        label="Version"
        placeholder="Requerido"
        v-model="version"
        :items="versiones"
        item-value="nombre"
        item-text="nombre"
        no-data-text="Primero selecciona una submarca"
        auto-select-first
        @change="avisarCambio()"
      ></v-autocomplete>
    </v-col>
    <v-col cols="4">
      <v-textarea
        label="Detalle de la Póliza asdasdasd"
        v-model="detallePoliza"
        rows="1"
        auto-grow
        :rules="inputsRequeridos"
        @change="avisarCambio"
      ></v-textarea>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Plan"
        v-model="plan"
        :items="planList"
        item-text="name"
        item-value="id"
        :rules="inputsRequeridos"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Origen del Auto"
        v-model="origenAuto"
        :items="origenAutoList"
        :rules="inputsRequeridos"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Uso del Auto"
        v-model="usoAuto"
        :items="usoAutoList"
        :rules="inputsRequeridos"
        @change="avisarCambio"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-text-field
        label="No de Motor"
        v-model="noMotor"
        :rules="inputsRequeridos"
        @change="avisarCambio"
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field
        label="No. De Serie"
        v-model="noSerie"
        :maxLength="17"
        :rules="[
          ...inputsRequeridos,
          localRules.alphanumeric,
          $rules.min(15),
          $rules.max(17),
        ]"
        @change="avisarCambio"
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field
        label="Placas"
        v-model="placas"
        :maxLength="10"
        :rules="[...inputsRequeridos, localRules.alphanumeric]"
        @change="avisarCambio"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mainAxios } from "../../../../mainAxios";

export default {
  data() {
    return {
      modelo: null,
      marca: null,
      submarca: null,
      version: null,
      detallePoliza: null,
      plan: null,
      origenAuto: null,
      usoAuto: null,
      noMotor: null,
      noSerie: null,
      placas: null,

      modelos: [],
      marcas: [],
      submarcas: [],
      versiones: [],

      localRules: {
        alphanumeric: (v) =>
          !v ||
          !!v.match(/^[0-9a-zA-Z]+$/) ||
          "Este campo solo acepta letras y números",
      },

      //listas
      planList: [
        { id: 1, name: "Amplia" },
        { id: 14, name: "Amplia-Plus" },
        { id: 2, name: "Limitada" },
        { id: 3, name: "RC" },
      ],
      origenAutoList: [
        "Fronterizo",
        "Importado",
        "Legalizado",
        "Nacional",
        "Salvamento",
      ],
      usoAutoList: [
        "Particular",
        "Comercial",
        "Uso con App",
        "Servicio público federal",
        "Transporte público",
        "Taxi",
      ],

      axiosConfig: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },

      //Validadores
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
    };
  },

  computed: {
    marcaId() {
      if (!this.marca) return null;
      return this.marcas.find((m) => m.marca == this.marca).id;
    },
  },

  props: ["avisar"],

  mounted() {
    this.getModelos();
  },

  methods: {
    getModelos() {
      mainAxios
        .get("/v1/vehiculo/qualitas/modelos", this.axiosConfig)
        .then(({ data }) => {
          this.modelos = data;
        });
    },

    getMarcas() {
      mainAxios
        .get(`/v1/vehiculo/qualitas/marca/${this.modelo}`, this.axiosConfig)
        .then(({ data }) => {
          this.marcas = data;
        });
    },

    getSubmarcas() {
      mainAxios
        .get(
          `/v1/vehiculo/qualitas/submarca/${this.marcaId}/${this.modelo}`,
          this.axiosConfig
        )
        .then(({ data }) => {
          this.submarcas = data;
        });
    },

    getVersiones() {
      mainAxios
        .get(
          `/v1/vehiculo/qualitas/vehiculo/${this.marcaId}/${this.submarca}/${this.modelo}`,
          this.axiosConfig
        )
        .then(({ data }) => {
          this.versiones = data;
        });
    },

    avisarCambio() {
      let datos = {
        modelo: this.modelo,
        marca: this.marca,
        submarca: this.submarca,
        version: this.version,
        detallePoliza: this.detallePoliza,
        plan: this.plan,
        origenAuto: this.origenAuto,
        usoAuto: this.usoAuto,
        noMotor: this.noMotor,
        noSerie: this.noSerie,
        placas: this.placas,
      };
      this.avisar(datos);
    },
  },
};
</script>
