<template lang="">
  <v-row class="my-4">
    <v-col cols="12">
      <v-row justify="center" align="center" class="mb-8" v-if="!isAdmin">
        <v-btn text color="primary" @click="addAsegurado()">
          <v-icon color="primary" class="mr-2">mdi-plus-circle-outline</v-icon>
          Agregar Adicional
        </v-btn>
      </v-row>
      <template>
        <transition-group name="fade" tag="div">
          <v-row
            align="center"
            v-for="(aseguradoX, index) in asegurados"
            :key="index + '_asegurado'"
          >
            <v-col cols="12" md="11">
              <v-card class="mi-tarjeta pa-3">
                <!-- Contenido de la tarjeta -->
                <v-row class="pa-2">
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model.trim="aseguradoX.nombre"
                      label="Nombre"
                      required
                      outlined
                      :rules="nombreRules"
                      :disabled="!aseguradoX.edit"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Fecha de nacimiento"
                      type="date"
                      required
                      outlined
                      v-model="aseguradoX.fechanacimiento"
                      :rules="fechaNacimientoRules"
                      hide-details="auto"
                      :disabled="
                        !aseguradoX.edit && aseguradoX.id !== undefined
                      "
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-select
                      outlined
                      v-model="aseguradoX.parentesco"
                      :items="parentescos"
                      :rules="parentescoRules"
                      label="Parentesco"
                      hide-details="auto"
                      :disabled="
                        !aseguradoX.edit && aseguradoX.id !== undefined
                      "
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="pa-2" align="end">
                  <v-col cols="12" md="4">
                    <v-label>Género</v-label>
                    <v-radio-group
                      v-model="aseguradoX.genero"
                      :rules="generoRules"
                      row
                      :disabled="
                        !aseguradoX.edit && aseguradoX.id !== undefined
                      "
                    >
                      <v-radio
                        label="Masculino"
                        value="Masculino"
                        color="primary"
                      ></v-radio>
                      <v-radio
                        label="Femenino"
                        value="Femenino"
                        color="primary"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="4">
                    <transition name="fade" mode="out-in">
                      <div v-show="aseguradoX.genero === 'Femenino'">
                        <v-label>¿Se encuentra embarazada?</v-label>
                        <v-radio-group
                          v-model="aseguradoX.embarazo"
                          row
                          :disabled="
                            !aseguradoX.edit && aseguradoX.id !== undefined
                          "
                        >
                          <v-radio label="Si" :value="true"></v-radio>
                          <v-radio label="No" :value="false"></v-radio>
                        </v-radio-group>
                      </div>
                    </transition>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model.trim="aseguradoX.cp"
                      label="Código postal"
                      type="number"
                      :rules="cpRules"
                      required
                      outlined
                      maxlength="5"
                      :disabled="
                        !aseguradoX.edit && aseguradoX.id !== undefined
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="d-md-none text-center" v-if="!isAdmin">
                    <v-row>
                      <v-col
                        v-show="!aseguradoX.edit && aseguradoX.id !== undefined"
                      >
                        <v-btn small text @click="edit(index)">
                          <v-icon color="primary"
                            >mdi-pencil-circle-outline</v-icon
                          >
                        </v-btn>
                      </v-col>
                      <v-col
                        v-show="aseguradoX.edit && aseguradoX.id !== undefined"
                      >
                        <v-btn small text @click="saveAsegurado(index)">
                          <v-icon color="primary"
                            >mdi-content-save-outline</v-icon
                          >
                        </v-btn>
                      </v-col>
                      <v-col>
                        <v-icon @click="addAsegurado()" color="primary"
                          >mdi-plus-circle-outline</v-icon
                        >
                      </v-col>
                      <v-col>
                        <v-icon color="red" @click="removeAsegurado(index)"
                          >mdi-trash-can-outline</v-icon
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col md="1" class="d-none d-md-block" v-if="!isAdmin">
              <v-row>
                <v-col cols="12">
                  <v-btn
                    small
                    text
                    @click="edit(index)"
                    v-show="!aseguradoX.edit && aseguradoX.id !== undefined"
                  >
                    <v-icon color="primary">mdi-pencil-circle-outline</v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  v-show="aseguradoX.edit && aseguradoX.id !== undefined"
                >
                  <v-btn small text @click="saveAsegurado(index)">
                    <v-icon color="primary">mdi-content-save-outline</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn small text @click="addAsegurado()">
                    <v-icon color="primary">mdi-plus-circle-outline</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn small text>
                    <v-icon color="red" @click="removeAsegurado(index)"
                      >mdi-trash-can-outline</v-icon
                    >
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </transition-group>
      </template>
    </v-col>
  </v-row>
</template>
<script>
import { mainAxios } from '../../../../mainAxios';
export default {
  name: 'AdditionalData',
  props: {
    aseguradosParent: {
      type: Array,
    },
    parentescosParent: {
      type: Array,
      required: true,
    },
    generosParent: {
      type: Array,
      required: true,
    },
    contratanteId: {
      type: String,
      required: true,
    },
    // Propiedad para saber si el usuario es administrador
    isAdmin: {
      type: Boolean,
      default: false 
    },
  },
  components: {},
  data() {
    return {
      items: ['Plan 1', 'Plan 2', 'Plan 3'],
      show: false,
      idContratante: this.contratanteId,
      parentescos: this.parentescosParent,
      asegurados: this.aseguradosParent,
      generos: this.generosParent,
      nombreRules: [(v) => !!v || 'Nombre es requerido'],
      generoRules: [(v) => !!v || 'Genero es requerido'],
      fechaNacimientoRules: [
        (v) => !!v || 'La fecha de nacimiento es requerida',
      ],
      cpRules: [(v) => !!v || 'Código postal es requerido'],
      emailRules: [(v) => !!v || 'Correo electrónico requerido'],
      tipoDePlanRules: [(v) => !!v || 'Tipo de plan es requerido'],
      telefonoRules: [(v) => !!v || 'Teléfono requerido'],
      parentescoRules: [(v) => !!v || 'Nombre es requerido'],
    };
  },
  methods: {
    edit(indexAse) {
      this.asegurados[indexAse].edit = true;
      this.$emit('updateAsegurados', this.asegurados);
    },
    async saveAsegurado(indexAse) {
      try {
        this.$emit('loading', true);
        if (this.idContratante.length > 0) {
          const config = {
            headers: {
              Authorization: 'Bearer ' + localStorage.agenteAccessToken,
              'inter-token': '' + sessionStorage.interToken,
            },
          };
          const payload = {
            idLead: this.idContratante,
            asegurado: { ...this.asegurados[indexAse] },
          };
          const resp = await mainAxios.post(
            '/v1/seguro-medico/update-asegurado',
            payload,
            config
          );

          const codeResult = resp.data.data.code;
          if (codeResult === '200') {
            await this.getAsegurados();
          }
        }
      } catch (error) {
        console.log(error);
      }
      this.$emit('loading', false);
    },
    async addAsegurado() {
      try {
        this.$emit('loading', true);
        if (this.idContratante.length > 0) {
          const config = {
            headers: {
              Authorization: 'Bearer ' + localStorage.agenteAccessToken,
              'inter-token': '' + sessionStorage.interToken,
            },
          };
          const payload = {
            idLead: this.idContratante,
            asegurado: {
              nombre: '',
              fechanacimiento: '',
              cp: '',
              parentesco: 'Hijo (a)',
              genero: 'Masculino',
              embarazo: false,
            },
          };
          const resp = await mainAxios.post(
            '/v1/seguro-medico/add-asegurado',
            payload,
            config
          );
          const newAsegurado = {
            ...resp.data.data.asegurado.asegurado,
            edit: true,
          };
          this.asegurados.push(newAsegurado);
        } else {
          this.asegurados.push({
            nombre: '',
            fechanacimiento: '',
            cp: '',
            parentesco: 'Hijo (a)',
            genero: 'Masculino',
            embarazo: false,
            edit: true,
          });
        }
      } catch (error) {
        console.log(error);
      }
      this.emitirAsegurados();
      this.$emit('loading', false);
    },
    async removeAsegurado(index) {
      this.$emit('loading', true);
      if (this.asegurados[index].id) {
        const config = {
          headers: {
            Authorization: 'Bearer ' + localStorage.agenteAccessToken,
            'inter-token': '' + sessionStorage.interToken,
          },
        };
        const payload = {
          contratanteId: this.idContratante,
          aseguradoId: this.asegurados[index].id,
        };
        const resp = await mainAxios.post(
          '/v1/seguro-medico/delete-asegurado',
          payload,
          config
        );
        if (resp.data.data.result === 'Asegurado eliminado con éxito') {
          this.asegurados.splice(index, 1);
        }
      } else {
        this.asegurados.splice(index, 1);
      }
      this.emitirAsegurados();
      this.$emit('loading', false);
    },
    async getAsegurados() {
      try {
        const config = {
          headers: {
            Authorization: 'Bearer ' + localStorage.agenteAccessToken,
            'inter-token': '' + sessionStorage.interToken,
          },
        };
        const resp = await mainAxios.get(
          '/v1/seguro-medico/get-asegurados/' + this.idContratante,
          config
        );
        this.asegurados = resp.data.data.asegurado;
      } catch (error) {
        console.log(error);
      }
    },
    emitirAsegurados() {
      this.$emit('updateAsegurados', this.asegurados);
    },
  },
  created() {
    this.asegurados = this.aseguradosParent;
    this.idContratante = this.contratanteId;
    this.parentescos = this.parentescosParent.map(
      (item) => item.nombreParentesco
    );
    this.generos = this.generosParent;
  },
  watch: {
    aseguradosParent: function(nuevoValor) {
      this.asegurados = nuevoValor;
    },
    parentescosParent: function(nuevoValor) {
      this.parentescos = nuevoValor.map((item) => item.nombreParentesco);
    },
    generosParent: function(nuevoValor) {
      this.generos = nuevoValor;
    },
    contratanteId: function(nuevoValor) {
      this.idContratante = nuevoValor;
    },
  },
};
</script>
<style scoped>
.mi-tarjeta {
  border: 1px solid #87ceeb; /* Color azul cielo para el borde */
  box-shadow: none; /* Sin sombra */
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
