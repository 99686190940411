<template>
  <div>
    <commond-form
      :request="formData"
      :route="`/v1/solicitud/update/${datosSolicitud.uuid}`"
      :run="run"
      :action="'PUT'"
      @cancel="run = false"
      @success="$emit('getData')"
      @afterError="$emit('changeComponent')"
    >
      <template v-slot:data>
        <v-row justify="end">
          <v-col cols="auto">
            <v-switch
              v-model="formData.interPlan"
              label="Actualizar a catálogo Coche seguro"
              hide-details
              inset
            ></v-switch>
          </v-col>
        </v-row>
        <v-row v-if="datosSolicitud.tipo == 'Renovación'">
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="No. de Póliza a renovar"
              v-model="formData.polizaRenovar"
              :rules="inputsRequeridos"
              outlined
              :readonly="onlyRead"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Fecha Inicio de vigencia a renovar"
              v-model="formData.fechaInicioVigenciaRenovar"
              :rules="inputsRequeridos"
              type="date"
              outlined
              :readonly="onlyRead"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Fecha Fin de vigencia a renovar"
              v-model="formData.fechaFinVigenciaRenovar"
              type="date"
              :rules="inputsRequeridos"
              outlined
              :readonly="onlyRead"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="3"
            xl="3"
            v-if="
              datosSolicitud.tipo == 'Emisión' ||
                datosSolicitud.tipo == 'Renovación'
            "
          >
            <v-text-field
              v-model="formData.numeroPoliza"
              label="Número de póliza"
              placeholder="Requerido"
              :rules="inputsRequeridos"
              outlined
              :readonly="onlyRead"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.anios"
              label="Años de Vigencia"
              placeholder="Requerido"
              :rules="inputsRequeridos"
              outlined
              :readonly="onlyRead"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.inicio"
              type="date"
              label="Inicio de Vigencia"
              placeholder="Requerido"
              :rules="inputsRequeridos"
              outlined
              :readonly="onlyRead"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.fin"
              type="date"
              label="Fin de Vigencia"
              placeholder="Calculado automáticamente"
              :rules="inputsRequeridos"
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-select
              v-model="formData.periodicidadSelected"
              label="Periodicidad de Pago"
              :items="periodicidadItems"
              item-text="text"
              item-value="value"
              :rules="inputsRequeridos"
              outlined
              :readonly="onlyRead"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-select
              v-model="formData.formaPagoSelected"
              label="Forma de Pago"
              :items="formaPago"
              :rules="inputsRequeridos"
              outlined
              :readonly="onlyRead"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Tipo de Vehículo"
              placeholder="Requerido"
              v-model="formData.tipo_vehiculo"
              :items="tipoVehiculosItems"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
              :readonly="onlyRead"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Origen del Auto"
              v-model="formData.origenAuto"
              :items="origenAutoList"
              :rules="inputsRequeridos"
              outlined
              color="#00a7e4"
              :readonly="onlyRead"
            ></v-autocomplete>
          </v-col>
          <!-- Modelos de autos para vieja y nueva version -->
          <v-col cols="12" md="6" lg="3" xl="3" v-if="!formData.interPlan">
            <v-autocomplete
              label="Modelo"
              placeholder="Requerido"
              v-model="formData.modelo"
              :items="modelos"
              item-value="id"
              item-text="id"
              auto-select-first
              @change="getMarcas()"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
              :readonly="onlyRead"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3" v-if="formData.interPlan">
            <v-autocomplete
              label="Modelo"
              placeholder="Requerido"
              v-model="formData.modelo"
              :items="modelosInter"
              :item-text="(item) => item"
              :item-value="(item) => item"
              auto-select-first
              outlined
              color="#00a7e4"
              @change="getVersionsInter()"
              :loading="loading"
              :rules="inputsRequeridos"
              :readonly="onlyRead"
            ></v-autocomplete>
          </v-col>
          <!-- Marca de autos para vieja y nueva version con api inter -->
          <v-col cols="12" md="6" lg="3" xl="3" v-if="!formData.interPlan">
            <v-autocomplete
              label="Marca"
              placeholder="Requerido"
              v-model="formData.marca"
              :items="marcas"
              item-value="marca"
              item-text="marca"
              no-data-text="Primero selecciona un modelo"
              auto-select-first
              @change="getSubmarcas()"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
              :readonly="onlyRead"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3" v-if="formData.interPlan">
            <v-autocomplete
              label="Marca"
              placeholder="Requerido"
              v-model="formData.marca"
              :items="marcasInter"
              :item-text="(item) => item"
              :item-value="(item) => item"
              no-data-text="Primero selecciona un modelo"
              auto-select-first
              @change="getVersionsInter()"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
              :readonly="onlyRead"
            ></v-autocomplete>
          </v-col>
          <!-- En el nuevo no viene separada por submarca -->
          <v-col cols="12" md="6" lg="3" xl="3" v-if="!formData.interPlan">
            <v-autocomplete
              label="Submarca"
              placeholder="Requerido"
              v-model="formData.submarca"
              :items="submarcas"
              item-value="nombre"
              item-text="nombre"
              no-data-text="Primero selecciona una marca"
              auto-select-first
              @change="getVersiones()"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
              :readonly="onlyRead"
            ></v-autocomplete>
          </v-col>
          <!-- Version  de autos para vieja y nueva version con api inter -->
          <v-col cols="12" md="6" lg="3" xl="3" v-if="!formData.interPlan">
            <v-autocomplete
              label="Version"
              placeholder="Requerido"
              v-model="formData.version"
              :items="versiones"
              item-value="nombre"
              item-text="nombre"
              no-data-text="Primero selecciona una submarca"
              auto-select-first
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
              :readonly="onlyRead"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3" v-if="formData.interPlan">
            <v-autocomplete
              label="Version"
              placeholder="Requerido"
              v-model="formData.version"
              :items="versionesInter"
              item-text="description"
              item-value="description"
              no-data-text="Primero selecciona una submarca"
              auto-select-first
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
              :readonly="onlyRead"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Plan"
              v-model="formData.coberturasSelected"
              :items="planList"
              item-text="name"
              item-value="name"
              :rules="inputsRequeridos"
              outlined
              color="#00a7e4"
              :readonly="onlyRead"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Uso del Auto"
              v-model="formData.uso_auto"
              :items="usoAutoList"
              :rules="inputsRequeridos"
              outlined
              color="#00a7e4"
              :readonly="onlyRead"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              label="No de Motor"
              v-model="formData.numMotor"
              :rules="inputsRequeridos"
              outlined
              color="#00a7e4"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              label="No. De Serie"
              v-model="formData.numSerie"
              :maxLength="17"
              :rules="[
                ...inputsRequeridos,
                localRules.alphanumeric,
                $rules.min(15),
                $rules.max(17),
              ]"
              outlined
              color="#00a7e4"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Placas"
              v-model="formData.placas"
              :maxLength="10"
              :rules="[...inputsRequeridos, localRules.alphanumeric]"
              outlined
              color="#00a7e4"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: end;"
          >
            <v-btn
              rounded
              dark
              color="#00a7e4"
              outlined
              @click="$emit('changeComponent')"
              style="margin-right: 20px;"
            >
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
            <v-btn rounded dark class="common-botton" @click="run = true">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </commond-form>
    <CommondLoading
      :show="loading"
      :loadingImage="dataLoading.iconCar"
      loadingText="Cargando catalogo de autos..."
    />
  </div>
</template>

<script>
import { mainAxios } from "@/mainAxios.js";
import CommondForm from "@/components/commonComponents/CommondForm.vue";
import { getBranches, getVersions } from "@/services/autos/autos.service.js";
import CommondLoading from "@/components/commonComponents/CommondLoading.vue";
import dataLoading from "@/helpers/data/data-loading-gif.json";

//import moment from "moment";

export default {
  data() {
    return {
      formData: {
        modelo: null,
        marca: null,
        submarca: null,
        version: null,
        coberturasSelected: null,
        tipo_vehiculo: null,
        origenAuto: null,
        uso_auto: null,
        numMotor: null,
        numSerie: null,
        placas: null,
        interPlan: false,
      },

      modelos: [],
      marcas: [],
      submarcas: [],
      versiones: [],

      localRules: {
        alphanumeric: (v) =>
          !v ||
          !!v.match(/^[0-9a-zA-Z]+$/) ||
          "Este campo solo acepta letras y números",
      },

      //listas
      planList: [
        { id: 1, name: "Amplia" },
        { id: 14, name: "Amplia-Plus" },
        { id: 2, name: "Limitada" },
        { id: 3, name: "RC" },
      ],
      origenAutoList: [
        "Fronterizo",
        "Importado",
        "Legalizado",
        "Nacional",
        "Salvamento",
      ],
      usoAutoList: [
        "Particular",
        "Comercial",
        "Uso con App",
        "Servicio público federal",
        "Transporte público",
        "Taxi",
      ],
      tipoVehiculosItems: [
        "Auto",
        "Motocicleta",
        "Pick-up",
        "Camión",
        "Tractocamión",
      ],
      formaPago: [
        "Tarjeta de Crédito",
        "Tarjeta de Débito",
        "Deposito Bancario",
        "Transferencia",
        "Cheque",
        "Efectivo",
        "Domiciliación",
      ],
      periodicidadItems: [
        { text: "Anual", value: 1 },
        { text: "Semestral", value: 2 },
        { text: "Trimestral", value: 3 },
        { text: "Mensual", value: 4 },
      ],
      axiosConfig: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
      //Validadores
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
      run: false,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      loading: false,
      dataLoading: dataLoading,
      modelosInter: this.generateLast60Years(),
      marcasInter: [],
      versionesInter: [],
    };
  },
  components: {
    CommondForm,
    CommondLoading,
  },
  computed: {
    marcaId() {
      if (!this.formData.marca && this.marcas.length) return null;
      return this.marcas.find((m) => m.marca == this.formData.marca).id;
    },
    onlyRead() {
      return false;
    },
  },
  watch: {
    "formData.interPlan"(value) {
      if (value) this.getToken();
    },
  },
  props: {
    datosSolicitud: Object,
  },
  mounted() {
    this.fillData();
  },
  methods: {
    getModelos() {
      mainAxios
        .get("/v1/vehiculo/qualitas/modelos", this.axiosConfig)
        .then(({ data }) => {
          this.modelos = data;
        });
    },

    getMarcas() {
      mainAxios
        .get(
          `/v1/vehiculo/qualitas/marca/${this.formData.modelo}`,
          this.axiosConfig
        )
        .then(({ data }) => {
          this.marcas = data;
        });
    },

    getSubmarcas() {
      mainAxios
        .get(
          `/v1/vehiculo/qualitas/submarca/${this.marcaId}/${this.formData.modelo}`,
          this.axiosConfig
        )
        .then(({ data }) => {
          this.submarcas = data;
        });
    },

    getVersiones() {
      mainAxios
        .get(
          `/v1/vehiculo/qualitas/vehiculo/${this.marcaId}/${this.formData.submarca}/${this.formData.modelo}`,
          this.axiosConfig
        )
        .then(({ data }) => {
          this.versiones = data;
        });
    },

    fillData() {
      var jsonData = JSON.parse(this.datosSolicitud.body);
      console.log("informacion aqui ", jsonData);
      this.formData.modelo = jsonData.modelo ? jsonData.modelo : null;
      this.formData.marca = jsonData.marca ? jsonData.marca : null;
      this.formData.submarca = jsonData.submarca ? jsonData.submarca : null;
      this.formData.version = jsonData.version ? jsonData.version : null;
      this.formData.coberturasSelected = jsonData.coberturasSelected
        ? jsonData.coberturasSelected
        : null;
      this.formData.tipo_vehiculo = jsonData.tipo_vehiculo
        ? jsonData.tipo_vehiculo
        : null;
      this.formData.origenAuto = jsonData.origenAuto
        ? jsonData.origenAuto
        : null;
      this.formData.uso_auto = jsonData.uso_auto ? jsonData.uso_auto : null;
      this.formData.numMotor = jsonData.numMotor ? jsonData.numMotor : null;
      this.formData.numSerie = jsonData.numSerie ? jsonData.numSerie : null;
      this.formData.placas = jsonData.placas ? jsonData.placas : null;
      this.formData.polizaRenovar = jsonData.polizaRenovar
        ? jsonData.polizaRenovar
        : null;
      this.formData.fechaInicioVigenciaRenovar = jsonData.fechaInicioVigenciaRenovar
        ? jsonData.fechaInicioVigenciaRenovar
        : null;
      this.formData.fechaFinVigenciaRenovar = jsonData.fechaFinVigenciaRenovar
        ? jsonData.fechaFinVigenciaRenovar
        : null;
      this.formData.anios = jsonData.anios ? jsonData.anios : null;
      this.formData.inicio = this.datosSolicitud.fechaInicioVigencia
        ? this.datosSolicitud.fechaInicioVigencia
        : null;
      this.formData.fin = this.datosSolicitud.fechaFinVigencia
        ? this.datosSolicitud.fechaFinVigencia
        : null;
      this.formData.numeroPoliza = jsonData.numeroPoliza
        ? jsonData.numeroPoliza
        : null;
      this.formData.periodicidadSelected = this.datosSolicitud.periodicidadPago
        ? this.datosSolicitud.periodicidadPago
        : null;
      this.formData.formaPagoSelected = this.datosSolicitud.formaDePago
        ? this.datosSolicitud.formaDePago
        : null;
      this.formData.interPlan = jsonData.interPlan ? jsonData.interPlan : false;
      this.getModelos();
      this.getMarcas();
      setTimeout(() => {
        if (
          this.formData.modelo != null &&
          this.formData.marca != null &&
          this.formData.submarca != null &&
          this.formData.version != null
        ) {
          this.getSubmarcas();
          this.getVersiones();
        }
      }, 1500);
    },

    getToken() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.loading = true;
      return mainAxios.get("/v1/cotizacion-ws/auto/init", config).then(
        ({
          data: {
            data: { accessToken },
          },
        }) => {
          localStorage.setItem("interTokenCoche", accessToken);
          this.getBranchesInter();
        }
      );
    },
    async getBranchesInter() {
      this.marcasInter = await getBranches();
      this.getVersionsInter();
    },
    async getVersionsInter() {
      this.loading = true;
      this.versionesInter = await getVersions(
        this.formData.marca,
        this.formData.modelo
      );
      this.loading = false;
    },
    generateLast60Years() {
      const currentYear = new Date().getFullYear();
      console.log({ currentYear });
      let years = [];
      for (let i = 0; i <= 60; i++) {
        years.push(currentYear - i);
      }
      return years;
    },
  },
};
</script>
