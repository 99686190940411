import Vue from "vue";
import Vuetify from "vuetify/lib";
import es from "vuetify/es5/locale/es";
Vue.use(Vuetify);

export default new Vuetify({  lang: {
  locales: {
    es: {
      ...es, // Utiliza el idioma español incluido en Vuetify
      dataTable: {
        sortBy: "Ordenar por", // Cambia "Sort by" globalmente
      },
    },
  },
  current: "es", // Establece el idioma a español
},
  theme: {
    themes: {
      light: {
        primary: "#00a7e4", // #E53935
        secondary: "#4D5358",
      },
    },
  },
});
