<template>
  <div>
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <commond-form
        :request="password"
        :route="routesConfigPassword.route"
        :run="run"
        :action="routesConfigPassword.action"
        @cancel="run = false"
        @success="changeComponent"
        @afterError="changeComponent"
      >
        <template v-slot:data>
          <v-row>
            <v-col
              class="whitout-padding-bottom"
              cols="12"
              sm="6"
              md="4"
              lg="4"
              xl="4"
            >
              <v-text-field
                label="Contraseña nueva"
                outlined
                v-model="password.newPassword"
                color="#00a7e4"
                required
                :type="showNewPassword ? 'text' : 'password'"
                append-icon="mdi-eye"
                @click:append="showNewPassword = !showNewPassword"
              ></v-text-field>
              <!-- Password Validation Rules -->
              <div class="validation-rules">
                <!--
                Itera sobre la lista de reglas de validación (validationRules) y
                genera un elemento div para cada regla, utilizando el índice como clave.
                -->
                <div
                  v-for="(rule, index) in validationRules"
                  :key="index"
                  class="validation-rule-item"
                >
                  <v-icon
                    small
                    :color="
                      rule.isValid(password.newPassword)
                        ? 'success'
                        : 'grey lighten-1'
                    "
                  >
                    mdi-check-circle
                  </v-icon>
                  <span class="validation-text">{{ rule.message }}</span>
                </div>
              </div>
            </v-col>
            <v-col
              class="whitout-padding-bottom"
              cols="12"
              sm="6"
              md="4"
              lg="4"
              xl="4"
            >
              <v-text-field
                label="Confirmar contraseña"
                outlined
                v-model="password.confirmPassword"
                color="#00a7e4"
                required
                :error-messages="passwordMatchError"
                :type="showConfirmNewPassword ? 'text' : 'password'"
                append-icon="mdi-eye"
                @click:append="showConfirmNewPassword = !showConfirmNewPassword"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
      </commond-form>

      <v-row style="padding-top: 50px;">
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          style="text-align: end;"
        >
          <v-btn
            rounded
            dark
            outlined
            color="#00a7e4"
            @click="changeComponent"
            style="margin-right: 11px;"
          >
            <span>Cancelar</span>
          </v-btn>
          <v-btn
            rounded
            dark
            class="common-botton"
            @click="afterSend"
            :disabled="!isFormValid"
          >
            <span>Cambiar contraseña</span>
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <!-- Versión móvil -->
    <template v-else>
      <div class="mobile-container">
        <commond-form
          :request="password"
          :route="routesConfigPassword.route"
          :run="run"
          :action="routesConfigPassword.action"
          @cancel="run = false"
          @success="changeComponent"
          @afterError="changeComponent"
        >
          <template v-slot:data>
            <v-row>
              <v-col cols="12" class="mobile-field">
                <v-text-field
                  label="Contraseña nueva"
                  outlined
                  v-model="password.newPassword"
                  color="#00a7e4"
                  required
                  :type="showNewPassword ? 'text' : 'password'"
                  append-icon="mdi-eye"
                  @click:append="showNewPassword = !showNewPassword"
                ></v-text-field>
                <div class="validation-rules">
                  <div
                    v-for="(rule, index) in validationRules"
                    :key="index"
                    class="validation-rule-item"
                  >
                    <v-icon
                      small
                      :color="
                        rule.isValid(password.newPassword)
                          ? 'success'
                          : 'grey lighten-1'
                      "
                    >
                      mdi-check-circle
                    </v-icon>
                    <span class="validation-text">{{ rule.message }}</span>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="mobile-field">
                <v-text-field
                  label="Confirmar contraseña"
                  outlined
                  v-model="password.confirmPassword"
                  color="#00a7e4"
                  required
                  :error-messages="passwordMatchError"
                  :type="showConfirmNewPassword ? 'text' : 'password'"
                  append-icon="mdi-eye"
                  @click:append="
                    showConfirmNewPassword = !showConfirmNewPassword
                  "
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
        </commond-form>

        <div class="mobile-actions">
          <v-btn
            rounded
            dark
            outlined
            color="#00a7e4"
            @click="changeComponent"
            class="mobile-cancel-button"
          >
            <span>Cancelar</span> <!-- Muestra el texto "Cancelar" dentro de un elemento <span> para indicar una acción de cancelación. -->
          </v-btn>
          <v-btn
            rounded
            dark
            class="mobile-submit-button"
            @click="afterSend"
            :disabled="!isFormValid"
          >
            <span>Cambiar contraseña</span>
          </v-btn>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CommondForm from "@/components/commonComponents/CommondForm.vue";

export default {
  components: {
    CommondForm,
  },
  props: {
    personalData: Object,
    routesConfigPassword: Object,
  },
  data() {
    return {
      password: {
        newPassword: "",
        confirmPassword: "",
      },
      run: false,
      route: "/v1/prospecto/perfil/password",
      requiredRule: [(v) => !!v || "El campo es requerido"],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      showNewPassword: false,
      showConfirmNewPassword: false,
      validationRules: [
        {
          message: "Debe incluir por lo menos 6 caracteres", // Mensaje de error si la contraseña no tiene al menos 6 caracteres
          isValid: (pass) => pass.length >= 6, // Valida que la longitud de la contraseña sea de al menos 6 caracteres
        },
        {
          message: "Debe incluir letras mayúsculas y minúsculas", // Mensaje de error si no incluye letras en mayúsculas y minúsculas
          isValid: (pass) => /[A-Z]/.test(pass) && /[a-z]/.test(pass), // Valida que haya al menos una letra mayúscula y una minúscula
        },
        {
          message: "Debe incluir un número y un carácter especial", // Mensaje de error si no contiene al menos un número y un carácter especial
          isValid: (pass) =>
            /[0-9]/.test(pass) && // Valida que haya al menos un número
            /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(pass), // Valida que haya al menos un carácter especial
        },
      ],
    };
  },
  methods: {
    changeComponent() {
      this.$emit("changeComponent", 1);
    },
    afterSend() {
      if (typeof this.routesConfigPassword.uuidCambio != "undefined")
        this.password.uuid = this.routesConfigPassword.uuidCambio;
      this.run = true;
    },
  },
  mounted() {
    if (this.rol != "PROSPECTO") {
      this.password.idProspecto = this.personalData.idProspecto;
    }
    console.log("hereee", this.personalData, this.routesConfigPassword);
  },
  computed: {
    // Verifica si las contraseñas ingresadas coinciden y retorna un mensaje de error si no coinciden.
    passwordMatchError() {
      return this.password.confirmPassword &&
        this.password.confirmPassword !== this.password.newPassword
        ? ["Las contraseñas no coinciden"]
        : [];
    },
    /**
     * Verifica si el formulario es válido comprobando que la nueva contraseña
     * cumpla con los requisitos de longitud, mayúsculas, minúsculas, números,
     * caracteres especiales y que coincida con la confirmación de contraseña.
     */
    isFormValid() {
      return (
        this.password.newPassword.length >= 6 &&
        /[A-Z]/.test(this.password.newPassword) &&
        /[a-z]/.test(this.password.newPassword) &&
        /[0-9]/.test(this.password.newPassword) &&
        /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(this.password.newPassword) &&
        this.password.newPassword === this.password.confirmPassword
      );
    },
  },
};
</script>

<style scoped>
.whitout-padding-bottom {
  padding-bottom: 0px !important;
}

.whitout-padding-top {
  padding-top: 0px !important;
}

.v-text-field--outlined fieldset {
  color: #00a7e4 !important;
}

.validation-rules { /* Estilos para las reglas de validación de la contraseña */
  margin-top: 8px; /* Margen superior de 8px */
}

.validation-rule-item { /* Estilos para cada regla de validación */
  display: flex;  /* Muestra los elementos en línea */
  align-items: center;  /* Alinea los elementos verticalmente */
  margin-bottom: 8px; /* Margen inferior de 8px */
}

.validation-rule-item .v-icon {   /* Estilos para el icono de validación */
  margin-right: 8px;  /* Margen derecho de 8px */
}

.validation-text { /* Estilos para el texto de validación */
  color: #303030; /* Color del texto */
  font-family: "Montserrat"; /* Fuente del texto */
  font-size: 12px; /* Tamaño de la fuente */
  font-weight: 500; /* Grosor de la fuente */
}

@media (max-width: 959px) {
  .mobile-container {
    padding: 16px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .mobile-field {
    margin-bottom: 16px;
  }

  .mobile-actions {
    text-align: center;
    margin-top: 16px;
  }

  .mobile-cancel-button {
    margin-right: 8px;
    color: #00a7e4;
    margin-bottom: 15px;
  }

  .mobile-submit-button {
    background-color: #00a7e4 !important;
    color: white;
    font-weight: bold;
    width: 100%;
    max-width: 300px;
  }
}
</style>
