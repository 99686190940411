<template>
  <common-card>
    <v-row>
      <v-col>
        <v-row style="padding: 15px 20px 0px 20px">
          <v-col cols="12" md="6">
            <h1>Alianzas Corporativas</h1>
          </v-col>
          <v-col cols="12" style="text-align: end; margin-bottom: 25px">
            <span
              class="button-span-styles-alianza"
              @click="$router.push('/crear/alianza')"
              >Crear nueva alianza
            </span>
            <v-icon color="#029ECF" class="mr-1"
              >mdi-plus-circle-outline</v-icon
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-expansion-panels
      elevation="0"
      style="padding: 30px; border-radius: 20px"
    > 
    <!-- Sección de búsqueda de alianzas desktop-->
      <v-expansion-panel elevation="0" v-if="$vuetify.breakpoint.mdAndUp">
        <v-expansion-panel style="padding: 35px">
          <h3 style="color: #42352e">Buscar alianza</h3>
          <v-row no-gutters style="gap: 41px">
            <v-col>
              <v-text-field
                v-model="search"
                label="Alianza"
                hint="Buscar una alianza por nombre"
                class=" my-2"
                outlined
                dense
                color="primary"
                background-color="white"
                @keyup.enter="getAlianzas"
              />
            </v-col>
            <v-col cols="auto">
              <v-btn
                class="common-botton-alianza"
                dark
                :loading="loading"
                @click="getAlianzas"
              >
                <span>Buscar</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel>
      </v-expansion-panel>
    <!-- Sección de búsqueda de alianzas mobile-->
      <v-expansion-panel elevation="0" v-if="!$vuetify.breakpoint.mdAndUp">
      <v-expansion-panel style="padding: 35px">
        <v-col cols="12">
          <h3 style="color: #42352e;">Buscar alianza</h3>
        </v-col>
        <v-row
          no-gutters
          align="center"
          justify="space-between"
          style="flex-wrap: wrap;"
        >
          <v-col cols="12">
            <v-text-field
              v-model="search"
              label="Alianza"
              hint="Buscar una alianza por nombre"
              outlined
              dense
              color="primary"
              background-color="white"
              @keyup.enter="getAlianzas"
            />
          </v-col>
          <v-col cols="12">
            <v-btn
              class="common-botton-alianza"
              dark
              :loading="loading"
              block
              @click="getAlianzas"
            >
              <span>Buscar</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel>
    </v-expansion-panel>
    </v-expansion-panels>
    <div style="padding: 0px 40px 0px 40px">
      <hr />
    </div>
    <v-row>
      <v-col cols="12" class="mb-10" style="padding: 40px">
        <v-container></v-container>
        <div class="table-container">
          <v-data-table
            :headers="agentesTable"
            :items="alianzas"
            item-key="name"
            class="elevation-0"
            :loading="loading"
            loading-text="Cargando..."
            no-data-text="Para ver elementos, primero realiza una búsqueda"
          >
            <template v-slot:item.sexo="{ item }">
              <div v-if="item.sexo == 1">Masculino</div>
              <div v-if="item.sexo == 2">Femenino</div>
            </template>
            <template v-slot:item.nombre="{ item }">
              {{ item.nombre }} {{ item.apellidoPaterno }}
              {{ item.apellidoMaterno }}
            </template>
            <template #item.fechaCreacion="{ item }">
              <span>{{ moment(item.fechaCreacion).format("DD/MM/YYYY") }}</span>
            </template>
            <template v-slot:item.cat_status_id="{ item }">
              <div
                style="display: flex; justify-content: center; align-items: center; width: 100%"
              >
                <div v-if="item.cat_status_id == -1">
                  <v-chip color="red" dark>Baja</v-chip>
                </div>
                <div v-if="item.cat_status_id == 0">
                  <v-chip color="blue" dark>Nueva</v-chip>
                </div>
                <div v-if="item.cat_status_id == 1">
                  <v-chip color="#25A249" dark>Activa</v-chip>
                </div>
                <div v-if="item.cat_status_id == 2">
                  <v-chip color="#82898E" dark>Inactiva</v-chip>
                </div>
              </div>
            </template>

            <template v-slot:item.id="{ item }">
              {{ item.id }}
            </template>
            <template v-slot:item.actions="{ item }">
              <div
                style="display: flex; justify-content: center; align-items: center; width: 100%"
              >
                <v-btn icon x-small @click="goToAlianzaDashboard(item.id)">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
  </common-card>
</template>

<script>
import { mainAxios } from "../../../../mainAxios";
import moment from "moment";
import CommonCard from "@/components/commonComponents/CommonCard.vue";

export default {
  components: {
    CommonCard,
  },
  data() {
    return {
      moment,
      agentes: [],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      roles: JSON.parse(localStorage.getItem("roles")),
      loading: false,
      search: "",
      agentesTable: [
        { text: "ID", value: "id", width: "50px" },
        { text: "Alianza", value: "nameAlliance", width: "130" },
        { text: "Administrador", value: "nombre", width: "180" },
        { text: "Correo del admin de Alianza", value: "correo", width: "90px" },
        {
          text: "Fecha de Registro",
          value: "fechaCreacion",
          width: "150px",
          align: "center",
        },
        {
          text: "Estatus",
          value: "cat_status_id",
          align: "center",
          width: "100px",
        },
        {
          text: "Ver Alianza",
          value: "actions",
          sortable: false,
          align: "center",
          width: "100px",
        },
      ],
      alianzas: [],
    };
  },

  methods: {
    /**
     * Obtiene la lista de alianzas corporativas desde el servidor.
     * Si hay un término de búsqueda, filtra las alianzas por nombre.
     * Muestra un indicador de carga mientras se realiza la solicitud.
     * Al recibir la respuesta, almacena los datos de las alianzas y oculta el indicador de carga.
     * En caso de error, simplemente oculta el indicador de carga.
     */
    getAlianzas() {
      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      let url = `/v1/alianza/list`;
      if (this.search) {
        url += `?nameAlliance=${this.search}`;
      }

      mainAxios
        .get(url, config)
        .then((response) => {
          this.alianzas = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    goToAlianzaDashboard(item) {
      this.$router.push(`/alianza/perfil/${item}`);
    },
  },
  mounted() {
    this.getAlianzas();
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #f2fafc;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #f6f5f9;
  color: rgba(0, 0, 0, 0.87);
}

.v-chip.v-size--default {
  height: auto;
  width: 70px;
  display: flex;
  justify-content: center;
}

.table-container {
  margin-bottom: 0; /* Sin espacio adicional por defecto */
  overflow-x: auto; /* Habilitar scroll horizontal para dispositivos móviles */
}

@media (max-width: 768px) {
  .table-container {
    margin-bottom: 150px; /* Espacio adicional solo para pantallas pequeñas */
  }
}
/* Este estilo garantiza que el footer permanezca en la parte inferior de la pantalla sin superponerse al contenido. */
footer {
  position: sticky;
  bottom: 0;
  z-index: 10;
  background-color: #f6f5f9;
}
</style>
