<template>
  <div class="pt-5">
    <v-data-table
      :items="alianzas"
      :headers="headers"
      :loading="loading"
      no-data-text="Sin asesores para mostrar"
      no-results-text="Sin resultados para mostrar"
      loading-text="Cargando..."
      :footer-props="{
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Elementos por página',
      }"
    >
      <template v-slot:top>
        <v-row style="padding: 15px">
          <v-col cols="12" md="8">
            <h2>Resultados de la búsqueda</h2>
          </v-col>
          <v-col cols="12" md="4" style="text-align: end">
            <download-csv :data="columnsReport" name="polizas_tabla.csv">
              <v-btn color="transparent" elevation="0" small>
                <span style="color: #0070c0">Descargar</span>
                <v-icon small right class="mr-2" color="#0070C0"
                  >mdi-file-pdf-box</v-icon
                >
              </v-btn>
            </download-csv>
          </v-col>
        </v-row>
      </template>
      <template #item.id="{ item }">
        {{ item.id ? item.id : "Sin ID" }}
      </template>

      <template v-slot:item.status="{ item }">
        <div
          style="display: flex; justify-content: center; align-items: center; width: 100%"
        >
          <div v-if="item.status == -1">
            <v-chip color="red" dark>Baja</v-chip>
          </div>
          <div v-if="item.status == 0">
            <v-chip color="blue" dark>Nuevo</v-chip>
          </div>
          <div v-if="item.status == 1">
            <v-chip color="#25A249" dark>Activo</v-chip>
          </div>
          <div v-if="item.status == 2">
            <v-chip color="#82898E" dark>Inactivo</v-chip>
          </div>
        </div>
      </template>
      <template #item.nombre="{ item }">
        <span>{{
          item.nombreCompleto ? item.nombreCompleto : "Sin Nombre"
        }}</span>
      </template>
      <template #item.celular="{ item }">
        <span>{{ item.celular ? item.celular : item.telefono }}</span>
      </template>
      <template #item.correoElectronico="{ item }">
        <span>{{ item.correo ? item.correo : "Sin Correo" }}</span>
      </template>
      <template #item.fechaRegistro="{ item }">
        <span>{{
          item.fechaRegistro ? item.fechaRegistro.split(" ")[0] : "Sin Fecha"
        }}</span>
      </template>
      <template v-slot:item.uuid="{ item }">
        <div
          style="display: flex; justify-content: center; align-items: center; width: 100%"
        >
          <v-btn icon x-small @click="editItem(item.uuid)">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </div>
      </template>
      <!-- <template #item.ver="{ item }">
        <v-btn icon x-small @click="editItem(item.uuid)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template> -->

      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        <div v-if="itemsLength">
          Mostrando {{ pageStart }} - {{ pageStop }} de
          {{ itemsLength }} solicitudes
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    alianzas: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    //TODO: aplicar o no el sort
    return {
      headers: [
        {
          text: "ID",
          value: "id",
          width: "50px",
        },
        {
          text: "Estatus",
          value: "status",
          align: "center",
          width: "100px",
        },
        {
          text: "Nombre",
          value: "nombre",
          width: "200",
        },
        {
          text: "Celular",
          value: "celular",
          sortable: false,
          width: "120",
        },
        {
          text: "Correo Electrónico",
          value: "correoElectronico",
          sortable: false,
          width: "240",
        },
        {
          text: "Fecha de Registro",
          value: "fechaRegistro",
          width: "150px",
          align: "center",
        },
        {
          text: "Ver Agente",
          value: "uuid",
          width: "100px",
          sortable: false,
          align: "center",
        },
      ],
      columnsReport: [],
    };
  },

  methods: {
    editItem(item) {
      console.log(item);
      this.$router.push(`/usuario/ver-micuenta/${item}`);
    },
    formatReport() {
      console.log(this.alianzas);
      this.columnsReport = this.alianzas.map((e) => ({
        ID: e.id ?? "Sin llenar",

        Estatus:
          e.status == -1
            ? "Baja"
            : e.status == 0
            ? "Nueva"
            : e.status == 1
            ? "Activo"
            : e.status == 2
            ? "Inactivo"
            : "Sin estatus",
        Nombre: e.nombre ?? "Sin llenar",
        Apellidos: e.apellidoPaterno + " " + e.apellidoMaterno,
        Celular: e.celular || e.telefono || "Sin llenar",
        "Correo Electrónico": e.correo ?? "Sin llenar",
        "Fecha de Registro": e.fechaRegistro
          ? e.fechaRegistro.split(" ")[0]
          : "Sin llenar",
      }));
      console.log(this.columnsReport);
    },
  },

  watch: {
    alianzas: {
      handler() {
        this.formatReport();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #f2fafc;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}
.v-chip.v-size--default {
  height: auto;
  width: 70px;
  display: flex;
  justify-content: center;
}
</style>
