<template>
  <!-- Contenedor principal con padding de 12px arriba y abajo -->
  <v-container style="padding: 12px 0 12px 0;">
    <!-- Componente de panel de expansión común -->
    <CommonExpansionPanel>
      <!-- Título del panel de expansión -->
      <template v-slot:title-panel> Agregar asesor(es)</template>
      <!-- Contenido del panel de expansión -->
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="12">
            <!-- Grupo de botones de radio para seleccionar el tipo de carga -->
            <v-radio-group v-model="tipoCarga">
              <!-- Opción de carga manual -->
              <v-radio label="Carga manual" value="manual" class="radios"></v-radio>

              <!-- Formulario para la carga manual -->
              <v-form ref="form" v-model="valid">
                <!-- Fila del formulario, visible solo si tipoCarga es 'manual' -->
                <v-row class="form-card" v-if="tipoCarga === 'manual'">
                  <!-- Columna para el campo de nombre -->
                  <v-col cols="12" md="4" class="padding-col">
                    <!-- Campo de texto para el nombre del usuario -->
                    <v-text-field
                      name="name"
                      label="Nombre(s)"
                      :rules="generalRules"
                      v-model="usuario.nombre"
                      outlined
                      primary
                      color="#00a7e4"
                      dense
                    ></v-text-field>
                  </v-col>
                  <!-- Columna para el campo de apellido paterno -->
                  <v-col cols="12" md="4" class="padding-col">
                    <!-- Campo de texto para el apellido paterno del usuario -->
                    <v-text-field
                      name="apellidoPaterno"
                      label="Apellido Paterno"
                      :rules="generalRules"
                      v-model="usuario.apellidoPaterno"
                      outlined
                      primary
                      color="#00a7e4"
                      dense
                    ></v-text-field>
                  </v-col>
                  <!-- Columna para el campo de apellido materno -->
                  <v-col cols="12" md="4" class="padding-col">
                    <!-- Campo de texto para el apellido materno del usuario -->
                    <v-text-field
                      name="apellidoMaterno"
                      label="Apellido Materno"
                      v-model="usuario.apellidoMaterno"
                      outlined
                      primary
                      color="#00a7e4"
                      dense
                      :rules="generalRules"
                    ></v-text-field>
                  </v-col>
                  <!-- Columna para el campo de género -->
                  <v-col cols="12" md="4" class="padding-col">
                    <!-- Autocompletado para seleccionar el género del usuario -->
                    <v-autocomplete
                      v-model="usuario.sexo"
                      :items="sexoList"
                      item-text="sexo"
                      item-value="id"
                      label="Género"
                      required
                      :rules="generalRules"
                      outlined
                      primary
                      color="#00a7e4"
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <!-- Columna para el campo de fecha de nacimiento -->
                  <v-col cols="12" md="4" class="padding-col">
                    <!-- Campo de texto para la fecha de nacimiento del usuario -->
                    <v-text-field
                      v-model="usuario.fechaNacimiento"
                      label="Fecha de Nacimiento"
                      placeholder="dd/mm/aaaa"
                      outlined
                      primary
                      color="#00a7e4"
                      dense
                      type="date"
                      :rules="generalRules"
                    ></v-text-field>
                  </v-col>
                  <!-- Columna para el campo de estado de nacimiento -->
                  <v-col cols="12" md="4" class="padding-col">
                    <!-- Autocompletado para seleccionar el estado de nacimiento -->
                    <v-autocomplete
                      item-text="nombre"
                      item-value="id"
                      v-model="usuario.nacimientoEstadoId"
                      :items="estados"
                      :rules="generalRules"
                      label="Estado de nacimiento"
                      outlined
                      primary
                      color="#00a7e4"
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <!-- Columna para el campo de celular -->
                  <v-col cols="12" md="4" class="padding-col">
                    <!-- Campo de texto para el celular de contacto del usuario -->
                    <v-text-field
                      label="Celular de contacto"
                      placeholder="##########"
                      :rules="phoneRules"
                      v-mask="'##########'"
                      v-model="usuario.celular"
                      outlined
                      primary
                      color="#00a7e4"
                      dense
                    ></v-text-field>
                  </v-col>
                  <!-- Columna para el campo de RFC -->
                  <v-col cols="12" md="4" class="padding-col">
                    <!-- Campo de texto para el RFC del usuario -->
                    <v-text-field
                      id="rfc"
                      v-model="usuario.rfc"
                      label="RFC"
                      type="text"
                      required
                      placeholder="XAXX010101000"
                      :rules="rfcRules"
                      @input="onInputToUpper"
                      outlined
                      primary
                      color="#00a7e4"
                      dense
                    ></v-text-field>
                  </v-col>

                  <!-- Columna para los correos electrónicos del usuario -->
                  <v-col cols="12" md="4">
                    <!-- Itera sobre cada email del usuario -->
                    <v-row v-for="(element, index) in usuario.emails" :key="index">
                      <v-col class="padding-col" cols="12" v-if="showComponent">
                        <!-- Componente de autocompletado de correo electrónico -->
                        <CommonEmailAutocomplete
                          @emailUpdate="(e) => (element.correo = e)"
                          :isDense="true"
                        >
                        </CommonEmailAutocomplete>
                      </v-col>
                    </v-row>
                  </v-col>

                  <!-- Fila para el botón de cargar asesor -->
                  <v-row>
                    <v-col cols="12" style="text-align: end" class="pt-10 pr-0">
                      <!-- Botón para abrir el modal de carga de asesor -->
                      <v-btn
                        @click="abrirModal()"
                        class="common-botton asesor mr-4"
                        dark
                        rounded="xxl"
                        width="200"
                        :disabled="validador"
                      >
                        Cargar asesor
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-row>
              </v-form>

              <!-- Opción de carga masiva -->
              <v-radio label="Carga masiva" value="masiva" class="radios"></v-radio>
            </v-radio-group>
          </v-col>

          <!-- Template para dispositivos de escritorio -->
          <template v-if="tipoCarga === 'masiva' && $vuetify.breakpoint.mdAndUp">
            <v-row justify="center" align="center">
              <v-col cols="10">
                <!-- Texto de apoyo para la carga masiva -->
                <p style="margin: 0px 0px 0px 10px; font-size: 15px">
                  <strong>
                    Por favor apóyate del siguiente template para cargarlos una vez registrados en este archivo Excel
                  </strong>
                </p>
              </v-col>
              <v-col cols="2" align="start">
                <!-- Enlace para descargar el template de Excel -->
                <a
                  style="color: #039ecc; text-decoration: none; font-family: 'Montserrat'; font-weight: 700;"
                  href="https://storage.googleapis.com/bucketresourcescrmbradescard/Resorces%20CRM/Carga_Masiva_Asesores.xlsx"
                >
                  Descargar
                  <!-- Icono de descarga -->
                  <v-icon color="#039ECC">mdi-download</v-icon>
                </a>
              </v-col>
            </v-row>

            <!-- Columna que contiene el área de carga de archivos -->
            <v-col cols="12" v-if="tipoCarga === 'masiva'">
              <v-card
                class="pa-4"
                outlined
                @dragover.prevent="onDragOver"
                @dragleave.prevent="onDragLeave"
                @drop.prevent="onDrop"
                :class="{ 'drag-active': isDragging }"
              >
                <v-row align="center">
                  <v-col cols="6" class="d-flex align-center pl-6">
                    <!-- Imagen del icono de carga -->
                    <v-img class="icon-upload" :src="uploadicon"></v-img>
                    <div style="margin-left: 15px">
                      <!-- Texto instructivo para arrastrar y soltar archivos -->
                      <p class="text-drag">Arrástrelo y suéltelo aquí</p>
                      <!-- Formato permitido -->
                      <p class="format-drag">Formato permitido: .xls .xlsx</p>
                      <!-- Tamaño máximo permitido -->
                      <p class="format-drag">5 MB máximo</p>
                    </div>
                    <!-- Campo de entrada de archivo oculto con icono de añadir -->
                    <v-file-input
                      v-model="file"
                      color="#039ecc"
                      accept=".xlsx, .xls"
                      hide-input
                      prepend-icon="mdi-plus-circle"
                      outlined
                      class="file-input"
                      ></v-file-input>
                  </v-col>

                  <v-col cols="4" class="text-left">
                    <!-- Botón para cargar el archivo seleccionado -->
                    <v-btn
                      rounded
                      @click="uploadFile"
                      :disabled="!file"
                      class="primary btn-archivo"
                    >
                      Cargar archivo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </template>

          <!-- Template para dispositivos móviles -->
          <template v-else-if="tipoCarga === 'masiva' && $vuetify.breakpoint.smAndDown">
            <v-row justify="center" align="center">
              <v-col cols="12" md="10">
                <v-row>
                  <v-col cols="12" md="8" class="d-flex align-center text-center md:text-left">
                    <!-- Texto de apoyo para la carga masiva en dispositivos móviles -->
                    <p class="text-template">
                      <strong>
                        Por favor apóyate del siguiente template para cargarlos una vez registrados en este archivo
                        Excel
                      </strong>
                    </p>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex justify-center md:justify-end align-center">
                    <!-- Enlace para descargar el template de Excel en dispositivos móviles -->
                    <a
                      class="download-link"
                      href="https://storage.googleapis.com/bucketresourcescrmbradescard/Resorces%20CRM/Layout_definitivo.xlsx"
                    >
                      Descargar
                      <!-- Icono de descarga -->
                      <v-icon color="#039ECC">mdi-download</v-icon>
                    </a>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- Área de carga de archivos para dispositivos móviles -->
            <v-col cols="12">
              <v-card
                class="pa-4 d-flex flex-column justify-center align-center"
                outlined
                @dragover.prevent="onDragOver"
                @dragleave.prevent="onDragLeave"
                @drop.prevent="onDrop"
                :class="{ 'drag-active': isDragging }"
                style="min-height: 300px;"
              >
                <!-- Icono de carga -->
                <v-img class="icon-upload mb-3" :src="uploadicon" contain></v-img>
                <div class="d-flex flex-column text-center">
                  <!-- Texto instructivo para arrastrar y soltar archivos -->
                  <p class="text-drag mb-2" style="font-size: 1rem; line-height: 1.5;">
                    Arrástrelo y suéltelo aquí
                  </p>
                  <!-- Formato permitido -->
                  <p class="format-drag mb-2" style="font-size: 0.875rem; color: #555;">
                    Formato permitido: <strong>.xls</strong>, <strong>.xlsx</strong>
                  </p>
                  <!-- Tamaño máximo permitido -->
                  <p class="format-drag mb-2" style="font-size: 0.875rem; color: #555;">
                    Tamaño máximo: <strong>5 MB</strong>
                  </p>
                  <!-- Campo de entrada de archivo oculto con icono de añadir -->
                  <v-file-input
                    v-model="file"
                    color="#039ecc"
                    accept=".xlsx, .xls"
                    hide-input
                    prepend-icon="mdi-plus-circle"
                    outlined
                    style="max-width: 400px; text-align:center !important;"
                    class="file-input"
                  ></v-file-input>
                </div>
                <!-- Botón para cargar el archivo seleccionado -->
                <v-btn
                  rounded
                  @click="uploadFile"
                  :disabled="!file"
                  class="primary btn-archivo mt-4"
                  style="max-width: 300px; width: 100%;"
                >
                  Cargar archivo
                </v-btn>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-expansion-panel-content>
    </CommonExpansionPanel>

    <!-- Modales para registro de asesor y carga masiva -->
    <ModalRegistroAsesor
      :dialog.sync="dialog"
      :asesor="usuario"
      :errorMessage="errorMessage"
      @confirmarRegistro="setNewUser"
      @setearValores="setearValores"
      @cambiarStep="cambiarStep"
      :step="stepIndividual"
    />
    <ModalCargaMasiva
      :dialog.sync="dialogCsv"
      :reporte="reporteCsv"
      @descargarExcel="downloadExcelFromCSV"
      :step="step"
      :progress="progress"
      @returnStep="returnStep"
    />
  </v-container>
</template>

<script>
import * as XLSX from "xlsx"; // Importa la librería XLSX para manejar archivos Excel
import uploadicon from "../../../../assets/uploadDocumentIcon.svg"; // Importa el ícono de carga
import moment from "moment"; // Importa la librería moment para manejar fechas
import RfcFacil from "rfc-facil"; // Importa la librería RfcFacil para generar RFCs
import { mainAxios } from "../../../../mainAxios"; // Importa la instancia de Axios para hacer peticiones HTTP
import CommonEmailAutocomplete from "../../../../components/commonComponents/CommonEmailAutocomplete.vue"; // Importa el componente de autocompletado de correo electrónico
import ModalRegistroAsesor from "./ModalRegistroAsesor.vue"; // Importa el modal de registro de asesor
import ModalCargaMasiva from "./ModalCargaMasiva.vue"; // Importa el modal de carga masiva
import SockJS from "sockjs-client"; // Importa la librería SockJS para manejar WebSockets
import Stomp from "stompjs"; // Importa la librería Stomp para manejar WebSockets
import CommonExpansionPanel from "../../../../components/commonComponents/CommonExpansionPanel.vue"; // Importa el componente de panel de expansión común

export default {
  props: {
    idAliance: Number, // Propiedad para el ID de la alianza
  },
  components: {
    CommonEmailAutocomplete, // Componente de autocompletado de correo electrónico
    ModalRegistroAsesor, // Modal para registro de asesor
    ModalCargaMasiva, // Modal para carga masiva
    CommonExpansionPanel, // Panel de expansión común
  },
  data() {
    return {
      showComponent: true, // Controla la visibilidad de componentes
      valid: false, // Indica si el formulario es válido
      errorMessage: "", // Mensaje de error
      file: null, // Archivo seleccionado para carga
      isDragging: false, // Indica si se está arrastrando un archivo sobre el área de carga
      uploadicon, // Icono de carga
      tipoCarga: "", // Tipo de carga seleccionada ('manual' o 'masiva')
      reporteCsv: {
        ejecutados: 0,
        correctos: 0,
        duplicados: 0,
        rechazados: 0,
      }, // Reporte de carga masiva
      usuario: {
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        rfc: "",
        sexo: "",
        fechaNacimiento: "",
        nacimientoEstadoId: "",
        celular: "",
        correo: "",
        emails: [
          {
            correo: "",
            favorito: true,
          },
        ],
        status: 0,
        ramo: 0,
        canal: 0,
      }, // Datos del usuario/asesor
      sexoList: [
        { id: 1, sexo: "Masculino" },
        { id: 2, sexo: "Femenino" },
      ], // Lista de opciones para género
      generalRules: [(v) => !!v || "Campo es necesario"], // Reglas de validación generales
      emailRules: [
        (v) => !!v || "E-mail es necesario",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail no es valido",
      ], // Reglas de validación para correos electrónicos
      phoneRules: [
        (v) => !!v || "Campo es necesario",
        (v) => /^\d{10}$/.test(v) || "Mínimo 10 digitos",
        (v) =>
          !/^(.)\1{9}$/.test(v) ||
          "No se permiten secuencias de dígitos repetidos",
        (v) =>
          !["1111111111", "9999999999", "0000000000"].includes(v) ||
          "El número de teléfono no es válido",
      ], // Reglas de validación para teléfonos
      rfcRules: [
        (v) =>
          v.length === 10 ||
          v.length === 12 ||
          v.length === 13 ||
          "RFC debe tener 10, 12 o 13 caracteres",
        (v) => !!v || "Campo Requerido",
        (v) =>
          /^[A-Za-z\d]{10,13}$/.test(v) ||
          "RFC no es válido, asegúrate de tener la longitud correcta y utiliza caracteres alfanuméricos",
      ], // Reglas de validación para RFC
      estados: 0, // Lista de estados
      canalList: [], // Lista de canales
      ramoList: [], // Lista de ramos
      campaniaList: [], // Lista de campañas
      archivo: null, // Archivo para carga
      dialog: false, // Controla la visibilidad del modal de registro de asesor
      dialogCsv: false, // Controla la visibilidad del modal de carga masiva
      step: 0, // Paso actual en la carga masiva
      stepIndividual: 1, // Paso actual en el registro individual
      csvData: "", // Datos CSV cargados
      clientId: Math.random()
        .toString(36)
        .substring(2), // ID único del cliente para WebSockets
      stompClient: null, // Cliente STOMP para WebSockets
      progress: 0, // Progreso de la carga masiva
    };
  },
  methods: {
    // Reinicia el componente para forzar la actualización
    resetComponent() {
      this.showComponent = false;
      this.$nextTick(() => {
        this.showComponent = true;
      });
    },
    // Conecta con el WebSocket usando SockJS y Stomp
    connectSocket() {
      let socket = new SockJS(
        `${process.env.VUE_APP_API_URL}/ws`
        // `http://localhost:8091/ws` // URL alternativa para desarrollo local
      );
      this.stompClient = Stomp.over(socket);

      this.stompClient.connect({}, (frame) => {
        console.log("Conectado: " + frame);

        // Suscribirse al canal del progreso usando el clientId
        this.stompClient.subscribe(
          `/topic/progress/${this.clientId}`,
          (progressUpdate) => {
            const progress = parseInt(progressUpdate.body, 10);
            this.progress = progress;
            console.log("Progreso: " + progress);
          }
        );
      });
    },
    // Cambia el paso individual al paso 1
    cambiarStep() {
      this.stepIndividual = 1;
    },
    // Método para subir el archivo seleccionado
    async uploadFile() {
      this.abrirModalCsv(); // Abre el modal de carga masiva
      try {
        // Verifica que el tamaño del archivo no exceda 5 MB
        if (this.file.size > 5 * 1024 * 1024) {
          console.error(
            "El archivo excede el tamaño máximo permitido de 5 MB."
          );
          return;
        }
        // Crea un FormData y agrega el archivo y otros datos
        const formData = new FormData();
        formData.append("asesores", this.file);
        formData.append("clientId", this.clientId);
        formData.append("notificationEmail", this.sendEmail ? 1 : 0);

        // Configuración de los encabezados para la petición HTTP
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer" + localStorage.agenteAccessToken,
          },
        };
        // Envía el archivo al servidor mediante una petición POST
        const response = await mainAxios.post(
          `/v1/alianza/upload-asesores/${this.idAliance}`,
          // `/v1/alianza/upload-asesores/18`, // URL alternativa para pruebas
          formData,
          config
        );
        this.csvData = response.data;

        // Verifica si la respuesta es una cadena (CSV)
        if (typeof response.data === "string") {
          const data = this.csvToJson(this.csvData);

          if (data.length) {
            const lastId = data.length;
            let correctCount = 0;
            let duplicateCount = 0;
            let otherCount = 0;
            // Cuenta los resultados de la carga
            data.forEach((item) => {
              const resultado = item["Resultado"].trim(); // TODO corregir el nombre de la columna

              if (resultado.includes("Correcto")) {
                correctCount++;
              } else if (resultado.includes("Duplicado")) {
                duplicateCount++;
              } else {
                otherCount++;
              }
            });
            // Actualiza el reporte de carga
            this.reporteCsv = {
              ejecutados: lastId,
              correctos: correctCount,
              duplicados: duplicateCount,
              rechazados: otherCount,
            };
            console.log("Reporte CSV:", this.reporteCsv);
            this.showDetailsCSV(); // Muestra los detalles del CSV
          }
        } else {
          console.error("La respuesta no está en formato CSV.");
        }
        console.log("Archivo subido correctamente:", response.data);
      } catch (error) {
        console.error("Error al subir el archivo:", error);
      }
    },

    // Descarga el reporte de carga masiva en formato Excel
    downloadExcelFromCSV() {
      const data = this.csvToJson(this.csvData);

      const worksheet = XLSX.utils.json_to_sheet(data);

      const workbook = {
        Sheets: { Alianzas: worksheet },
        SheetNames: ["Alianzas"],
      };

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const blob = new Blob([excelBuffer], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Resultado de carga de Asesores.xlsx";
      link.click();
    },

    // Retorna al paso inicial y reinicia variables relacionadas con la carga masiva
    returnStep() {
      this.connectSocket();
      this.step = 0;
      this.file = null;
    },
    // Convierte un CSV a JSON
    csvToJson(csv) {
      const lines = csv.split("\n");
      const result = [];
      const headers = lines[0].split(",");

      for (let i = 1; i < lines.length; i++) {
        const currentLine = lines[i].trim();
        if (!currentLine) {
          continue;
        }

        const obj = {};
        const currentLineValues = currentLine.split(",");

        for (let j = 0; j < headers.length; j++) {
          obj[headers[j].trim()] = currentLineValues[j]
            ? currentLineValues[j].trim()
            : "";
        }

        result.push(obj);
      }

      return result;
    },

    // Restablece los valores del formulario y estados relacionados
    setearValores() {
      this.usuario = {
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        rfc: "",
        sexo: "",
        fechaNacimiento: "",
        nacimientoEstadoId: null,
        celular: "",
        correo: "",
        emails: [
          {
            correo: "",
            favorito: true,
          },
        ],
        status: 0,
        ramo: 0,
        canal: 0,
      };
      this.$refs.form.resetValidation(); // Reinicia la validación del formulario
      this.stepIndividual = 1; // Reinicia el paso individual
      this.dialog = false; // Cierra el modal de registro de asesor
      this.dialogCsv = false; // Cierra el modal de carga masiva
      this.sendEmail = false; // Reinicia la opción de envío de email
      this.resetComponent(); // Reinicia el componente
    },

    // Abre o cierra el modal de registro de asesor
    abrirModal() {
      this.dialog = !this.dialog;
    },
    // Abre o cierra el modal de carga masiva
    abrirModalCsv() {
      this.dialogCsv = !this.dialogCsv;
    },
    // Maneja el evento de arrastrar sobre el área de carga
    onDragOver() {
      this.isDragging = true; // Detecta que el archivo está sobre el área de carga
    },
    // Maneja el evento de salir del área de carga
    onDragLeave() {
      this.isDragging = false; // Restablece el estado cuando se sale del área
    },
    // Maneja el evento de soltar un archivo en el área de carga
    onDrop(event) {
      this.isDragging = false;
      const droppedFiles = event.dataTransfer.files;

      // Verifica que el archivo sea de tipo .xlsx o .xls
      if (
        droppedFiles.length &&
        (droppedFiles[0].type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          droppedFiles[0].type === "application/vnd.ms-excel")
      ) {
        this.file = droppedFiles[0]; // Asigna el archivo seleccionado
      } else {
        alert("Solo se permiten archivos en formato .xlsx o .xls");
      }
    },
    // Actualiza el RFC automáticamente basado en los datos ingresados
    actualizarRfc() {
      if (
        !this.usuario.nombre ||
        !this.usuario.apellidoPaterno ||
        !this.usuario.apellidoMaterno ||
        !this.usuario.fechaNacimiento
      )
        return;
      const arregloNacimiento = moment(this.usuario.fechaNacimiento)
        .format("DD/MM/YYYY")
        .split("/")
        .map((e) => Number(e));
      if (arregloNacimiento.length < 3) return;
      const rfc = RfcFacil.forNaturalPerson({
        name: this.usuario.nombre,
        firstLastName: this.usuario.apellidoPaterno,
        secondLastName: this.usuario.apellidoMaterno,
        day: arregloNacimiento[0],
        month: arregloNacimiento[1],
        year: arregloNacimiento[2],
      });
      this.usuario.rfc = rfc;
      this.usuario.rfcRepresentanteLegal = rfc;
      this.usuario.password = this.usuario.rfc;
    },
    // Obtiene las listas de ramos, canales, campañas y estados desde la API
    listas() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      // Solicita la lista de alianzas
      mainAxios.get(`/v1/catalogo/alliance`, config).then((response) => {
        // Corrige etiquetas específicas si es necesario
        function corregirLabels(branchs) {
          return branchs.map((branch) => {
            if (branch.label === "Danios") {
              branch.label = "Daños";
            }
            return branch;
          });
        }
        corregirLabels(response.data.branchs);
        // Asigna las listas a las variables del componente
        this.ramoList = response.data.branchs;
        this.canalList = response.data.channels;
      });
      // Solicita la lista de campañas
      mainAxios.get(`/v1/campania/list`, config).then((response) => {
        this.campaniaList = response.data;
      });

      // Solicita la lista de landing pages
      mainAxios.get(`/v1/agente/landing/list`, config).then(({ data }) => {
        this.landings = data;
      });
      // Solicita la lista de estados
      mainAxios.get(`/v1/catalogo/estado/nombres`, config).then(({ data }) => {
        this.estados = data;
      });
    },
    // Convierte el input del RFC a mayúsculas
    onInputToUpper() {
      this.usuario.rfc = this.usuario.rfc.toUpperCase();
    },
    // Guarda un nuevo usuario/asesor
    setNewUser() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          ContentType: "application/json",
        },
      };
      // Datos del usuario a enviar
      let dataUser = {
        alianzaId: this.idAliance,
        correo: this.usuario.emails[0].correo,
        nombre: this.usuario.nombre,
        apellidoPaterno: this.usuario.apellidoPaterno,
        apellidoMaterno: this.usuario.apellidoMaterno,
        fechaNacimiento: this.usuario.fechaNacimiento,
        celular: this.usuario.celular,
        rfc: this.usuario.rfc,
        nacimientoEstadoId: this.usuario.nacimientoEstadoId,
        sexo: this.usuario.sexo,
        notificationEmail: this.sendEmail ? 1 : 0,
      };

      // Envía los datos del usuario al servidor
      mainAxios
        .post(`/v1/agente/save-alliance`, dataUser, config)
        .then((response) => {
          console.log(response);
          if (response.data.result == "Correcto") {
            this.stepIndividual++;
            this.$emit("reloadTable");
            this.$emit("updateCountAgente"); // Emitir evento para actualizar el contador de asesores
          } else {
            this.errorMessage = response.data.result; // Asigna el mensaje de error
            console.log(this.errorMessage);
            this.stepIndividual = 3; // Cambia al paso de error
          }
        });
    },
    // Muestra los detalles del reporte CSV una vez completado
    showDetailsCSV() {
      setTimeout(() => {
        if (this.progress >= 100) {
          this.step = 1; // Avanza al siguiente paso si el progreso es 100%
        }
      }, 1000);
    },
  },
  mounted() {
    this.listas(); // Carga las listas necesarias al montar el componente
    this.connectSocket(); // Conecta con el WebSocket
    // Verifica la ruta actual para determinar si se debe enviar un email
    if (
      String(window.location.pathname).includes("/alianza/perfil/") ||
      String(window.location.pathname).includes("/perfil/alianza")
    ) {
      this.sendEmail = true;
    }
  },

  watch: {
    // Observa cambios en la fecha de nacimiento y actualiza el RFC
    "usuario.fechaNacimiento"() {
      this.actualizarRfc();
    },
    // Observa cambios en el nombre y actualiza el RFC
    "usuario.nombre"() {
      this.actualizarRfc();
    },
    // Observa cambios en el apellido paterno y actualiza el RFC
    "usuario.apellidoPaterno"() {
      this.actualizarRfc();
    },
    // Observa cambios en el apellido materno y actualiza el RFC
    "usuario.apellidoMaterno"() {
      this.actualizarRfc();
    },
  },

  computed: {
    // Computed para validar si el formulario está completo y es válido
    validador() {
      const {
        nombre,
        apellidoPaterno,
        apellidoMaterno,
        emails,
        nacimientoEstadoId,
        rfc,
        celular,
        sexo,
        fechaNacimiento,
      } = this.usuario;

      // Define las condiciones de validación
      const validaciones = [
        nombre && nombre.length > 0,
        apellidoPaterno && apellidoPaterno.length > 0,
        apellidoMaterno && apellidoMaterno.length > 0,
        emails && emails[0] && emails[0].correo && emails[0].correo.length > 0,
        nacimientoEstadoId && nacimientoEstadoId > 0,
        rfc && rfc.length > 0,
        celular && celular.length > 9,
        sexo && sexo > 0,
        fechaNacimiento && fechaNacimiento.length > 0,
      ];

      // Verifica que todas las validaciones sean verdaderas
      const esValido = validaciones.every((validacion) => validacion);

      // Retorna falso si es válido, verdadero si no lo es (para deshabilitar el botón)
      return esValido ? false : true;
    },
  },
};
</script>

<style scoped>

@media (max-width: 600px) {
  /* Clase personalizada para centrar el input de archivo (v-file-input) */
  .file-input {
    display: flex !important; /* Configura el contenedor como un contenedor flex */
    justify-content: center !important; /* Centra horizontalmente el contenido */
    align-items: center !important; /* Centra verticalmente el contenido */
    text-align: center !important; /* Asegura que el texto esté alineado al centro */
  }

  /* Estilo aplicado al ícono de "prepend" dentro del v-input (ícono a la izquierda del input) */
  .v-input__prepend {
    display: flex !important; /* Configura el contenedor del ícono como flex */
    justify-content: center !important; /* Centra horizontalmente el ícono */
    align-items: center !important; /* Centra verticalmente el ícono */
    text-align: center !important; /* Asegura que el texto o ícono esté alineado al centro */
  }
}

/* Estilos para las tarjetas con borde punteado y fondo */
.v-card {
  border: 1px dashed #ccc;
  border-radius: 8px;
  background-color: #f6f5f9;
  transition: background-color 0.2s ease;
}

/* Centra el contenido del input de archivo */
.file-input {
  margin: 0 auto;
  /* Centra el contenedor horizontalmente */
  text-align: center;
}

/* Estilos para la tarjeta del formulario */
.form-card {
  border: none;
  background-color: #f7f8fac9;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
}

/* Cambia el fondo cuando se está arrastrando un archivo */
.drag-active {
  background-color: #e3f2fd;
}

/* Estilos específicos para el botón de asesor */
.asesor {
  background-color: #00a5df !important;
  color: white !important;
}

/* Estilos para el botón de archivo */
.btn-archivo {
  background-color: #7d8b99 !important;
  color: white !important;
}

/* Estilos para el texto de formato permitido */
.format-drag {
  font-family: "Montserrat";
  color: #039ecc;
  margin: 0;
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
}

/* Estilos para el texto de arrastrar y soltar */
.text-drag {
  margin-bottom: 10px;
  color: #4d5358;
  font-size: 15px;
  font-weight: 500;
}

/* Estilos para el icono de carga */
.icon-upload {
  max-width: 53.77px;
  height: 48px;
}

/* Media queries para pantallas con ancho máximo de 600px */
@media (max-width: 600px) {
  /* Ajusta el padding de las tarjetas */
  .v-card {
    padding: 10px;
  }

  .form-card {
    padding: 10px;
  }

  /* Ajusta el tamaño y alineación del texto */
  .text-drag,
  .format-drag {
    font-size: 12px;
    text-align: center !important;
  }

  /* Ajusta el tamaño del icono de carga */
  .icon-upload {
    max-width: 50px;
    height: 35px;
  }

  /* Ajusta el ancho y margen del botón de archivo */
  .btn-archivo {
    width: 100%;
    margin-top: 10px;
  }

  /* Centra los elementos flexibles */
  .d-flex {
    justify-content: center !important;
    align-items: center !important;
  }
}
</style>
