<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Modelo"
          placeholder="Requerido"
          v-model="polizaData.modelo"
          :items="modelos"
          item-value="id"
          item-text="id"
          auto-select-first
          @change="getVersions()"
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Marca"
          placeholder="Requerido"
          v-model="polizaData.marca"
          :items="marcas"
          :item-text="(item) => item"
          :item-value="(item) => item"
          no-data-text="Primero selecciona un modelo"
          auto-select-first
          @change="getVersions()"
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Version"
          placeholder="Requerido"
          v-model="polizaData.version"
          :items="versiones"
          item-text="description"
          item-value="description"
          no-data-text="Primero selecciona una marca y modelo"
          auto-select-first
          outlined
          color="#00a7e4"
          :rules="inputsRequeridos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Plan"
          v-model="polizaData.plan_id"
          :items="planList"
          item-text="name"
          item-value="id"
          :rules="inputsRequeridos"
          outlined
          color="#00a7e4"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Origen del Auto"
          v-model="polizaData.origen_auto"
          :items="origenAutoList"
          :rules="inputsRequeridos"
          outlined
          color="#00a7e4"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-autocomplete
          label="Uso del Auto"
          v-model="polizaData.uso_auto"
          :items="usoAutoList"
          :rules="inputsRequeridos"
          outlined
          color="#00a7e4"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="No de Motor"
          v-model="polizaData.num_motor"
          :rules="inputsRequeridos"
          outlined
          color="#00a7e4"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="No. De Serie"
          v-model="polizaData.num_serie"
          :maxLength="17"
          :rules="[
            ...inputsRequeridos,
            localRules.alphanumeric,
            $rules.min(15),
            $rules.max(17),
          ]"
          outlined
          color="#00a7e4"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="3" xl="3">
        <v-text-field
          label="Placas"
          v-model="polizaData.placas"
          :maxLength="10"
          :rules="[...inputsRequeridos, localRules.alphanumeric]"
          outlined
          color="#00a7e4"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="12" lg="12" xl="12">
        <v-textarea
          label="Detalle de la Póliza"
          v-model="polizaData.detalle_auto"
          outlined
          color="#00a7e4"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          outlined
          color="#00a7e4"
          @click="$emit('beforeStep')"
          style="margin-right: 11px;"
        >
          <span>
            Anterior
          </span>
        </v-btn>
        <v-btn rounded dark class="common-botton" @click="$emit('goToSave',polizaData)">
          <span>
            Guardar
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <CommondLoading
      :show="loading"
      :loadingImage="dataLoading.iconCar"
      loadingText="Cargando catalogo de autos..."
    />
  </div>
</template>

<script>
import { mainAxios } from "@/mainAxios.js";
import { getBranches, getVersions } from "@/services/autos/autos.service.js";
import CommondLoading from "@/components/commonComponents/CommondLoading.vue";
import dataLoading from "@/helpers/data/data-loading-gif.json";

export default {
  data() {
    return {
      polizaData: {
        modelo: null,
        marca: null,
        submarca: null,
        version: null,
        detalle_auto: null,
        plan_id: null,
        origen_auto: null,
        uso_auto: null,
        num_motor: null,
        num_serie: null,
        placas: null,
        interPlan:true
      },

      modelos: this.generateLast60Years(),
      marcas: [],
      submarcas: [],
      versiones: [],

      localRules: {
        alphanumeric: (v) =>
          !v ||
          !!v.match(/^[0-9a-zA-Z]+$/) ||
          "Este campo solo acepta letras y números",
      },

      //listas
      planList: [
        { id: 1, name: "Amplia" },
        { id: 14, name: "Amplia-Plus" },
        { id: 2, name: "Limitada" },
        { id: 3, name: "RC" },
      ],
      origenAutoList: [
        "Fronterizo",
        "Importado",
        "Legalizado",
        "Nacional",
        "Salvamento",
      ],
      usoAutoList: [
        "Particular",
        "Comercial",
        "Uso con App",
        "Servicio público federal",
        "Transporte público",
        "Taxi",
      ],

      axiosConfig: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
      //Validadores
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
      dataLoading:dataLoading,
      loading:false
    };
  },

  computed: {
    marcaId() {
      if (!this.polizaData.marca) return null;
      return this.marcas.find((m) => m.marca == this.polizaData.marca).id;
    },
  },

  props: ["avisar"],

  components:{
    CommondLoading
  },

  mounted() {
    this.getToken()
  },

  methods: {
    generateLast60Years() {
      const currentYear = new Date().getFullYear();
      console.log({currentYear})
      let years = [];
      for (let i = 0; i <= 60; i++) {
        years.push(currentYear - i);
      }
      return years;
    },
    getToken() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.loading = true;
      return mainAxios.get("/v1/cotizacion-ws/auto/init", config).then(
        ({
          data: {
            data: { accessToken },
          },
        }) => {
          localStorage.setItem('interTokenCoche', accessToken)
          this.loading = false
          this.getBranches()
        }
      );
    },
    async getBranches(){
      this.marcas = await getBranches()
    },
    async getVersions(){
      this.polizaData.version = null
      this.loading = true 
      this.versiones = await getVersions(this.polizaData.marca, this.polizaData.modelo)
      this.loading = false 
    }
  },
};
</script>
