<template>
  <div style="margin-top:24px;">
    <CommonExpansionPanel>
      <template v-slot:title-panel>Información</template>
      <v-expansion-panel-content>
        <view-data-alianza v-if="switch_data == 1" :personalData="alianzaData">
        </view-data-alianza>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
    <!-- Se eliminó componente edit y se modificó componente view para ver y editar dentro del mismo componente -->
  </div>
</template>
<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import ViewDataAlianza from "./ViewDataAlianza.vue";

export default {
  components: {
    CommonExpansionPanel,
    ViewDataAlianza,
  },
  props: {
    alianzaData: Object,
  },
  data() {
    return {
      switch_data: 1,
    };
  },
};
</script>
