<template>
  <div>
    <v-expansion-panels elevation="0">
      <v-expansion-panel elevation="0">
        <v-expansion-panel-header style="padding: 35px">
          <h3 style="color: #42352e">Búsqueda detallada</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-row dense>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.cotizacion_general"
                  label="No. Cotizacion general"
                  outlined
                  dense
                  :light="true"
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-menu v-model="menuFecha" :close-on-content-click="false">
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="datesSelectedString"
                      label="Rango de cotizacion"
                      outlined
                      dense
                      v-on="on"
                      v-bind="attrs"
                      readonly
                      prepend-inner-icon="mdi-calendar"
                      :light="true"
                      background-color="white"
                      class="share-bar-clase"
                    ></v-text-field>
                  </template>
                  <v-card>
                    <v-date-picker
                      v-model="form.rangoFecha"
                      range
                      locale="es"
                      selected-items-text="2 seleccionadas"
                    ></v-date-picker>
                    <v-card-actions>
                      <v-btn small text @click="form.rangoFecha = []"
                        >Limpiar</v-btn
                      >
                      <v-spacer></v-spacer>
                      <v-btn
                        small
                        text
                        color="primary"
                        @click="menuFecha = false"
                        >Listo</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.nombre"
                  label="Nombre"
                  outlined
                  dense
                  :light="true"
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.rfc"
                  label="RFC"
                  outlined
                  dense
                  :rules="[$rules.rfc]"
                  :light="true"
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col> -->
              <v-col cols="12" md="4">
              <v-text-field
                v-model="form.telefono"
                label="Teléfono"
                outlined
                dense
                prepend-inner-icon="mdi-phone"
                maxLength="10"
                :rules="[$rules.numeric, localRules.longDigit(10)]"
                :light="true"
                background-color="white"
                class="share-bar-clase"
              ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.email"
                  label="Correo electrónico"
                  outlined
                  dense
                  prepend-inner-icon="mdi-email"
                  :rules="[$rules.email]"
                  :light="true"
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="pt-5">
      <v-row>

        <v-col cols="6" md="3">
          <v-select
            v-model="form.tipo_producto"
            label="Tipo producto"
            :items="tipos"
            outlined
            dense
            append-icon="mdi-chevron-down"
            class="selector-style"
          ></v-select>
        </v-col>

        <v-col cols="6" md="2" v-if="rol != 'AGENT'">
          <v-select
            :value="getRolId"
            @input="updateRolId"
            :items="this.rol === 'AGENTCORP' || this.rol === 'ADMINAGENT' && this.alianzaIdFromRoute ? asesores : operadores"
            :label="this.rol === 'AGENTCORP' || this.rol === 'ADMINAGENT' && this.alianzaIdFromRoute ? 'Asesor' : 'Operador'"
            item-text="nombreCompleto"
            item-value="id"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-spacer></v-spacer> <!-- Añade un espacio flexible para separar los elementos -->
        <v-col cols="12" md="4" class="d-flex justify-end"> <!-- Columna de 12 espacios en pantallas pequeñas y 4 en medianas, alinea su contenido a la derecha -->
          <div class="d-flex button-container"> <!-- Clase para ajustar el margen en pantallas responsive -->
            <v-btn
              dark
              outlined
              color="#00a7e4"
              rounded
              class="mr-2"
              @click="clearForm()"
            >
              Limpiar
            </v-btn>
           <!-- Clase personalizada para estilos adicionales del botón -->
            <!-- Aplica un estilo oscuro al botón -->
            <!-- Desactiva el botón si el formulario no es válido o si ningún campo está lleno -->
            <!-- Muestra un indicador de carga mientras se ejecuta la acción -->
            <!-- Llama al método updateSearch al hacer clic -->
            <v-btn
              class="common-botton"
              dark
              :disabled="!isFormValid || !minimoUnCampoLleno"
              :loading="loading"
              @click="updateSearch"
            >
              Filtrar
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mainAxios } from "@/mainAxios.js";
import moment from "moment";

export default {
  props: {
    currentFilters: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        cotizacion_general: "",
        status: "",
        agente_id: "",
        operador_id: "",
        ramo: "",
        rangoFecha: "",
        tipo: "",
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        rfc: "",
        telefono: "",
        email: "",
      },
      menuFecha: false,

      solicitudesStatus: [
        {
          id: 0,
          title: "Nuevas",
        },
        {
          id: 1,
          title: "Asignada",
        },
        {
          id: 2,
          title: "En tramite",
        },
        {
          id: 3,
          title: "Pendiente de Aprobación",
        },
        {
          id: 4,
          title: "Pendiente de Información",
        },
        {
          id: 5,
          title: "Recotizar",
        },
        {
          id: 6,
          title: "Cerradas", //no esta api
        },
        {
          id: 7,
          title: "Canceladas",
        },
      ],
      agentes: [],
      operadores: [],
      asesores: [],
      ramos: [],
      tipos: ["Coche", "Medico", "Mascota"],

      isFormValid: true,
      localRules: {
        longDigit(number) {
          return (v) =>
            !v ||
            v.length == number ||
            "Debe proporcionar " + number + " digitos";
        },
      },
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      alianzaIdFromRoute: null,
    };
  },

  computed: {
    datesSelectedString() {
      if (this.form.rangoFecha.length > 1)
        return `${this.formatDate(this.form.rangoFecha[0])} a ${this.formatDate(
          this.form.rangoFecha[1]
        )}`;
      return "";
    },

    minimoUnCampoLleno() {
      return (
        Object.keys(this.form).filter(
          (e) => !!this.form[e] || this.form[e] === 0
        ).length >= 1
      );
    },
    getRolId() {
      return this.rol === "AGENTCORP" || this.rol === "ADMINAGENT"
        ? this.form.agente_id
        : this.form.operador_id;
    },
  },

  watch: {
    currentFilters(v) {
      !!v && this.setCurrent();
    },
  },

  mounted() {
    this.alianzaIdFromRoute = this.$route.params.alianza_id;
    this.getOperadores();
    this.getAgentes();
    this.getRamos();
    this.setCurrent();
    this.getListAsesores();
  },

  methods: {
    getOperadores() {
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/agentes/operador", requestConfig).then(({ data }) => {
        this.operadores = data.map((e) => ({
          correo: e.correo,
          nombreCompleto: e.nombreCompleto,
          id: e.id,
          uuid: e.uuid,
        }));
      });
    },

    getAgentes() {
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/agente/list", requestConfig).then(({ data }) => {
        this.agentes = data.map((e) => ({
          correo: e.correo,
          nombreCompleto: e.nombreCompleto,
          id: e.id,
          uuid: e.uuid,
        }));
      });
    },
    async getListAsesores() {
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .post(
          `/v1/agente/list-filtro/${this.idParamOrStorage()}`,
          {
            filtroBusqueda: "",
          },
          requestConfig
        )
        .then(({ data }) => {
          this.asesores = data.map((e) => ({
            correo: e.correo,
            nombreCompleto: e.nombreCompleto,
            id: e.id,
            uuid: e.uuid,
          }));
          console.log(this.asesores);
        });
    },
    getRamos() {
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/ramo/list", requestConfig).then(({ data }) => {
        this.ramos = data;
      });
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    updateSearch() {
      if (!this.isFormValid || !this.minimoUnCampoLleno) return;
      let payload = {};
      Object.keys(this.form).forEach((e) => {
        if (!!this.form[e] || this.form[e] === 0) payload[e] = this.form[e];
      });
      payload.grouped = true;
      this.$emit("searchUpdated", payload);
    },

    setCurrent() {
      this.clearForm();
      this.form = { ...this.form, ...this.currentFilters };
    },

    clearForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = "";
      });
    },
    updateRolId(value) {
      if (this.rol === "AGENTCORP" || this.rol === "ADMINAGENT") {
        this.form.agente_id = value;
      } else {
        this.form.operador_id = value;
      }
    },
    idParamOrStorage() {
      return this.alianzaIdFromRoute || localStorage.alianzaId;
    },
  },
};
</script>
<style scoped>
.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(
    .v-expansion-panels--tile
  )
  > .v-expansion-panel--active {
  border-radius: 20px !important;
  background-color: #f7f8fa;
}
.v-expansion-panel-header {
  background-color: #f7f8fa;
  border-radius: 20px !important;
}
@media (max-width: 600px) {
  .button-container {
    margin-bottom: 15px; /* Ajusta el espacio */
  }
}
</style>
