<template>
  <div>
    <v-row>
      <v-col cols="10" md="10">
        <span class="titulo-header">Solicitudes</span>
      </v-col>
      <v-col md="2">
        <v-btn
          class="common-botton"
          dark
          block
          @click="$router.push('/solicitud-crear/10')"
          v-if="canCreateSolicitudes"
        >
          <v-icon start> mdi-plus-circle </v-icon>
          Agregar
        </v-btn>
      </v-col>
    </v-row>
    <div style="padding-top: 30px;" v-if="globalConfig.showMainHubGraphs">
      <stats-solicitud></stats-solicitud>
    </div>
    <div style="padding-top: 30px;">
      <filters-solicitud-index></filters-solicitud-index>
    </div>
  </div>
</template>

<script>
import FiltersSolicitudIndex from "./FiltersSolicitudIndex.vue";
import StatsSolicitud from "./StatsSolicitud.vue";
import { postReadCorreo } from "@/services/solicitudes/Solicitudes.js";

export default {
  components: {
    FiltersSolicitudIndex,
    StatsSolicitud,
  },

  data() {
    return {
      globalConfig: JSON.parse(localStorage.getItem("globalConfig")),
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },
  methods: {
    async readEmail() {
      try {
        await postReadCorreo({});
        this.$toast.success("Correo leido con exito");
      } catch {
        this.$toast.error("Surgio un error");
      }
    },
  },
  computed: {
    canCreateSolicitudes() {
      let area_negocio_id = localStorage.getItem("area_negocio_id");
      if (
        area_negocio_id == "9" ||
        area_negocio_id == "10" ||
        area_negocio_id == "11" ||
        this.rol == "ADMIN"
      )
        return true;
      else return false;
    },
  },
};
</script>
