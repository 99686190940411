<template>
  <div>
    <commond-form
      :request="formData"
      :route="'/v1/emision/manual/actualizar'"
      :run="run"
      :action="'POST'"
      @cancel="run = false"
      @success="$emit('getData')"
      @afterError="$emit('changeTab', 1)"
    >
      <template v-slot:data>
        <v-row justify="end">
          <v-col cols="auto">
            <v-switch
              v-model="formData.interPlan"
              label="Actualizar a catálogo Coche seguro"
              hide-details
              inset
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
        <!-- Modelos de autos para vieja y nueva version -->
          <v-col cols="12" md="6" lg="3" xl="3" v-if="!formData.interPlan">
            <v-autocomplete
              label="Modelo"
              placeholder="Requerido"
              v-model="formData.modelo"
              :items="modelos"
              item-value="id"
              item-text="id"
              auto-select-first
              @change="getMarcas()"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3" v-if="formData.interPlan">
            <v-autocomplete
              label="Modelo"
              placeholder="Requerido"
              v-model="formData.modelo"
              :items="modelosInter"
              :item-text="(item) => item"
              :item-value="(item) => item"
              auto-select-first
              outlined
              color="#00a7e4"
              @change="getVersionsInter()"
              :loading="loading"
              :rules="inputsRequeridos"
            ></v-autocomplete>
          </v-col>
          <!-- Marca de autos para vieja y nueva version con api inter -->
          <v-col cols="12" md="6" lg="3" xl="3" v-if="!formData.interPlan">
            <v-autocomplete
              label="Marca"
              placeholder="Requerido"
              v-model="formData.marca"
              :items="marcas"
              item-value="marca"
              item-text="marca"
              no-data-text="Primero selecciona un modelo"
              auto-select-first
              @change="getSubmarcas()"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3" v-if="formData.interPlan">
            <v-autocomplete
              label="Marca"
              placeholder="Requerido"
              v-model="formData.marca"
              :items="marcasInter"
              :item-text="(item) => item"
              :item-value="(item) => item"
              no-data-text="Primero selecciona un modelo"
              auto-select-first
              @change="getVersionsInter()"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-autocomplete>
          </v-col>
          <!-- En el nuevo no viene separada por submarca -->
          <v-col cols="12" md="6" lg="3" xl="3" v-if="!formData.interPlan">
            <v-autocomplete
              label="Submarca"
              placeholder="Requerido"
              v-model="formData.submarca"
              :items="submarcas"
              item-value="nombre"
              item-text="nombre"
              no-data-text="Primero selecciona una marca"
              auto-select-first
              @change="getVersiones()"
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-autocomplete>
          </v-col>
          <!-- Version  de autos para vieja y nueva version con api inter -->
          <v-col cols="12" md="6" lg="3" xl="3" v-if="!formData.interPlan">
            <v-autocomplete
              label="Version"
              placeholder="Requerido"
              v-model="formData.version"
              :items="versiones"
              item-value="nombre"
              item-text="nombre"
              no-data-text="Primero selecciona una submarca"
              auto-select-first
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3" v-if="formData.interPlan">
            <v-autocomplete
              label="Version"
              placeholder="Requerido"
              v-model="formData.version"
              :items="versionesInter"
              item-text="description"
              item-value="description"
              no-data-text="Primero selecciona una submarca"
              auto-select-first
              outlined
              color="#00a7e4"
              :rules="inputsRequeridos"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Plan"
              v-model="formData.plan_id"
              :items="planList"
              item-text="name"
              item-value="id"
              :rules="inputsRequeridos"
              outlined
              color="#00a7e4"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Origen del Auto"
              v-model="formData.origen_auto"
              :items="origenAutoList"
              :rules="inputsRequeridos"
              outlined
              color="#00a7e4"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Uso del Auto"
              v-model="formData.uso_auto"
              :items="usoAutoList"
              :rules="inputsRequeridos"
              outlined
              color="#00a7e4"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              label="No de Motor"
              v-model="formData.num_motor"
              :rules="inputsRequeridos"
              outlined
              color="#00a7e4"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              label="No. De Serie"
              v-model="formData.num_serie"
              :maxLength="17"
              :rules="[
                ...inputsRequeridos,
                localRules.alphanumeric,
                $rules.min(15),
                $rules.max(17),
              ]"
              outlined
              color="#00a7e4"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Placas"
              v-model="formData.placas"
              :maxLength="10"
              :rules="[...inputsRequeridos, localRules.alphanumeric]"
              outlined
              color="#00a7e4"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12" lg="12" xl="12">
            <v-textarea
              label="Detalle de la Póliza"
              v-model="formData.detalle_auto"
              outlined
              color="#00a7e4"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: end;"
          >
            <v-btn
              rounded
              dark
              color="#00a7e4"
              outlined
              @click="$emit('changeTab')"
              style="margin-right: 20px;"
            >
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
            <v-btn rounded dark class="common-botton" @click="preSave()">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </commond-form>
    <CommondLoading
      :show="loading"
      :loadingImage="dataLoading.iconCar"
      loadingText="Cargando catalogo de autos..."
    />
  </div>
</template>

<script>
import { mainAxios } from "@/mainAxios.js";
import CommondForm from "@/components/commonComponents/CommondForm.vue";
import CommondLoading from "@/components/commonComponents/CommondLoading.vue";
import dataLoading from "@/helpers/data/data-loading-gif.json";
import { getBranches, getVersions } from "@/services/autos/autos.service.js";

export default {
  data() {
    return {
      formData: {
        id: null,
        modelo: null,
        marca: null,
        submarca: null,
        version: null,
        detalle_auto: null,
        plan_id: null,
        origen_auto: null,
        uso_auto: null,
        num_motor: null,
        num_serie: null,
        placas: null,
        interPlan: false,
      },
      modelosInter: this.generateLast60Years(),
      modelos: [],
      marcas: [],
      submarcas: [],
      versiones: [],

      localRules: {
        alphanumeric: (v) =>
          !v ||
          !!v.match(/^[0-9a-zA-Z]+$/) ||
          "Este campo solo acepta letras y números",
      },

      //listas
      planList: [
        { id: 1, name: "Amplia" },
        { id: 14, name: "Amplia-Plus" },
        { id: 2, name: "Limitada" },
        { id: 3, name: "RC" },
      ],
      origenAutoList: [
        "Fronterizo",
        "Importado",
        "Legalizado",
        "Nacional",
        "Salvamento",
      ],
      usoAutoList: [
        "Particular",
        "Comercial",
        "Uso con App",
        "Servicio público federal",
        "Transporte público",
        "Taxi",
      ],

      axiosConfig: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
      //Validadores
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
      run: false,
      loading: false,
      dataLoading: dataLoading,

    };
  },
  components:{
    CommondForm,
    CommondLoading
  },
  computed: {
    marcaId() {
      if (!this.formData.marca && this.marcas.length) return null;
      return this.marcas.find((m) => m.marca == this.formData.marca).id;
    },
  },
  watch: {
    "formData.interPlan"(value) {
      if (value) this.getToken();
    },
  },
  props: {
    polizaInfo: {
      type: Object,
      default: () => {},
    },
    productos: {
      type: Array,
      default: () => [],
    },
    ramoData: {
      type: Object,
      default: () => {},
    },
  },

  mounted() {
    this.fillData();
  },

  methods: {
    getModelos() {
      mainAxios
        .get("/v1/vehiculo/qualitas/modelos", this.axiosConfig)
        .then(({ data }) => {
          this.modelos = data;
        });
    },

    getMarcas() {
      mainAxios
        .get(
          `/v1/vehiculo/qualitas/marca/${this.formData.modelo}`,
          this.axiosConfig
        )
        .then(({ data }) => {
          this.marcas = data;
        });
    },

    getSubmarcas() {
      mainAxios
        .get(
          `/v1/vehiculo/qualitas/submarca/${this.marcaId}/${this.formData.modelo}`,
          this.axiosConfig
        )
        .then(({ data }) => {
          this.submarcas = data;
        });
    },

    getVersiones() {
      mainAxios
        .get(
          `/v1/vehiculo/qualitas/vehiculo/${this.marcaId}/${this.formData.submarca}/${this.formData.modelo}`,
          this.axiosConfig
        )
        .then(({ data }) => {
          this.versiones = data;
        });
    },

    fillData() {
      var keys = Object.keys(this.formData);
      keys.map((e) => {
        if (this.ramoData[e]) {
          this.formData[e] = this.ramoData[e];
          if(e == 'modelo' && this.ramoData.interPlan && !isNaN(this.ramoData[e])){
            this.formData[e] = parseInt(this.ramoData[e])
          } 
        }
      });
      this.getModelos();
      this.getMarcas();
      setTimeout(() => {
        if (
          this.formData.modelo != null &&
          this.formData.marca != null &&
          this.formData.submarca != null &&
          this.formData.version != null
        ) {
          this.getSubmarcas();
          this.getVersiones();
        }
      }, 1500);
    },

    getToken() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.loading = true;
      return mainAxios.get("/v1/cotizacion-ws/auto/init", config).then(
        ({
          data: {
            data: { accessToken },
          },
        }) => {
          localStorage.setItem("interTokenCoche", accessToken);
          this.getBranchesInter();
        }
      );
    },

    generateLast60Years() {
      const currentYear = new Date().getFullYear();
      console.log({ currentYear });
      let years = [];
      for (let i = 0; i <= 60; i++) {
        years.push(currentYear - i);
      }
      return years;
    },
    async getBranchesInter() {
      this.marcasInter = await getBranches();
      this.getVersionsInter();
    },
    async getVersionsInter() {
      this.loading = true;
      this.versionesInter = await getVersions(
        this.formData.marca,
        this.formData.modelo
      );
      this.loading = false;
    },
    preSave(){
      this.formData.modelo = String(this.formData.modelo)
      this.run = true
    }
  },
};
</script>
