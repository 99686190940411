<template>
  <div>
    <v-expansion-panels elevation="0">
      <v-expansion-panel elevation="0">
        <v-expansion-panel-header style="padding: 35px">
          <h3 style="color: #42352e">Búsqueda detallada</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-row dense>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.poliza"
                  label="No. Póliza"
                  outlined
                  dense
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="4"
                v-if="rol != 'AGENT' && rol != 'AGENTCORP' && !alianzaIdFromRoute "
              >
                <v-autocomplete
                  v-model="form.agente_id"
                  label="Agente"
                  :items="agentes"
                  item-text="nombreCompleto"
                  item-value="id"
                  outlined
                  dense
                  background-color="white"
                  class="share-bar-clase"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-menu v-model="menuFecha" :close-on-content-click="false">
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="datesSelectedString"
                      label="Rango de emisión"
                      outlined
                      dense
                      v-on="on"
                      v-bind="attrs"
                      readonly
                      prepend-inner-icon="mdi-calendar"
                      :light="true"
                      background-color="white"
                      class="share-bar-clase"
                    ></v-text-field>
                  </template>
                  <v-card>
                    <v-date-picker
                      v-model="form.rango_fechas"
                      range
                      locale="es"
                      selected-items-text="2 seleccionadas"
                    ></v-date-picker>
                    <v-card-actions>
                      <v-btn small text @click="form.rango_fechas = []"
                        >Limpiar</v-btn
                      >
                      <v-spacer></v-spacer>
                      <v-btn
                        small
                        text
                        color="primary"
                        @click="menuFecha = false"
                        >Listo</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.nombre"
                  label="Nombre"
                  outlined
                  dense
                  :light="true"
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.apellidoPaterno"
                  label="Apellido Paterno"
                  outlined
                  dense
                  :light="true"
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.apellidoMaterno"
                  label="Apellido Materno"
                  outlined
                  dense
                  :light="true"
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.rfc"
                  label="RFC"
                  outlined
                  dense
                  :rules="rfcRules"
                  :light="true"
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">



                <v-text-field
                  v-model="form.telefono"
                  label="Teléfono"
                  outlined
                  dense
                  prepend-inner-icon="mdi-phone"
                  maxLength="10"
                  :rules="[$rules.numeric, localRules.longDigit(10)]"
                  :light="true"
                  background-color="white"
                  class="share-bar-clase">
                </v-text-field>


              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.correo"
                  label="Correo electrónico"
                  outlined
                  dense
                  prepend-inner-icon="mdi-email"
                  :rules="[$rules.email]"
                  :light="true"
                  background-color="white"
                  class="share-bar-clase"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="pt-5">
      <v-row>
        <v-col cols="6" md="2">
          <v-select
            v-model="form.status"
            label="Estatus Póliza"
            :items="emisionesStatus"
            item-text="title"
            item-value="id"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="6" md="2">
          <v-select
            v-model="form.ramo"
            label="Ramo"
            :items="ramos"
            item-text="ramo"
            item-value="ramo"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="6" md="2" v-if="rol != 'AGENT'">
          <v-select
            :value="getRolId"
            @input="updateRolId"
            :items="this.rol === 'AGENTCORP' || this.rol === 'ADMINAGENT' && this.alianzaIdFromRoute ? asesores : operadores"
            :label="this.rol === 'AGENTCORP' || this.rol === 'ADMINAGENT' && this.alianzaIdFromRoute ? 'Asesor' : 'Operador'"
            item-text="nombreCompleto"
            item-value="id"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="3" class="d-flex justify-end">
        <div class="d-flex button-container"> <!-- Clase para ajustar el margen en pantalla responsive -->
          <v-btn
            dark
            outlined
            color="#00a7e4"
            rounded
            class="mr-2"
            @click="clearForm()"
            min-width="120"
          >
            Limpiar
          </v-btn>
          <v-btn
            class="common-botton"
            dark
            :disabled="!isFormValid || !minimoUnCampoLleno"
            :loading="loading"
            @click="updateSearch"
            min-width="120"
          >
            Filtrar
          </v-btn>
        </div>
      </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mainAxios } from "@/mainAxios.js";
import moment from "moment";

export default {
  props: {
    currentFilters: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        solicitud_id: "",
        status: "",
        agente_id: "",
        ejecutivo_operaciones_id: "",
        ramo: "",
        rango_fechas: "",
        tipo: "",
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        rfc: "",
        telefono: "",
        correo: "",
      },
      menuFecha: false,

      emisionesStatus: [
        {
          id: 0,
          title: "Nueva",
        },
        {
          id: 1,
          title: "Emitida Pendiente de Pago",
        },
        {
          id: 2,
          title: "Pagada Parcial",
        },
        {
          id: 3,
          title: "Pagada Total",
        },
        {
          id: 4,
          title: "Renovada",
        },
        {
          id: 5,
          title: "Finalizada",
        },
        {
          id: 8,
          title: "Duplicada",
        },
        {
          id: 6,
          title: "Cancelada",
        },
      ],
      agentes: [],
      operadores: [],
      ramos: [],
      asesores: [],
      tipos: [
        "Cotización",
        "Emisión",
        "Renovación",
        "Endoso A y D",
        "Endoso B",
        "Facturacion",
        "Cancelación",
        "Otro",
      ],

      isFormValid: true,
      localRules: {
        longDigit(number) {
          return (v) =>
            !v ||
            v.length == number ||
            "Debe proporcionar " + number + " digitos";
        },
      },
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      rfcRules: [
        (v) =>
          (v || "").length <= 13 || "RFC No debe ser mayor de 13 caracteres",
        (v) =>
          /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/.test(
            v
          ) || "RFC no es valido recordar escribir en mayusculas",
      ],
      alianzaIdFromRoute: null,
    };
  },

  computed: {
    datesSelectedString() {
      if (this.form.rango_fechas.length > 1)
        return `${this.formatDate(
          this.form.rango_fechas[0]
        )} a ${this.formatDate(this.form.rango_fechas[1])}`;
      return "";
    },

    minimoUnCampoLleno() {
      return (
        Object.keys(this.form).filter(
          (e) => !!this.form[e] || this.form[e] === 0
        ).length >= 1
      );
    },
    getRolId() {
      return this.rol === "AGENTCORP" || this.rol === "ADMINAGENT"
        ? this.form.agente_id
        : this.form.ejecutivo_operaciones_id;
    },
  },

  watch: {
    currentFilters(v) {
      !!v && this.setCurrent();
    },
  },

  mounted() {
    this.alianzaIdFromRoute = this.$route.params.alianza_id;
    this.getOperadores();
    this.getAgentes();
    this.getRamos();
    this.setCurrent();
    this.getListAsesores();
  },

  methods: {
    getOperadores() {
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios.get("/v1/agentes/operador", requestConfig).then(({ data }) => {
        this.operadores = data.map((e) => ({
          correo: e.correo,
          nombreCompleto: e.nombreCompleto,
          id: e.id,
          uuid: e.uuid,
        }));
      });
    },

    getAgentes() {
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios.get("/v1/agente/list", requestConfig).then(({ data }) => {
        this.agentes = data.map((e) => ({
          correo: e.correo,
          nombreCompleto: e.nombreCompleto,
          id: e.id,
          uuid: e.uuid,
        }));
      });
    },

    async getListAsesores() {
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .post(
          `/v1/agente/list-filtro/${this.idParamOrStorage()}`,
          {
            filtroBusqueda: "",
          },
          requestConfig
        )
        .then(({ data }) => {
          this.asesores = data.map((e) => ({
            correo: e.correo,
            nombreCompleto: e.nombreCompleto,
            id: e.id,
            uuid: e.uuid,
          }));
          console.log(this.asesores);
        });
    },

    getRamos() {
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/ramo/list", requestConfig).then(({ data }) => {
        this.ramos = data;
      });
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    updateSearch() {
      if (!this.isFormValid || !this.minimoUnCampoLleno) return;
      let payload = {};
      Object.keys(this.form).forEach((e) => {
        if (!!this.form[e] || this.form[e] === 0) payload[e] = this.form[e];
      });

      this.$emit("searchUpdated", payload);
    },

    setCurrent() {
      this.clearForm();
      this.form = { ...this.form, ...this.currentFilters };
    },

    clearForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = "";
      });
    },
    updateRolId(value) {
      if (this.rol === "AGENTCORP" || this.rol === "ADMINAGENT") {
        this.form.agente_id = value;
      } else {
        this.form.ejecutivo_operaciones_id = value;
      }
    },
    idParamOrStorage() {
      return this.alianzaIdFromRoute || localStorage.alianzaId;
    },
  },
};
</script>
<style scoped>
.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(
    .v-expansion-panels--tile
  )
  > .v-expansion-panel--active {
  border-radius: 20px !important;
  background-color: #f7f8fa;
}
.v-expansion-panel-header {
  background-color: #f7f8fa;
  border-radius: 20px !important;
}
@media (max-width: 600px) {
  .button-container {
    margin-bottom: 15px; /* Ajusta el espacio */
  }
}
</style>
