<template>
  <div>
    <!-- Versión escritorio -->
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <CommonExpansionPanel>
        <template v-slot:title-panel>
          Contraseña
        </template>
        <v-expansion-panel-content>
          <view-data
            @changeComponent="changeComponent"
            :personalData="personalData"
            :canEdit="canEdit"
            v-if="switch_data == 1"
          >
          </view-data>
          <edit-data
            @changeComponent="changeComponent"
            :personalData="personalData"
            :routesConfigPassword="routesConfigPassword"
            v-else
          >
          </edit-data>
        </v-expansion-panel-content>
      </CommonExpansionPanel>
    </template>

    <!-- Versión móvil -->
    <template v-else>
      <CommonExpansionPanel>
        <template v-slot:title-panel>
          Contraseña
        </template>
        <v-expansion-panel-content>
          <view-data
            @changeComponent="changeComponent"
            :personalData="personalData"
            :canEdit="canEdit"
            v-if="switch_data == 1"
          >
          </view-data>
          <edit-data
            @changeComponent="changeComponent"
            :personalData="personalData"
            :routesConfigPassword="routesConfigPassword"
            v-else
          >
          </edit-data>
        </v-expansion-panel-content>
      </CommonExpansionPanel>
    </template>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import ViewData from "./ViewData.vue";
import EditData from "./EditData.vue";

export default {
  components: {
    CommonExpansionPanel,
    ViewData,
    EditData,
  },
  props: {
    personalData: Object,
    routesConfigPassword: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      switch_data: 1,
    };
  },

  methods: {
    changeComponent(component) {
      this.switch_data = component;
    },
  },
};
</script>
<style scoped>
/* Estilos para la versión móvil */
@media (max-width: 959px) {
  .mobile-expansion-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }

  .mobile-title-panel {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 16px;
  }

  .mobile-panel-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .mobile-view-data,
  .mobile-edit-data {
    width: 100%;
    max-width: 400px;
    margin-bottom: 16px;
  }
}
</style>