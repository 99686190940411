<template>
  <CommonExpansionPanel :currentPanel="0">
    <template v-slot:title-panel> Buscar </template>
    <v-expansion-panel-content>
      <filters-polizas
        @searchUpdated="getEmisiones"
        :currentFilters="filtros"
        :loading="loading"
      ></filters-polizas>
      <hr />
      <table-polizas :loading="loading" :emisiones="emisiones"></table-polizas>
    </v-expansion-panel-content>
  </CommonExpansionPanel>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import FiltersPolizas from "./FiltersPolizas.vue";
import TablePolizas from "./TablePolizas.vue";
import { mainAxios } from "@/mainAxios.js";

export default {
  components: {
    CommonExpansionPanel,
    FiltersPolizas,
    TablePolizas,
  },

  data() {
    return {
      switch_data: 1,
      emisiones: [],
      filtros: {},
      loading: false,
      emisionesStatus: [
        {
          id: 0,
          title: "Nueva",
        },
        {
          id: 1,
          title: "Emitida Pendiente de Pago",
        },
        {
          id: 2,
          title: "Pagada Parcial",
        },
        {
          id: 3,
          title: "Pagada Total",
        },
        {
          id: 4,
          title: "Renovada",
        },
        {
          id: 5,
          title: "Finalizada",
        },
        {
          id: 8,
          title: "Duplicada",
        },
        {
          id: 6,
          title: "Cancelada",
        },
      ],
    };
  },
  props: {
    contactData: Object,
    correosDataFormat: Object,
    routesContactData: Object,
  },
  methods: {
    getEmisiones(body) {
      const alianzaIdFromUrl = this.$route.params.alianza_id;
      const alianzaIdFromStorage = localStorage.alianzaId !== "null" ? localStorage.alianzaId : null;
      const alianzaId = alianzaIdFromUrl || alianzaIdFromStorage || null;
      if (alianzaId) {
        body.alianza_id = alianzaId;
      } else {
        delete body.alianza_id;
      }
      
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.loading = true;
      mainAxios
        .post(`/v1/emision/list/filtros`, body, requestConfig)
        .then(({ data }) => {
          this.loading = false;
          if (data.length)
            this.emisiones = data.map((emision) => {
              const result = {
                ...emision,
                ...(emision.requestJson && {
                  ...JSON.parse(emision.requestJson),
                }),
                status: this.emisionesStatus.find((e) => e.id == emision.status)
                  .title,
              };
              result.inicio = this.$timezone.formatServerTimezone(
                result.inicio,
                "DD/MM/YYYY"
              );
              result.fin = this.$timezone.formatServerTimezone(
                result.fin,
                "DD/MM/YYYY"
              );

              return result;
            });
          else this.emisiones = [];
          this.filtros = body;
        });
    },

    getTodasLasSolicitudes() {
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(`/v1/solicitud/info/status/1`, requestConfig)
        .then(({ data }) => {
          this.todasLasSolicitudes = data;
        });
    },
  },
  mounted() {
    if (String(window.location.pathname).includes("/alianza/perfil/")) {
      this.getEmisiones({});
    }
  },
};
</script>
