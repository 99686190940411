<template>
  <common-card class="mt-3">
    <v-row style="padding: 0px 30px 0px 30px;">
      <v-col cols="12">
        <h1>Plantilla ECI</h1>
      </v-col>
    </v-row>
    <v-row style="padding: 0px 30px 0px 30px;">
      <v-col cols="12" md="6">
        <v-text-field
          name="nombre"
          label="Nombre plantilla"
          :rules="generalRules"
          v-model="plantilla.nombre"
          outlined
          primary
          color="#00a7e4"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          name="asunto"
          label="asunto"
          :rules="generalRules"
          v-model="plantilla.asunto"
          outlined
          primary
          color="#00a7e4"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          v-model="plantilla.status"
          label="Estatus"
          :items="status"
          item-text="nombre"
          item-value="id"
          outlined
          :rules="generalRules"
          primary
          color="#00a7e4"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          v-model="plantilla.area_id"
          label="Area"
          :items="areas"
          :item-text="
            (item) => item.areas + '-' + item.departamento + '-' + item.proceso
          "
          item-value="id"
          outlined
          required
          :rules="generalRules"
          primary
          color="#00a7e4"
        >
          <template #item="{item}">
            {{ item.areas + "-" + item.departamento + "-" + item.proceso }}
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          v-model="plantilla.ramo_id"
          label="Ramo"
          :items="ramos"
          item-text="etiqueta"
          item-value="id"
          outlined
          :rules="generalRules"
          primary
          color="#00a7e4"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          v-model="plantilla.canal_id"
          label="Canal"
          :items="canales"
          item-text="etiqueta"
          item-value="id"
          outlined
          :rules="generalRules"
          primary
          color="#00a7e4"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="isEdit" style="padding: 0px 30px 0px 30px;">
      <v-col cols="12" md="6">
        <v-text-field
          label="Creado por"
          v-model="plantilla.creado_por"
          outlined
          primary
          readonly
          color="#00a7e4"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Modificado por"
          v-model="plantilla.actualizado_por"
          outlined
          primary
          readonly
          color="#00a7e4"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row style="padding: 0px 30px 0px 30px;" class="mb-10">
      <!-- <v-col cols="12">
        <quill-editor
          v-model="plantilla.detalle"
          :options="editorOptions"
          style="height: 300px;"
        />
      </v-col> -->
      <v-col cols="12">
        <ckeditor v-model="plantilla.detalle" :config="editorConfig" />
      </v-col>
    </v-row>
    <v-row style="padding: 0px 30px 0px 30px;">
      <v-col cols="12">
        <v-col cols="12" style="text-align: end;">
          <v-btn
            rounded
            dark
            outlined
            color="#00a7e4"
            style="margin-right: 11px;"
            @click="$router.push('/administacion/plantillas')"
          >
            <span>
              Cancelar
            </span>
          </v-btn>
          <v-btn @click="procesarCambios()" class="common-botton mr-4" dark>
            {{ isEdit ? "Actualizar" : "Guardar" }}
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
  </common-card>
</template>
<script>
import { mainAxios } from "../../../../mainAxios";
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import { getCanalList } from "@/services/canal/canal.service.js";
import { getRamoList } from "@/services/ramo/ramo.service.js";
import { getAreaList } from "@/services/area/area.service.js";
//import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import ckeditor from "ckeditor4-vue";

export default {
  components: {
    CommonCard,
    //quillEditor: VueQuillEditor.quillEditor,
    ckeditor: ckeditor.component,
  },
  data() {
    return {
      editorContent: "<p>Escribe aquí...</p>", // Contenido inicial
      editorConfig: {
        versionCheck: false, // Desactiva el mensaje de actualización
      },
      areas: [],
      ramos: [],
      canales: [],
      status: [],
      plantilla: {
        id: null,
        nombre: null,
        status: 0,
        area_id: null,
        ramo_id: null,
        canal_id: null,
        detalle: null,
        asunto: null,
        creado_por: "",
        actualizado_por: "",
      },
      generalRules: [(v) => !!v || "Campo es necesario"],
      editorOptions: {
        theme: "snow", // Puedes elegir entre 'snow', 'bubble' y otras opciones que Quill ofrece.
        placeholder: "Escribe aquí el detalle de la plantilla...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ["clean"],
          ],
        },
      },
    };
  },

  mounted() {
    this.listas();
    if (this.isEdit) {
      this.getPlantilla();
    }
  },

  watch: {},

  computed: {
    isEdit() {
      return !!this.$route.params.id;
    },
  },

  methods: {
    async listas() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/plantillas-eci/status`, config).then((response) => {
        this.status = response.data;
      });
      this.areas = await getAreaList();
      this.ramos = await getRamoList();
      this.canales = await getCanalList();
    },
    procesarCambios() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      if (this.isEdit) {
        mainAxios
          .post(
            `/v1/plantillas-eci/actualizar/${this.$route.params.id}`,
            this.plantilla,
            config
          )
          .then((response) => {
            console.log(response);
            this.$router.push("/administacion/plantillas");
          });
      } else {
        mainAxios
          .post(`/v1/plantillas-eci/crear`, this.plantilla, config)
          .then((response) => {
            console.log(response);
            this.$router.push("/administacion/plantillas");
          });
      }
    },
    getPlantilla() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(`/v1/plantillas-eci/${this.$route.params.id}`, config)
        .then((response) => {
          this.plantilla.nombre = response.data.data.nombre;
          this.plantilla.asunto = response.data.data.asunto;
          this.plantilla.area_id = Number(response.data.data.area);
          this.plantilla.ramo_id = Number(response.data.data.ramo);
          this.plantilla.canal_id = Number(response.data.data.canal);
          this.plantilla.status = Number(response.data.data.status.id);
          this.plantilla.detalle = response.data.data.detalle;
          this.plantilla.creado_por =
            response.data.data.creadoPor?.nombreCompleto;
          this.plantilla.actualizado_por =
            response.data.data.modificadoPor?.nombreCompleto || "-";
        });
    },
  },
};
</script>

<style>
.mayusculas {
  text-transform: uppercase;
}
</style>
