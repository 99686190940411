<template>
  <div class="main-container">
    <Navigation :mobileMenu="mobileMenu" />
    <div class="content-wrapper">
      <v-main class="main-styles">
        <main-app-bar
          class="mb-4 mb-md-0"
          @openMenu="openMenu"
          @showMenu="showMenu"
          :openProp="isVisible"
        ></main-app-bar>
        <v-container fluid v-if="screenWidth1 > 999">
          <v-row>
            <v-col :cols="isVisible && esPerfil ? '10' : '12'">
              <div class="main-styles">
                <router-view></router-view>
              </div>
            </v-col>
            <v-col :cols="2" v-if="isVisible && esPerfil">
              <div class="main-styles">
                <ProfileCard
                  :esAgente="esAgente"
                  :correo="currentCorreo"
                ></ProfileCard>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-container
          v-else
          style="height: fit-content; background-color: #f6f5f9;"
        >
          <router-view></router-view>
          <MenuLateralDialog
            :esAgente="esAgente"
            :correo="currentCorreo"
            :dialog="isVisible && esPerfil"
            @closeMenu="isVisible = false"
          >
          </MenuLateralDialog>
        </v-container>
        <Footer class="footer-conteiner" v-if="globalConfig.showFooter" />
        <FooterECI class="footer-conteiner" v-else />
      </v-main>
      <InboundProcces v-if="readyTorRecibeCalls" />
      <InboundLog v-if="readyTorRecibeCalls" />
    </div>
  </div>
</template>
<script>
import Navigation from "../components/Nav.vue";
import Footer from "./Footer.vue";
import FooterECI from "./FooterECI.vue";
import MainAppBar from "../components/MainAppBar.vue";
import ProfileCard from "../../src/views/pages/perfiles/components/profileData/ProfileCard.vue";
import MenuLateralDialog from "@/components/commonComponents/dialogs/MenuLateralDialog.vue";
import InboundProcces from "@/components/commonComponents/Inbound/InboundProcces.vue";
import InboundLog from "@/components/commonComponents/Inbound/log/InboundLog.vue";
export default {
  name: "HomeView",
  data() {
    return {
      name: localStorage.agenteName,
      mobileMenu: false,
      isMenuDesplegado: false,
      isVisible: false,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      esAgente: false,
      screenWidth1: window.innerWidth,
      screenHeight: window.innerHeight,
      globalConfig: JSON.parse(localStorage.getItem("globalConfig")),
      readyTorRecibeCalls: false,
    };
  },
  components: {
    Navigation,
    Footer,
    MainAppBar,
    ProfileCard,
    MenuLateralDialog,
    FooterECI,
    InboundProcces,
    InboundLog,
  },
  computed: {
    esPerfil() {
      const ruta = String(this.$route.path);
      if (
        (ruta.includes("/usuario/ver-micuenta") ||
          ruta.includes("/perfil/prospecto")) &&
        this.screenWidth1 > 999
      ) {
        return false;
      } else {
        return true;
      }
    },
    currentRuta() {
      return this.$route.path;
    },
  },
  watch: {
    currentRuta() {
      if (this.screenWidth1 < 999) this.isVisible = false;
    },
  },
  methods: {
    openMenu() {
      this.mobileMenu = !this.mobileMenu;
    },
    showMenu(val) {
      if (this.esPerfil) {
        this.isVisible = val;
      } else {
        this.isVisible = false;
      }
    },
    updateScreenSize() {
      this.screenWidth1 = window.innerWidth;
      this.screenHeight = window.innerHeight;
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateScreenSize);
    if (this.rol != "PROSPECTO" && this.rol != "AGENTCORP")
      this.esAgente = true;
    else {
      this.esAgente = false;
    }
    this.readyTorRecibeCalls = true;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateScreenSize);
  },
};
</script>
<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.content-wrapper {
  flex: 1;
  background-color: #f6f5f9;
}
.main-styles {
  height: 100%;
  padding: 1rem;
  position: relative;
}
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none;
}

.footer-conteiner {
  position: absolute;
  width: 100%;
  bottom: -150px;
}

@media only screen and (max-width: 960px) {
  .footer-conteiner {
    bottom: 0 !important;
    position: relative;
  }
}
</style>
