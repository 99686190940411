var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-5"},[_c('v-data-table',{attrs:{"items":_vm.solicitudes,"headers":_vm.headers,"loading":_vm.loading,"no-data-text":"Sin solicitudes para mostrar","no-results-text":"Sin resultados para mostrar","loading-text":"Cargando...","footer-props":{ // se agrego el slot para mostrar el total de solicitudes
      'items-per-page-all-text': 'Todos',
      'items-per-page-text': 'Elementos por página',
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticStyle:{"padding":"15px"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h2',[_vm._v("Resultados de la búsqueda")])])],1)]},proxy:true},{key:"item.ver",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.getToken(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.primaNeta",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.tipo_producto != 'Mascota' ? _vm.formatPrice(item.primaNeta) : _vm.getPrimaNetaMascota(item))+" ")]}},{key:"item.primerPago",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.primerPago))+" ")]}},{key:"item.total",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.tipo_producto != 'Mascota' ? _vm.formatPrice(item.total) : _vm.getPrimaTotalMascota(item))+" ")]}},{key:"item.fecha",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormatDate(item.fecha))+" ")]}},{key:"item.aseguradoraId",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAseguradoraName(item.aseguradoraId))+" ")]}},{key:"footer.page-text",fn:function(ref){
    var pageStart = ref.pageStart;
    var pageStop = ref.pageStop;
    var itemsLength = ref.itemsLength;
return [(itemsLength)?_c('div',[_vm._v(" Mostrando "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" de "+_vm._s(itemsLength)+" solicitudes ")]):_vm._e()]}}])}),_c('CommonRecotizar',{attrs:{"dialog":_vm.dialog},on:{"userChoice":_vm.recotizarProducto}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }