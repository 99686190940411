<template>
<!-- Añadí padding-top -->
  <v-app-bar
      color="#F6F5F9"
      style="padding-left: 20px; padding-top: 10px;"
  >
      <v-row style="padding-top: 2%;"> <!-- Añadí padding-top -->
          <v-col cols="5" sm="8" md="8" lg="8" xl="10" class="share-bar-clase"> <!-- Añadí share-bar-clase -->
              <!-- Espacio reservado para una barra de búsqueda futura -->
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xl="2" style="text-align: end;"> <!-- Añadí style="text-align: end;" -->
              <!-- Botón de menú hamburguesa -->
              <v-btn class="mi-clase" icon color="#00A7E4" @click="emitOpenMenu"> <!-- Añadí mi-clase -->
                  <v-icon>mdi-menu</v-icon>
              </v-btn>

              <!-- Avatar del usuario -->
              <v-avatar color="primary" style="cursor: pointer;" @click="irARuta">
                  <span class="white--text">
                      {{
                          (
                              name.split(" ")[0][0] +
                              (name.split(" ")[1] ? name.split(" ")[1][0] : "")
                          ).toUpperCase()
                      }}
                  </span>
              </v-avatar>
          </v-col>
      </v-row>
  </v-app-bar>
</template>

<script>
export default {
  props: {
      openProp: {
          type: Boolean,
          default: false,
      },
  },
  watch: {
      openProp(val) {
          this.isOpen = val;
      },
  },
  data() {
      return {
          name: localStorage.agenteName || "Usuario", // validar si existe el nombre del agente
          isOpen: false, // Controla si el menú está abierto
          rol: JSON.parse(localStorage.getItem("roles"))[0]?.rol || "",
      };
  },
  methods: {
      emitOpenMenu() { // Método para emitir el evento openMenu
          console.log("emitOpenMenu: Se hizo clic en el menú hamburguesa"); // Mensaje de depuración
          this.$emit("openMenu"); // Emite el evento openMenu
      },
      irARuta() { // Método para redirigir a la ruta /usuario/ver-micuenta
          console.log("irARuta: Redirigiendo a /usuario/ver-micuenta");
          this.$router.push("/usuario/ver-micuenta");
      },
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrapper {
  flex: 1;
  background-color: #F6F5F9;
}

.main-styles {
  height: 100%;
  padding: 23px;
}

.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none;
}

/* Media query para pantallas de 1263px o menos */
@media screen and (min-width: 1263px) {
  .mi-clase {
      display: none; /* Oculta el botón de menú hamburguesa */
  }
}

@media screen and (max-width: 425px) {
  .share-bar-clase {
      display: none; /* Oculta el espacio reservado para una barra de búsqueda futura */
  }
}
</style>
