import { mainAxios } from "@/mainAxios.js";
import { getAgenteAccessToken } from "../tokens.service";


export const getBranches = async () => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
      "inter-token": localStorage.getItem('interTokenCoche'),
    },
  };

  const serverResponse = await mainAxios.get(
    `/v1/cotizacion-ws/auto/catalogo/marcas`,
    config
  );
  return serverResponse.data.data.marcas

};

export const getVersions = async (brand, year) => {
  console.log({brand})
  console.log({year})
  
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
      "inter-token": localStorage.getItem('interTokenCoche'),
    },
  };

  const serverResponse = await mainAxios.get(
    `/v1/cotizacion-ws/auto/catalogo/versiones/${brand}/${year}`,
    config
  );
  return serverResponse.data.data.versiones

};
