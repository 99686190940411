<!-- src/views/pages/perfiles/components/contactData/ContactDataPanel.vue -->
<template>
  <div>
    <!-- Componente de panel de expansión común que envuelve el contenido -->
    <CommonExpansionPanel>
      <!-- Define el título del panel de expansión -->
      <template v-slot:title-panel>
        Datos de contacto
      </template>
      <!-- Contenido del panel de expansión -->
      <v-expansion-panel-content>
        <!-- Componente para visualizar datos, se muestra si switch_data es 1 -->
        <view-data
          :contactData="contactData"
          :correosDataFormat="correosDataFormat"
          :canEdit="canEdit"
          @changeComponent="changeComponent"
          v-if="switch_data == 1"
        >
        </view-data>
        <!-- Componente para editar datos, se muestra si switch_data no es 1 -->
        <edit-data
          :telefonos="contactData"
          :correosDataFormat="correosDataFormat"
          :routesContactData="routesContactData"
          @changeComponent="changeComponent"
          @update-contact-data="updateContactData"
          v-else
        >
        </edit-data>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
// Importa el componente de panel de expansión común
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
// Importa el componente para visualizar datos
import ViewData from "./ViewData.vue";
// Importa el componente para editar datos
import EditData from "./EditData.vue";

export default {
  // Define los componentes que se utilizarán en este componente
  components: {
    CommonExpansionPanel,
    ViewData,
    EditData,
  },

  // Define los datos reactivos del componente
  data() {
    return {
      switch_data: 1,  // Inicializa switch_data en 1 para mostrar el componente de visualización por defecto
    };
  },
  // Define las propiedades que se pueden pasar al componente
  props: {
    contactData: Object,  // Datos de contacto, debe ser un objeto
    correosDataFormat: Object,  // Formato de correos, debe ser un objeto
    routesContactData: Object,  // Rutas de datos de contacto, debe ser un objeto
    canEdit: {
      type: Boolean,  // Indica si se puede editar, debe ser un booleano
      default: true,  // Valor por defecto es true
    },
  },
  // Define los métodos del componente
  methods: {
    // Método para cambiar el componente visible
    changeComponent(component) {
      this.switch_data = component;  // Cambia el valor de switch_data para mostrar el componente correspondiente
      this.$emit("getData");  // Emite el evento getData para notificar al componente padre
    },
    // Método para actualizar los datos de contacto
    updateContactData(contactData) {
      this.contactData.telefono = contactData.telefono;  // Actualiza el teléfono en los datos de contacto
      this.contactData.celular = contactData.celular;  // Actualiza el celular en los datos de contacto
    },
  },
};
</script>
